import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dimensions } from '../../../../../styles/dimensions';
import { theme } from '../../../../../styles/theme';
import { EMPTY_VALUE } from '../../../types';
import { VisitorInfo } from '../../type';
import { useAppContext } from '../../../../../appContext';

type Props = {
  visitorInfo: VisitorInfo;
};

export const VisitorInformation: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { locations } = useAppContext();
  const { location, company, plate, vehicle } = props.visitorInfo;

  const locationName = locations.find(item => item.id === Number(location))?.location;
  return (
    <Box display="flex" flexDirection="column" rowGap={baseMD}>
      <Typography variant="subtitle3" color="grey.100">
        {t('visitor.visitor_information')}
      </Typography>

      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.location')}</PropertyWrapper>
        <ValueWrapper variant="body1">{locationName ?? EMPTY_VALUE}</ValueWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.company')}</PropertyWrapper>
        <ValueWrapper variant="body1">{company || EMPTY_VALUE}</ValueWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.plate_number')}</PropertyWrapper>
        <ValueWrapper variant="body1">{plate || EMPTY_VALUE}</ValueWrapper>
      </ItemWrapper>
      <ItemWrapper>
        <PropertyWrapper variant="body1">{t('visitor.vehicle')}</PropertyWrapper>
        <ValueWrapper variant="body1">{vehicle || EMPTY_VALUE}</ValueWrapper>
      </ItemWrapper>
    </Box>
  );
};

const { baseSM, baseMD } = dimensions;

const {
  shadows,
  palette: { grey }
} = theme;

const ItemWrapper = styled(Box)`
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${baseSM};
  box-shadow: ${shadows[4]};
`;

const PropertyWrapper = styled(Typography)`
  width: 150px;
  padding: ${baseSM} ${baseSM} ${baseSM} 0px;
  color: ${grey[200]};
`;

const ValueWrapper = styled(Typography)`
  padding: ${baseSM} 0px ${baseSM} ${baseMD};
  color: ${grey[100]};
`;
