import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../utils/storage';

const ProtectedRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  if (!isAuthenticated()) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
