import React from 'react';

import { TableCell, tableCellClasses, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { CellAlignment } from '../../../../types/cell';

type Props = {
  className?: string;
  align?: CellAlignment;
  value: JSX.Element | string;
  width?: string;
  link?: string | null;
};

const BodyTableCell: React.FunctionComponent<Props> = (props: Props) => {
  const { className, align, value, width, link } = props;

  return (
    <Cell className={className} align={align} minwidth={width}>
      {link ? <StyledLink to={link}>{value}</StyledLink> : value}
    </Cell>
  );
};

export default BodyTableCell;

const { grey, primary } = theme.palette;

const { baseMD, inputHeight } = dimensions;

const StyledLink = styled(Link)`
  color: ${primary.dark};
  font-size: 14px;
  font-weight: 600;
`;

const Cell = styled(TableCell)(({ minwidth }: { minwidth: string | undefined }) => ({
  height: inputHeight,
  padding: `0 ${baseMD}`,
  minWidth: minwidth ? minwidth : 'auto',

  [`&.${tableCellClasses.head}`]: {
    color: grey[100]
  }
}));
