import dayjs from 'dayjs';
import { BooleanValue } from '../../../types/checkbox';
import { isOneDate, isStartDateBeforeOrEqualEndDate, TimeFormatType } from '../../../utils/date';

export enum VisitorRouteEndpoints {
  ID = 'id',
  ADD_NEW = 'add-new',
  EDIT = 'edit'
}

export type AccessInfo = {
  startDate: string;
  endDate: string;
  tagActive: string;
  tagNumber: string;
  tagText: string;
  pin: string;
  email: string;
};

export type VisitorInfo = {
  location: string;
  company: string;
  plate: string;
  vehicle: string;
};

export type HistoryInfo = {
  time: string;
  doorName: string;
  doorLocation: string;
  message: string;
};

export enum FormActionType {
  ADD_NEW,
  EDIT
}
export type PopulateVisitorForm = {
  contractPersonName: string;
  name: string;
  location: string;
  company: string;
  phone: string;
  email: string;
  plate: string;
  vehicle: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  tagActive: CheckBoxFormValue;
  tagNumber: string;
  tagText: string;
  isSendEmail: CheckBoxFormValue;
  pin: string;
};

export enum CheckBoxFormValue {
  ON = 'on',
  OFF = ''
}

export const convertCheckboxValue = (checkBoxValue: CheckBoxFormValue): BooleanValue => {
  switch (checkBoxValue) {
    case CheckBoxFormValue.ON:
      return BooleanValue.TRUE;
    case CheckBoxFormValue.OFF:
    default:
      return BooleanValue.FALSE;
  }
};

export type PopulateVisitor = {
  email: string;
  locationId: string;
  name: string;
  phone: string;
  pin: string;
  startDate: string;
  endDate: string;
  tagActive: string;
  tagNumber: string;
  contactId: number;
  tagText: string;
};

export const isPopulateVisitorForm = (obj: { [k: string]: FormDataEntryValue }): obj is PopulateVisitorForm => {
  return (
    'contractPersonName' in obj &&
    'name' in obj &&
    'startDate' in obj &&
    'startTime' in obj &&
    'endDate' in obj &&
    'endTime' in obj
  );
};

export const validatePopulateVisitorFormData = (data: PopulateVisitorForm): void => {
  const { startDate, startTime, endDate, endTime } = data;

  const validStartDate = dayjs(startDate, TimeFormatType.MONTH_DATE_YEAR, true).isValid();
  const validStartTime = dayjs(startTime, TimeFormatType.HOUR_MINUTE, true).isValid();

  const validEndDate = dayjs(endDate, TimeFormatType.MONTH_DATE_YEAR, true).isValid();
  const validEndTime = dayjs(endTime, TimeFormatType.HOUR_MINUTE, true).isValid();

  const isInputDatesQualified = isStartDateBeforeOrEqualEndDate(new Date(startDate), new Date(endDate));

  if (!validStartDate || !validEndDate) {
    throw new Error('visitor.toast.failed.invalid_visit_date');
  }

  if (!validStartTime || !validEndTime) {
    throw new Error('visitor.toast.failed.invalid_visit_time');
  }

  if (!isInputDatesQualified) {
    throw new Error('visitor.toast.failed.unqualified_visit_dates');
  }

  const startDateTime = dayjs(`${startDate} ${startTime}`);
  const endDateTime = dayjs(`${endDate} ${endTime}`);

  if (isOneDate(new Date(startDate), new Date(endDate)) && startDateTime.diff(endDateTime) > 0) {
    throw new Error('visitor.toast.failed.unqualified_visit_time');
  }
};
