import React from 'react';

import { dimensions } from '../../../styles/dimensions';
import { useTranslation } from 'react-i18next';
import { Box, styled, Typography } from '@mui/material';

import { AuthError, DEFAULT_AUTH_ERROR, EMPTY_STRING, InputFieldType, RE_PASSWORD } from '../type';
import { PrimaryButton } from '../../../components/Button';

import { ReactComponent as Key } from '../../../assets/image/Key.svg';
import { ErrorPopup } from '../../../components/Popup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthAction } from '../store';
import { useAppContext } from '../../../appContext';
import { HttpStatusCode } from '../../../models';
import { showErrorToast, showSuccessToast } from '../../../components/Toast/actions';
import { isEqual } from 'lodash';
import { InputField, MainLayout } from '../components';

const { baseXM, baseSM, baseXL } = dimensions;

const ChangePassword: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { showLoading, hideLoading } = useAppContext();
  const { changePassword } = useAuthAction();

  const [confirmedNewPassword, setConfirmedNewPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');

  const [error, setError] = React.useState<AuthError>(DEFAULT_AUTH_ERROR);

  const onErrorChanged = (title: string, content: string) => {
    setError({ title: title, content: content });
  };

  const handleChangePassword = async () => {
    if (RE_PASSWORD.test(newPassword)) {
      if (isEqual(newPassword, confirmedNewPassword)) {
        showLoading();

        const statusCode = await changePassword(state.username, newPassword);

        hideLoading();

        switch (statusCode) {
          case HttpStatusCode.OK: {
            showSuccessToast({
              title: t('forgot_password.password_updating_successfully'),

              subtitle: t('forgot_password.please_log_in_with_new_password')
            });

            navigate('/auth/login');

            break;
          }
          case HttpStatusCode.TokenExpired: {
            showErrorToast({
              title: t('forgot_password.code_expired'),

              subtitle: t('forgot_password.please_try_again')
            });

            navigate('/auth/login');

            break;
          }

          default: {
            navigate('/auth/login');
            break;
          }
        }
      } else {
        onErrorChanged(
          t('forgot_password.error.password_do_not_match.title'),

          t('forgot_password.error.password_do_not_match.content')
        );
      }
    } else {
      onErrorChanged(
        t('forgot_password.error.invalid_passwords.title'),

        t('forgot_password.error.invalid_passwords.content')
      );
    }
  };

  React.useEffect(() => {
    if (!state) {
      navigate('/auth/login');
    }
  }, []);

  return (
    <MainLayout>
      <Content>
        <Title>
          <Typography variant="h1">{t('change_password.title')}</Typography>

          <Typography variant="body1" color="grey.200" textAlign="center">
            {t('change_password.content')}
          </Typography>
        </Title>

        <InputWrapper>
          <InputField
            icon={<Key />}
            type={InputFieldType.PASSWORD}
            value={newPassword}
            label={t('change_password.new_password')}
            placeholder={t('change_password.input_password')}
            onChange={value => setNewPassword(value)}
          />

          <InputField
            icon={<Key />}
            type={InputFieldType.PASSWORD}
            value={confirmedNewPassword}
            label={t('change_password.confirm_new_password')}
            placeholder={t('change_password.input_password')}
            onChange={value => setConfirmedNewPassword(value)}
          />
        </InputWrapper>

        <PrimaryButton
          content={t('change_password.title')}
          width="300px"
          height="48px"
          onClick={handleChangePassword}
        />

        <ErrorPopup
          open={!isEqual(error.title, EMPTY_STRING)}
          onClose={() => setError(DEFAULT_AUTH_ERROR)}
          title={error.title}
          content={error.content}
        />
      </Content>
    </MainLayout>
  );
};

export default ChangePassword;

const Content = styled(Box)`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: ${baseSM};
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  row-gap: ${baseXM};
  padding-bottom: ${baseXL};

  @media (min-height: 541px) {
    padding-top: 8%;
  }
`;
