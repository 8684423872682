export enum UserIcon {
  EMPLOYEE = 'Employee',
  VISITOR = 'Visitor',
  SYSTEM = 'System',
  UNKNOWN = 'Unknown'
}

export enum LogLevel {
  HIGH = 'High',
  MEDIUM = 'Medium',
  NORMAL = 'Normal'
}

export type Log = {
  id: string;
  uid: string | null;
  userIcon: UserIcon;
  name: string;
  date: string;
  time: string;
  message: string;
  doorName: string;
  doorLocation: string;
  department: string;
  room: string;
  carPlate: string;
  logLevel: LogLevel;
};

export type LogResponse = {
  data: Log[] | null;
  code: string;
  message: string;
  total: number | null;
  fromId: string;
  take: string;
  doorLocations: string | null;
};

type ColumnSettings = {
  Type: boolean;
  Name: boolean;
  Message: boolean;
  Door: boolean;
  DoorLocation: boolean;
  Room: boolean;
  CarPlate: boolean;
  Time: boolean;
  Department: boolean;
};

export type ColumnSettingsResponse = {
  data: {
    adminID: string;
    settings: ColumnSettings | null;
  } | null;
  code: string | null;
  message: string | null;
};

export type ColumnSettingsParams = {
  settings: ColumnSettings;
};
