import { Box, styled } from '@mui/material';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FunctionComponent<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <TabPanelWrapper display={value !== index ? 'none !important' : 'block'} {...other}>
      {value === index && children}
    </TabPanelWrapper>
  );
};

const TabPanelWrapper = styled(Box)`
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  align-self: center;
`;
