import { Box, Chip, CircularProgress, Grow, Input, InputLabel, Stack, styled, Typography } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddNewIcon } from '../../../../assets/image/Users/AddNewIcon.svg';
import SearchBar from '../../../../components/SearchBar';
import { Group } from '../../../../models/group';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { UnderLine } from '../../../../types/searchBar';
import { useUsersHook } from '../../store';

type Props = {
  chosenGroupIds: string[];
};

export const GroupBar: React.FunctionComponent<Props> = ({ chosenGroupIds }: Props) => {
  const { t } = useTranslation();

  const [{ groupList }, { getAllGroups }] = useUsersHook();
  const [loading, setLoading] = useState<boolean>(true);

  const initialGroupList = useRef<Group[]>(groupList);

  const [chosenGroup, setChosenGroup] = useState<Group[]>([]);
  const [listGroup, setListGroup] = useState<Group[]>(groupList);

  const handleAddGroupIntoChosenList = (id: string) => {
    const addedGroup = initialGroupList.current.find(group => group.groupID === id);

    const removeSelectItemGroups = listGroup.filter(item => {
      return item.groupID !== id;
    });

    initialGroupList.current = initialGroupList.current.filter(item => {
      return item.groupID !== id;
    });

    setListGroup(removeSelectItemGroups);

    addedGroup && setChosenGroup(chosenGroup.concat(addedGroup));
  };

  const handleRemoveGroupOutOfChosenList = (id: string) => {
    const removedGroup = chosenGroup.find(group => group.groupID === id);

    const removeSelectItemChosenGroup = chosenGroup.filter(item => {
      return item.groupID !== id;
    });

    setChosenGroup(removeSelectItemChosenGroup);

    removedGroup &&
      (setListGroup(listGroup.concat(removedGroup)),
      (initialGroupList.current = initialGroupList.current.concat(removedGroup)));
  };

  const handleSearch = (value: string) => {
    const result = initialGroupList.current.filter(item =>
      item.groupName.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setListGroup(result);
  };

  const handleClearSearchBox = () => {
    setListGroup(initialGroupList.current);
  };

  const getGroups = async () => {
    setLoading(true);
    await getAllGroups();
    setLoading(false);
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    const initialChosenGroup: Group[] = groupList.filter(item => chosenGroupIds.includes(item.groupID));

    setChosenGroup(initialChosenGroup);

    const currentGroups = groupList.filter(item => !initialChosenGroup.includes(item));

    initialGroupList.current = currentGroups;

    setListGroup(currentGroups);
  }, [groupList]);

  return (
    <Wrapper>
      <StyledStack direction="row" spacing={1}>
        <Label required={true} shrink={false}>
          {t('user.create.group_title')}
        </Label>

        <ListGroupInput
          required
          name="groupIds"
          value={chosenGroup.map(item => item.groupID)}
          onKeyDown={e => e.preventDefault()}
        />

        {chosenGroup.map((group, index) => (
          <Grow in={true} key={index}>
            <GroupChip label={group.groupName} onDelete={() => handleRemoveGroupOutOfChosenList(group.groupID)} />
          </Grow>
        ))}
      </StyledStack>

      <GroupWrapper>
        <Typography variant="subtitle3" color="grey.100">
          {t('user.create.select_group')}
        </Typography>

        <SearchBar
          placeHolder={t('user.create.search_group')}
          justuderline={UnderLine.YES}
          onSearch={handleSearch}
          onClear={handleClearSearchBox}
        />

        <ItemWrapper>
          {loading ? (
            <LoadingWrapper>
              <CircularProgress color="primary" size={30} />
            </LoadingWrapper>
          ) : (
            listGroup.map((item, index) => (
              <GroupItem key={index}>
                <Typography variant="body1" color="grey.100">
                  {item.groupName}
                </Typography>

                <AddNewIcon cursor="pointer" onClick={() => handleAddGroupIntoChosenList(item.groupID)} />
              </GroupItem>
            ))
          )}
        </ItemWrapper>
      </GroupWrapper>
    </Wrapper>
  );
};

const { baseSM, baseLG, baseMD } = dimensions;
const { primary, grey, error } = theme.palette;

const ListGroupInput = styled(Input)`
  width: 1px;
`;

const Wrapper = styled(Box)`
  flex: 1.5;
  min-height: 500px;
  border-left: 1px solid ${grey[400]};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  ${props => props.theme.breakpoints.down(1400)} {
    border: none;
  }
`;

const GroupWrapper = styled(Box)`
  width: 460px;
  border: 1px solid ${grey[400]};
  margin-top: ${baseSM};
  padding: ${baseMD};
  ${props => props.theme.breakpoints.down(1400)} {
    margin: ${baseSM} 0;
  }
`;

const GroupItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${grey[400]};
  height: 40px;
`;

const StyledStack = styled(Stack)`
  display: flex;
  flex-flow: row wrap;
  row-gap: ${baseSM};
  width: 492px;
  margin: ${baseLG} 8% 0;
`;

const GroupChip = styled(Chip)`
  background-color: ${primary.lighter};
  border: 1px solid ${primary.light};
`;

const ItemWrapper = styled(Box)`
  max-height: 300px;
  overflow-y: auto;
`;

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.grey[500]};
  opacity: 70%;
  height: 200px;
`;

const Label = styled(InputLabel)`
  color: ${grey[200]};
  width: 39%;
  .MuiInputLabel-asterisk {
    color: ${error.main} !important;
  }
`;
