import React from 'react';
import { useTranslation } from 'react-i18next';

import { SelectBoxWithLabel } from '../../components/SelectBox';
import { selectBoxData } from '../../data';
import { FilterOptionsWrapper } from '../../components';
import { FORM_INPUT_WIDTH, INPUT_WIDTH, LABEL_WIDTH } from './types';

const UserSelect: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <FilterOptionsWrapper>
      <SelectBoxWithLabel
        // TODO: Change in https://sioux.atlassian.net/browse/ECA-364
        listItem={selectBoxData}
        label={t('report.auth.filter_option.select_user')}
        width={FORM_INPUT_WIDTH}
        selectBoxWidth={INPUT_WIDTH}
        labelWidth={LABEL_WIDTH}
      />

      <SelectBoxWithLabel
        // TODO: Change in https://sioux.atlassian.net/browse/ECA-364
        listItem={selectBoxData}
        label={t('report.auth.filter_option.select_group')}
        width={FORM_INPUT_WIDTH}
        selectBoxWidth={INPUT_WIDTH}
        labelWidth={LABEL_WIDTH}
      />
    </FilterOptionsWrapper>
  );
};

export default UserSelect;
