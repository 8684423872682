import { useRef, useState } from 'react';
import { useBlocker } from './useBlocker';

type PromptResult = {
  showPrompt: boolean;
  cancelNavigation: () => void;
  confirmNavigation: () => void;
};

export function useCallbackPrompt(): PromptResult {
  const [showPrompt, setShowPrompt] = useState<boolean>(false);

  const nextTransition = useRef<any>();

  const handleBlockedNavigation = (nextLocation: any) => {
    if (nextLocation.location.pathname === '/auth/login') {
      nextLocation.retry();
    } else {
      setShowPrompt(true);
      nextTransition.current = nextLocation.retry;
    }
  };

  const cancelNavigation = () => {
    setShowPrompt(false);
  };

  const confirmNavigation = () => {
    setShowPrompt(false);
    nextTransition.current();
  };

  useBlocker(handleBlockedNavigation);

  return { showPrompt, confirmNavigation, cancelNavigation };
}
