import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectBoxWithLabel } from '../../components/SelectBox';
import { FilterOptionsWrapper } from '../../components';
import { selectBoxData } from '../../data';
import { FORM_INPUT_WIDTH, INPUT_WIDTH, LABEL_WIDTH } from './types';

const PlaceSelect: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <FilterOptionsWrapper>
      <SelectBoxWithLabel
        // TODO: Change in in https://sioux.atlassian.net/browse/ECA-357
        listItem={selectBoxData}
        label={t('report.auth.filter_option.select_door')}
        width={FORM_INPUT_WIDTH}
        selectBoxWidth={INPUT_WIDTH}
        labelWidth={LABEL_WIDTH}
      />

      <SelectBoxWithLabel
        // TODO: Change in in https://sioux.atlassian.net/browse/ECA-357
        listItem={selectBoxData}
        label={t('report.auth.filter_option.select_location')}
        width={FORM_INPUT_WIDTH}
        labelWidth={LABEL_WIDTH}
        selectBoxWidth={INPUT_WIDTH}
      />
    </FilterOptionsWrapper>
  );
};

export default PlaceSelect;
