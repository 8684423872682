import { camelCase, startCase } from 'lodash';

/**
 * This will convert all property keys string of Object into camelCase string.
 * Note that it cannot convert string whose words conjoin in lowercase or uppercase, for example:
 *   - username -> username
 *   - USERNAME -> username
 */
export function camelCaseKeysObj<T>(data: { [key: string]: string }): T {
  const newObj: any = {};

  for (const [key, value] of Object.entries(data)) {
    newObj[`${camelCase(key.trim())}`] = value;
  }

  return newObj;
}

/**
 * This will convert all property keys string of Object into Start Case property keys string.
 * Note that it cannot convert string whose words conjoin in lowercase or uppercase, for example:
 *   - username -> Username
 *   - USERNAME -> USERNAME
 */
export function startCaseKeysObj<T>(data: { [key: string]: string }): T {
  const newObj: any = {};
  for (const [key, value] of Object.entries(data)) {
    newObj[`${startCase(key.trim())}`] = value;
  }

  return newObj;
}

export function lowercaseKeysObj<T>(data: { [key: string]: string }): T {
  const newObj: any = {};
  for (const [key, value] of Object.entries(data)) {
    newObj[`${key.trim().toLowerCase()}`] = value;
  }

  return newObj;
}
