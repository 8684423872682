import React from 'react';

import { Button, ButtonProps, styled, Typography } from '@mui/material';

const StyledButton = styled(Button)(({ height, width }: { height: string | undefined; width: string | undefined }) => ({
  height: height,
  width: width,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const defaultProps: GeneralButtonProps = {
  height: 'initial',
  width: 'initial',
  content: ''
};

type GeneralButtonProps = {
  height?: string;
  width?: string;
  content?: string;
} & ButtonProps;

export const SecondaryButton: React.FunctionComponent<GeneralButtonProps> = (props: GeneralButtonProps) => {
  const { height, width, children, content } = props;
  return (
    <StyledButton variant="outlined" height={height} width={width} {...props}>
      {children}
      <Typography textTransform="initial" variant="subtitle4" color="primary">
        {content}
      </Typography>
    </StyledButton>
  );
};

SecondaryButton.defaultProps = defaultProps;
