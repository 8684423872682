import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { StandardTime } from '../../../../models';
import { EMPTY_VALUE } from '../../types';

type Props = {
  item: StandardTime;
};

const StandardTimeItem: React.FunctionComponent<Props> = (props: Props) => {
  const { item } = props;
  const { day, endTime, startTime } = item;

  return (
    <Wrapper>
      <Typography variant="body1" color="grey.200">
        {day}
      </Typography>

      <Typography variant="body1" color="grey.100">
        {startTime} {EMPTY_VALUE} {endTime}
      </Typography>
    </Wrapper>
  );
};

export default StandardTimeItem;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
`;
