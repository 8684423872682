import { Box, styled, TextField, TextFieldProps, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StandardTime } from '../../../../models';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { TimeFormatType } from '../../../../utils/date';
import { TimePick } from '../Edit/types';

const TimePickerTextField: React.FunctionComponent = (props: TextFieldProps) => (
  <TextField
    size="small"
    onKeyDown={e => {
      e.preventDefault();
      e.stopPropagation();
    }}
    disabled={true}
    sx={{ caretColor: theme.palette.common.white }}
    {...props}
  />
);

type StandardTimeItemProps = {
  item: StandardTime;
  onTimeChanged: (moment: Moment, day: string, type: TimePick) => void;
};

export const StandardTimeItem: React.FunctionComponent<StandardTimeItemProps> = (props: StandardTimeItemProps) => {
  const { item, onTimeChanged } = props;

  const { t } = useTranslation();

  const [time, setTime] = React.useState<StandardTime>(item);

  const handleTimeChanged = (moment: Moment | null, pickedTimeType: TimePick) => {
    if (moment) {
      onTimeChanged(moment, time.day, pickedTimeType);

      const newTime = { ...time };

      if (pickedTimeType === TimePick.START_TIME) {
        newTime.startTime = moment.format(TimeFormatType.HOUR_MINUTE);
      } else {
        newTime.endTime = moment.format(TimeFormatType.HOUR_MINUTE);
      }

      setTime(newTime);
    }
  };

  return (
    <Wrapper>
      <Typography width="100px">{time.day}</Typography>

      <TimePickerWrapper>
        <TimePicker
          value={moment(time.startTime, TimeFormatType.HOUR_MINUTE)}
          ampm={false}
          onChange={e => handleTimeChanged(e, TimePick.START_TIME)}
          renderInput={params => <TimePickerTextField {...params} />}
        />
      </TimePickerWrapper>

      <Typography>{t('group.time_connect_string')}</Typography>

      <TimePickerWrapper>
        <TimePicker
          value={moment(time.endTime, TimeFormatType.HOUR_MINUTE)}
          ampm={false}
          onChange={e => handleTimeChanged(e, TimePick.END_TIME)}
          renderInput={params => <TimePickerTextField {...params} />}
        />
      </TimePickerWrapper>
    </Wrapper>
  );
};

const { baseSM } = dimensions;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${baseSM};
  padding-bottom: ${baseSM};
`;

const TimePickerWrapper = styled(Box)`
  width: 120px;
`;
