import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { autocompleteSX } from './style';
import { NormalDropDownListProps } from './type';
import { DropDownListType } from '../../types/components';

type Props = Omit<NormalDropDownListProps, 'type' | 'label'>;

export const NormalDropDownList: React.FunctionComponent<Props> = (props: Props) => {
  const { width, height, defaultOption, loadOptions, onChangeLastOption, onChange, ...others } = props;
  const [options, setOptions] = React.useState<DropDownListType[]>([]);

  const setInitOptions = async () => {
    const users = await loadOptions();
    setOptions(users);
  };

  React.useEffect(() => {
    onChangeLastOption && onChangeLastOption(options[options.length - 1]);
  }, [options]);

  React.useEffect(() => {
    setInitOptions();
  }, [loadOptions]);

  return (
    <Autocomplete
      disablePortal
      defaultValue={defaultOption}
      options={options}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={option => option.label}
      onChange={(_, option) => onChange && onChange(option)}
      sx={{ ...autocompleteSX(width, height) }}
      renderInput={params => <TextField {...params} {...others} />}
    />
  );
};
