import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectBoxWithLabel } from '../../components/SelectBox';
import { FilterOptionsWrapper } from '../../components';
import { selectBoxData } from '../../data';
import { ReportLogFormField } from '../../types';

const { SELECT_DOOR_IDS, SELECT_LOCATION } = ReportLogFormField;

const LABEL_WIDTH = '120px';
const SELECT_BOX_MIN_WIDTH = '200px';
const SELECT_BOX_WIDTH = '62%';

const PlaceSelect: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <FilterOptionsWrapper width="30%" minWidth="300px">
      <SelectBoxWithLabel
        // TODO: Change in in https://sioux.atlassian.net/browse/ECA-357
        listItem={selectBoxData}
        name={SELECT_DOOR_IDS}
        label={t('report.log_history.filter_option.select_door')}
        selectBoxWidth={SELECT_BOX_WIDTH}
        labelWidth={LABEL_WIDTH}
        minWidth={SELECT_BOX_MIN_WIDTH}
      />

      <SelectBoxWithLabel
        // TODO: Change in in https://sioux.atlassian.net/browse/ECA-357
        listItem={selectBoxData}
        name={SELECT_LOCATION}
        label={t('report.log_history.filter_option.select_location')}
        selectBoxWidth={SELECT_BOX_WIDTH}
        labelWidth={LABEL_WIDTH}
        minWidth={SELECT_BOX_MIN_WIDTH}
      />
    </FilterOptionsWrapper>
  );
};

export default PlaceSelect;
