import { Box, CircularProgress, Fade } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Popup } from '../../../../components/Popup';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast/actions';

import { UserField } from '../../../../models';
import { BooleanValue } from '../../../../types/checkbox';
import { convertFileToBase64 } from '../../../../utils/fileHelper';
import { useCallbackPrompt } from '../../../../utils/useCallbackPrompt';
import { ContentWrapper } from '../../components/ContentWrapper';
import { LoadingWrapper } from '../../components/LoadingWrapper';
import { useUsersHook } from '../../store';
import { Body } from '../components/Body';
import { HeaderBar } from '../components/HeaderBar';

import { isCreateUserFieldsForm } from './type';
import { TimeFormatType, formatDateTime } from '../../../../utils/date';

export const AddNew: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [{ locationList }, { createUser, getAllUsers }] = useUsersHook();
  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = Object.fromEntries(new FormData(e.target));

    if (!isCreateUserFieldsForm(data)) return;

    const { avatar, emergency, locationId: locationName, startDate, endDate, tagActive, ...rest } = data;
    const locationId = locationList.find(item => item.location === locationName)?.id;

    const createFields: UserField = {
      ...rest,
      emergency: emergency ? BooleanValue.TRUE : BooleanValue.FALSE,
      avatar: await convertFileToBase64(avatar),
      tagActive: tagActive ? BooleanValue.TRUE : BooleanValue.FALSE,
      locationId: locationId ? `${locationId}` : '',
      startDate: formatDateTime(new Date(startDate), TimeFormatType.LONGDATETIME_V2),
      endDate: formatDateTime(new Date(endDate), TimeFormatType.LONGDATETIME_V2)
    };

    try {
      setLoading(true);
      const newUserId = await createUser(createFields);
      await getAllUsers();

      showSuccessToast({
        title: t('user.create.message.success.title'),
        subtitle: t('user.create.message.success.subtitle')
      });

      navigate(`/users/user/${newUserId}`);
      confirmNavigation();
    } catch (e: any) {
      showErrorToast({
        title: t('user.create.message.fail.title'),
        subtitle: t('user.create.message.fail.subtitle')
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Fade in={true}>
      <ContentWrapper>
        {loading && (
          <LoadingWrapper>
            <CircularProgress color="primary" size={40} />
          </LoadingWrapper>
        )}

        <Popup
          title={t('user.create.popup.title')}
          content={t('user.create.popup.content')}
          open={showPrompt}
          onClose={cancelNavigation}
          closeactiontext={t('logout.button.cancel')}
          action={{
            name: t('user.create.popup.title'),
            onAction: confirmNavigation
          }}
        />

        <Box component="form" onSubmit={handleSubmit} height="98%">
          <HeaderBar
            headerTitle={t('user.create.title')}
            actionTitle={t('user.create.save')}
            onClickCancel={handleCancel}
          />

          <Body isEdit={false} setLoading={setLoading} />
        </Box>
      </ContentWrapper>
    </Fade>
  );
};
