import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUsersStates } from '../../../store';
import GridTable from './GridTable';

export const VisitorsInGroup: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { groupInformation } = useUsersStates();

  const titles = [
    t('group.visitors_in_group.column.id'),
    t('group.visitors_in_group.column.visitor_name'),
    t('group.visitors_in_group.column.location')
  ];

  const visitors = groupInformation.visitorsInGroup?.users ?? [];

  return (
    <GridTable
      header={t('group.visitors_in_group.content')}
      titles={titles}
      rows={visitors.map(it => ({ visitorId: it.userId, visitorName: it.userName, location: it.locationName }))}
    />
  );
};
