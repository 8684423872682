import { UserField } from '../../../../models';

export type CreateUserFieldsForm = Omit<UserField, 'avatar' | 'groupIds'> & {
  avatar?: File;
  groupIds: string;
};

export const isCreateUserFieldsForm = (data: { [k: string]: FormDataEntryValue }): data is CreateUserFieldsForm => {
  return 'name' in data && 'groupIds' in data && 'tagNumber' in data && 'email' in data;
};
