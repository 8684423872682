import React from 'react';

import { useTranslation } from 'react-i18next';

import { CheckboxInput } from '../../../../components/Input';

type Props = {
  onAccessChanged: (value: boolean) => void;
};

const TemporaryAccessCheckbox: React.FunctionComponent<Props> = (props: Props) => {
  const { onAccessChanged } = props;

  const { t } = useTranslation();

  return (
    <>
      <CheckboxInput
        label={t('group.ask_for_temporary_group_access')}
        onChange={(_e, checked) => {
          onAccessChanged(checked);
        }}
      />
    </>
  );
};

export default TemporaryAccessCheckbox;
