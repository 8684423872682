import { Box, styled, Typography } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import { Column, CustomTable } from '../../../../../components/CustomTable';
import { dimensions } from '../../../../../styles/dimensions';
import { HistoryInfo } from '../../type';

type Props = {
  historyInfoList: HistoryInfo[];
};

enum TableColumns {
  Time = 'time',
  DoorName = 'doorName',
  Location = 'doorLocation',
  Message = 'message'
}

const getTableColumnName = (column: TableColumns, t: TFunction<'translation', undefined>): string => {
  switch (column) {
    case TableColumns.Time:
      return t('visitor.history_table.time');
    case TableColumns.DoorName:
      return t('visitor.history_table.door_name');
    case TableColumns.Location:
      return t('visitor.history_table.door_location');
    case TableColumns.Message:
      return t('visitor.history_table.message');
    default:
      return '';
  }
};

export const HistoryToday: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const tableColumns: Column[] = Object.values(TableColumns).map(column => {
    return {
      dataKey: column,
      fieldName: getTableColumnName(column, t)
    };
  });

  return (
    <Wrapper>
      <Typography variant="body1" color="grey.100" marginBottom={dimensions.base}>
        {t('visitor.history_today')}
      </Typography>

      <HistoryTableWrapper>
        <CustomTable columns={tableColumns} dataList={props.historyInfoList} cellMaxWidth="130px" />
      </HistoryTableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  flex: 1.5;
  height: 74vh;
  min-height: 500px;
  overflow-y: auto;
`;

const HistoryTableWrapper = styled(Box)`
  height: calc(100% - 48px);
  overflow-y: auto;
`;
