import React from 'react';

import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { theme } from '../../../../../styles/theme';
import { isEmpty } from 'lodash';
import { dimensions } from '../../../../../styles/dimensions';

type Props = {
  header: string;
  titles: string[];
  rows: { [key: string]: any }[];
};

const GridTable: React.FunctionComponent<Props> = (props: Props) => {
  const { header, titles, rows } = props;

  return (
    <Wrapper item xs={12} lg={6} xl={4}>
      <Typography variant="subtitle3" paddingBottom={baseLG}>
        {header}
      </Typography>

      <Container>
        <StyledTable stickyHeader>
          <TableHead>
            <Row>
              {titles.map((item, index) => (
                <TableTitle key={index}>{item}</TableTitle>
              ))}
            </Row>
          </TableHead>

          <Body>
            {!isEmpty(rows) &&
              rows.map((item, index) => (
                <Row key={index}>
                  {Object.values(item).map((val, index) => (
                    <RowContent key={index}>{val}</RowContent>
                  ))}
                </Row>
              ))}
          </Body>
        </StyledTable>
      </Container>
    </Wrapper>
  );
};

export default GridTable;

const { grey } = theme.palette;
const { baseXS, baseLG, baseMD, inputHeight } = dimensions;

const Wrapper = styled(Grid)`
  margin-top: ${baseLG};
  height: 466.5px;
  display: flex;
  flex-direction: column;
  row-gap: ${baseXS};
`;

const Container = styled(TableContainer)`
  flex: 1;
  width: 100%;
  height: 100%;
  border: 1px solid ${grey[400]};
`;

const Body = styled(TableBody)`
  overflow: auto;
`;

const StyledTable = styled(Table)`
  border: 1px solid ${grey[400]};
`;

const TableTitle = styled(TableCell)`
  padding: ${baseXS} ${baseMD};
  height: ${inputHeight};
  color: ${grey[100]};
  font-weight: 700;
  font-size: 16px;
`;

const RowContent = styled(TableCell)`
  padding: ${baseXS} ${baseMD};
  height: ${inputHeight};

  font-weight: 500;
  font-size: 14px;
`;

const Row = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${grey[500]};
  }
`;
