import React from 'react';

import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from '../../components/Button';

import { Column, SettingColumnAction } from './types';
import { dimensions } from '../../styles/dimensions';
import { useLogHook } from './store';
import { isEmpty } from 'lodash';
import { theme } from '../../styles/theme';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ColumnSettingsDialog: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { open, onClose } = props;

  const [{ columns }, { settingColumns }] = useLogHook();

  const [loading, setLoading] = React.useState<boolean>(false);

  const prevColumns = React.useRef<Column[]>(columns);

  const handleApply = async () => {
    prevColumns.current = columns;

    setLoading(true);
    await settingColumns(SettingColumnAction.APPLY);
    setLoading(false);

    onClose();
  };

  const handleCancel = () => {
    if (!isEmpty(prevColumns.current)) settingColumns(SettingColumnAction.EDIT, prevColumns.current);

    onClose();
  };

  const handleColumnSettingChanged = async (value: boolean, idx: number) => {
    const result = columns.map((col, index) => (index == idx ? { ...col, display: value } : col));

    setLoading(true);
    await settingColumns(SettingColumnAction.EDIT, result);
    setLoading(false);
  };

  React.useEffect(() => {
    if (isEmpty(prevColumns.current) && !isEmpty(columns)) prevColumns.current = columns;
  }, [columns]);

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      <Title variant="h4">
        {t('dashboard.column_settings.content')}

        <IconButton onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </Title>

      <DialogContent>
        {columns.map((column, index) => (
          <CheckBoxLine key={index}>
            <Typography variant="body1">{column.title}</Typography>

            <Checkbox checked={column.display} onChange={e => handleColumnSettingChanged(e.target.checked, index)} />
          </CheckBoxLine>
        ))}
      </DialogContent>

      <DialogActions>
        <SecondaryButton content={t('dashboard.column_settings.cancel')} onClick={handleCancel} />

        <PrimaryButton
          content={t('dashboard.column_settings.apply')}
          onClick={handleApply}
          startIcon={loading && <CircularProgress color="success" size={20} thickness={7} />}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ColumnSettingsDialog;

const CheckBoxLine = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  padding: ${dimensions.baseSM} 0;
  border-bottom: 1px solid ${theme.palette.grey[400]};
`;

const Title = styled(DialogTitle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 89%;
`;
