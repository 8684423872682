import { createActionsHook, createHook, createStateHook, createStore, StoreActionApi } from 'react-sweet-state';

import {
  AddNewGroupResponse,
  AddNewUserResponse,
  AddNewVisitorResponse,
  AllGroupInformationResponse,
  DoorAuthorization,
  EditGroupResponse,
  FreeTextLabel,
  FreeTextLabelResponse,
  Group,
  GroupInformation,
  GroupResponse,
  Location,
  LocationResponse,
  UserField,
  UserInfo,
  UserInGroup,
  UserLog,
  UserShortInfo,
  VisitorInfo,
  VisitorInGroup,
  VisitorShortInfo,
  VisitorShortInfoResponse
} from '../../models';

import { $get, $post, $put } from '../../utils/http';
import { ImportUserInfo, ImportUserRes } from './converter';
import { PopulateVisitor } from './Visitor/type';

import { PopulateLocationForm } from '../Location/type';
import { BLANK_STRING, DEFAULT_FREE_TEXT_LABEL, TAKEN_NUMBER } from './types';
import { formatDateTime, TimeFormatType } from '../../utils/date';
import { mockedGroupExportData } from './data';
import { isEmpty } from 'lodash';

interface DoorShortForm {
  doorId: string;
  doorName: string;
  doorLocation: string;
  processorName: string;
}

type State = {
  userList: UserShortInfo[];
  totalUsers: number;

  userInfo: UserInfo | null;
  createFields: UserField;

  userLogs: UserLog[];

  groupList: Group[];
  groupInformation: GroupInformation;

  existedDoors: DoorAuthorization[];
  existedUsers: UserInGroup[];
  existedVisitors: VisitorInGroup[];

  totalGroup: number;

  visitorInfo: VisitorInfo | null;
  visitorList: VisitorShortInfo[];
  totalVisitors: number;
  locationList: Location[];
  freeTextLabel: FreeTextLabel;
};

const initialState: State = {
  userList: [],
  totalUsers: 0,
  userInfo: null,
  createFields: {
    name: '',
    emergency: '',
    avatar: '',
    employeeNr: '',
    locationId: '',
    departement: '',
    phone: '',
    email: '',
    tagNumber: '',
    pin: '',
    tagActive: '',
    tagText: '',
    plate: '',
    vehicle: '',
    startDate: '',
    endDate: '',
    freeTextText1: '',
    freeTextText2: '',
    comment: '',
    groupIds: ''
  },
  userLogs: [],
  groupInformation: {
    groupId: '',
    groupName: '',
    groupTmpAccessEnabled: '',
    timeDetails: [],
    objectsEnabled: [],
    usersInGroup: {
      totalUsers: 0,
      users: []
    },
    visitorsInGroup: null
  },
  totalGroup: 0,
  groupList: [],
  visitorInfo: null,
  visitorList: [],
  existedDoors: [],
  existedUsers: [],
  existedVisitors: [],
  totalVisitors: 0,
  locationList: [],
  freeTextLabel: DEFAULT_FREE_TEXT_LABEL
};

export const actions = {
  getAllUsers:
    (id?: string) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      const response = await $get(`user/short`, {
        params: { fromId: id, take: TAKEN_NUMBER }
      });

      if (response) {
        const users: UserShortInfo[] = response.data.users;

        if (users) {
          setState({ totalUsers: response.total });

          if (id) {
            setState({ userList: getState().userList.concat(users) });
          } else {
            setState({ userList: users });
          }
        }
      }
    },

  setInitialUserList:
    () =>
    ({ setState }: StoreActionApi<State>) => {
      setState({ userList: [] });
    },

  getUsersByName:
    (name: string, id?: string) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      const response = await $get(`user/search`, {
        //"q" param contain name of user
        params: { q: name, take: TAKEN_NUMBER, fromId: id, short: BLANK_STRING }
      });

      if (response) {
        const users: UserShortInfo[] = response.data.users || [];
        setState({ totalUsers: response.total });

        if (id) {
          setState({
            userList: [...getState().userList, ...users]
          });
        } else {
          setState({ userList: users });
        }
      } else {
        setState({ totalUsers: 0, userList: [] });
      }
    },

  getUserInfo:
    (id: string) =>
    async ({ setState }: StoreActionApi<State>) => {
      const response = await $get(`user/${id}`);

      if (response) {
        const data: UserInfo = response.data;

        setState({ userInfo: data });

        return data;
      }
    },

  getFreeTextLabel:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      const response: FreeTextLabelResponse = await $get(`user/freeText`);

      if (response && response.data) {
        setState({ freeTextLabel: { ...response.data, comment: DEFAULT_FREE_TEXT_LABEL.comment } });
      } else {
        setState({ freeTextLabel: initialState.freeTextLabel });
      }
    },

  getLogsOfUser:
    (userId: string) =>
    async ({ setState }: StoreActionApi<State>) => {
      const date = formatDateTime(new Date(), TimeFormatType.REVERSED_SHORTDATE);

      const response = await $get(`log/user`, { params: { userId, date } });

      if (response) {
        const data: UserLog[] = response.data;

        setState({ userLogs: data });
      }
    },

  // TODO: Prevent create user when tag name or pin are duplicated
  // Implement in this task: https://sioux.atlassian.net/browse/ECA-331
  createUser: (userData: UserField) => async () => {
    const res: AddNewUserResponse = await $post('user/new', userData);

    const { data } = res;

    if (!data || !data.success) {
      throw new Error();
    }

    return data.id;
  },

  // TODO: Prevent edit user when tag name or pin are duplicated
  // Implement in this task: https://sioux.atlassian.net/browse/ECA-331
  editUser: (input: UserField, userId: string) => async () => {
    await $put(`user/${userId}`, input);
  },

  getExportUserInfoList: () => async (): Promise<{ [key: string]: any }[]> => {
    const response = await $get(`user/export`);

    const { data } = response;
    if (!data || !data.users) {
      throw new Error();
    }

    return data.users;
  },

  createUserInfoFromFile: (users: ImportUserInfo[]) => async () => {
    try {
      const response: ImportUserRes = await $post('user/import', { users });

      const { data } = response;

      if (!data || !data.success) {
        throw new Error();
      }
    } catch (_err) {
      throw new Error('user.import_user_toast.failed.subtitle.network_issue');
    }
  },

  setInitialGroups:
    () =>
    ({ setState }: StoreActionApi<State>) => {
      setState({ groupList: initialState.groupList });
    },

  getAllGroups:
    (fromId?: string) =>
    async ({ setState }: StoreActionApi<State>) => {
      const res: GroupResponse = await $get('group/short', {
        params: {
          fromId: fromId,
          take: TAKEN_NUMBER
        }
      });

      const { data, total } = res;

      if (data && total) {
        setState({ groupList: data, totalGroup: total });
      }
    },
  getAllGroupInformation: () => async () => {
    // TODO: remove mocked data in https://sioux.atlassian.net/browse/ECA-366
    await new Promise(resolve => setTimeout(resolve, 3000));

    return mockedGroupExportData.data;
  },

  getGroupsByName:
    (name: string, fromId?: string) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      const res: GroupResponse = await $get(`group/search`, {
        //"q" param contain name of group
        params: { q: name, take: TAKEN_NUMBER, fromId: fromId }
      });

      const { data, total } = res;

      if (!data) {
        if (!fromId) {
          setState({ groupList: [], totalGroup: 0 });
        }

        return;
      }

      setState({ totalGroup: total });

      if (fromId) {
        setState({
          groupList: [...getState().groupList, ...data]
        });
      } else {
        setState({ groupList: data });
      }
    },
  getGroupById:
    (id: string) =>
    async ({ setState }: StoreActionApi<State>) => {
      const res: AllGroupInformationResponse = await $get(`group/${id}`);
      setState({ groupInformation: res.data.length > 0 ? res.data[0] : undefined });
    },

  addNewGroup: (groupData: any) => async (): Promise<number> => {
    //TODO: Add New Group Request rework here https://sioux.atlassian.net/browse/ECA-332
    const res: AddNewGroupResponse = await $post('group/new', groupData);

    const { data } = res;

    if (!data || !data.success) {
      throw new Error('visitor.toast.failed.unexpected_error');
    }

    return data.id;
  },

  editGroup: (groupId: string, groupData: any) => async (): Promise<boolean> => {
    const res: EditGroupResponse = await $put(`group/${groupId}`, groupData);

    const { data } = res;

    if (!data || data.success !== 1) {
      throw new Error('visitor.toast.failed.unexpected_error');
    }

    return true;
  },

  getVisitorList:
    (id?: string, take?: number) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      const response: VisitorShortInfoResponse = await $get(`visitor/short`, {
        params: { fromId: id, take: take || TAKEN_NUMBER }
      });

      const { data, total } = response;

      if (!data.users || data.users.length === 0) return;

      const shortInfoVisitorList = data.users.map<VisitorShortInfo>(info => {
        return {
          id: info.id,
          visitorId: info.userId,
          name: info.name
        };
      });

      const newVisitorList = id ? getState().visitorList.concat(shortInfoVisitorList) : shortInfoVisitorList;

      setState({
        totalVisitors: total ? Number(total) : getState().totalVisitors,
        visitorList: newVisitorList
      });
    },

  setInitialVisitor:
    () =>
    ({ setState }: StoreActionApi<State>) => {
      setState({
        visitorList: [],
        visitorInfo: initialState.visitorInfo,
        totalVisitors: initialState.totalVisitors
      });
    },

  getVisitorsBySearch:
    (searchKey: string, fromId?: string) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      const response: VisitorShortInfoResponse = await $get(`visitor/search`, {
        //"q" stand for query keys which is string input
        params: { q: searchKey, take: TAKEN_NUMBER, fromId, short: BLANK_STRING }
      });

      if (!response) {
        setState({ totalVisitors: 0, visitorList: [] });
        return;
      }

      const { data, total } = response;

      if (!data.users || data.users.length === 0) {
        if (!fromId) {
          setState({
            visitorList: initialState.visitorList,
            totalVisitors: initialState.totalVisitors
          });
        }
        return;
      }

      const shortInfoSearchVisitorList = data.users.map<VisitorShortInfo>(info => {
        return {
          id: info.id,
          visitorId: info.userId,
          name: info.name
        };
      });

      const newSearchList = fromId
        ? getState().visitorList.concat(shortInfoSearchVisitorList)
        : shortInfoSearchVisitorList;

      setState({
        visitorList: newSearchList,
        totalVisitors: total ? Number(total) : getState().totalVisitors
      });
    },

  getVisitorInfo:
    (visitorId: string) =>
    async ({ setState }: StoreActionApi<State>) => {
      const response = await $get(`visitor/${visitorId}`);
      const { data } = response;

      if (data) {
        setState({
          visitorInfo: data
        });
      }
    },

  addNewVisitor: (visitorData: PopulateVisitor) => async (): Promise<string> => {
    const res: AddNewVisitorResponse = await $post('visitor/new', visitorData);

    const { data } = res;

    if (!data || !data.success) {
      throw new Error('visitor.toast.failed.unexpected_error');
    }

    return data.id;
  },

  getExistedDoors:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      const response = await $get('door/list');
      const { data } = response.objects;

      const doors = data.map((item: DoorShortForm) => ({
        doorId: item.doorId,
        doorName: item.doorName,
        doorLocation: item.doorLocation,
        moduleName: item.processorName
      }));

      setState({ existedDoors: doors });
    },
  getExistedUsers:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      const response = await $get(`user/short`, {
        params: { take: TAKEN_NUMBER }
      });

      if (response) {
        const users: UserShortInfo[] = response.data.users;
        const convertUsers: UserInGroup[] = users.map(it => ({
          userName: it.name,
          userId: it.userId,
          locationName: it.location
        }));
        setState({ existedUsers: convertUsers });
      } else {
        setState({ existedUsers: [] });
      }
    },
  getExistedVisitors:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      const response: VisitorShortInfoResponse = await $get(`visitor/short`, {
        params: { take: TAKEN_NUMBER }
      });

      const { users } = response.data;
      if (users.length !== 0) {
        const existedVisitors: VisitorInGroup[] = users.map(it => ({
          userId: it.userId,
          userName: it.name,
          locationName: it.location
        }));

        setState({ existedVisitors });
      }
    },

  sendEmailToVisitor: (_email: string) => () => {
    //TODO: Implement later
  },

  getLocations:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      const response: LocationResponse = await $get('location/all');
      const { data } = response;

      if (data && Array.isArray(data.locations)) {
        setState({ locationList: data.locations });
      }
    },

  createLocation: (data: PopulateLocationForm) => async () => {
    await $post('location/new', data);
  },

  editVisitor: (visitorId: string, visitorData: PopulateVisitor) => async () => {
    await $put(`visitor/${visitorId}`, visitorData);
  },

  addUserToGroup: (groupId: string, userIds: string[]) => async () => {
    const joinedUserIds = userIds.join(',');
    const params = {
      userId: joinedUserIds,
      groupId
    };

    await $post('group/addUser', params);
  },
  updateParticipantInGroup:
    (currentParticipantIds: string[]) =>
    async ({ getState }: StoreActionApi<State>) => {
      const { groupId, usersInGroup, visitorsInGroup } = getState().groupInformation;

      const prevUserIds = usersInGroup ? usersInGroup.users.map(item => item.userId) : [];
      const prevVisitorIds = visitorsInGroup ? visitorsInGroup.users.map(item => item.userId) : [];
      const prevParticipantIds = [...prevUserIds, ...prevVisitorIds];
      const newParticipantIds = currentParticipantIds.filter(item => !prevParticipantIds.includes(item));
      const removedParticipantIds = prevParticipantIds.filter(item => !currentParticipantIds.includes(item));

      const addUser = newParticipantIds.length > 0 ? newParticipantIds.join(',') : undefined;
      const delUser = removedParticipantIds.length > 0 ? removedParticipantIds.join(',') : undefined;
      await $put(`/group/${groupId}`, { addUser, delUser });
    },
  updateDoorsInGroup:
    (belongDoorsIds: string[]) =>
    async ({ getState }: StoreActionApi<State>) => {
      if (isEmpty(belongDoorsIds)) return;

      const doorIds = belongDoorsIds.join(',');

      await $put(`/group/${getState().groupInformation.groupId}`, { addDoor: doorIds });
    }
};

const Store = createStore({
  initialState,
  actions
});

export const useUsersHook = createHook(Store);
export const useUsersStates = createStateHook(Store);
export const useUsersActions = createActionsHook(Store);
