import { Box, Grow, styled, Typography, Zoom } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OptionButton, OptionButtonType } from '../../../../components/Button/OptionButton';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { useUsersHook } from '../../store';

export const HeaderUserInformation: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [{ userInfo }] = useUsersHook();

  const handleClickAddNewVisitorButton = () => {
    navigate('/users/visitor/add-new', { state: { id: userInfo?.id, name: userInfo?.name } });
  };

  const handleClickEditButton = () => {
    navigate('edit');
  };

  if (!userInfo) return <></>;

  return (
    <Wrapper>
      <InfoWrapper>
        <Grow in={true}>
          <UserImageWrapper src={userInfo.avatar} />
        </Grow>

        <Zoom in={true}>
          <Box display="flex" flexDirection="column" rowGap={`${baseSM}`}>
            <InfoWrapper>
              <Typography variant="h3" color="grey.100">
                {userInfo.name}
              </Typography>

              <Typography variant="h3" color="grey.200" paddingLeft={`${baseSM}`} borderLeft={`3px solid ${grey[200]}`}>
                {userInfo.id}
              </Typography>
            </InfoWrapper>

            <InfoWrapper>
              <Typography variant="body2" color="grey.200">
                {t('user.tag_number')}
              </Typography>
              <Typography variant="subtitle4" color="grey.100">
                {userInfo.tagNumber}
              </Typography>
            </InfoWrapper>

            <InfoWrapper>
              <Typography variant="body2" color="grey.200">
                {t('user.department')}
              </Typography>
              <Typography variant="subtitle4" color="grey.100">
                {userInfo.departement}
              </Typography>
            </InfoWrapper>
          </Box>
        </Zoom>
      </InfoWrapper>

      <ButtonWrapper>
        <OptionButton
          type={OptionButtonType.EDIT}
          content={t('edit')}
          onClick={handleClickEditButton}
          isscaledown={false}
        />

        <OptionButton
          type={OptionButtonType.ADD_NEW}
          content={t('user.add_visitor')}
          onClick={handleClickAddNewVisitorButton}
          isscaledown={false}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

const { base, baseXS, baseLG, baseSM, buttonHeight } = dimensions;
const { grey } = theme.palette;

const UserImageWrapper = styled(Box)(({ src }: { src: string | undefined }) => ({
  width: '120px',
  height: '120px',
  backgroundImage: `url(${src})`,
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  border: `1px solid ${grey[400]}`,
  borderRadius: `${baseXS}`,
  boxShadow: `${theme.shadows[2]}`
}));

const Wrapper = styled(Box)`
  padding: ${baseLG};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InfoWrapper = styled(Box)`
  display: flex;
  min-width: 200px;
  flex-direction: row;
  column-gap: ${base};
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  column-gap: ${base};
  row-gap: ${base};
  height: ${buttonHeight};
`;
