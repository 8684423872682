import { styled } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownList } from '../../../../components/DropDownList';
import { dimensions } from '../../../../styles/dimensions';
import { DropDownListType } from '../../../../types/components';
import { useUsersHook } from '../../store';
import { FormActionType } from '../type';
import { useLocation } from 'react-router-dom';

type Props = {
  formActionType: FormActionType;
};

export const ContractPersonForm: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [{ userList, visitorInfo }, { getUsersByName, getAllUsers }] = useUsersHook();

  const { formActionType } = props;

  const getPlaceholderText = (value: string): string => value.slice(0, -1);

  const loadOptions = async (): Promise<DropDownListType[]> => {
    return userList.map(user => {
      return {
        id: user.userId,
        label: `${user.name} | ${user.userId}`
      };
    });
  };

  const loadMoreOptions = async (filterKey?: string): Promise<void> => {
    if (!Array.isArray(userList)) return;

    const key = filterKey ? filterKey.split('|')[0] : '';

    const lastUserId = userList[userList.length - 1]?.userId;
    await getUsersByName(key.trim(), lastUserId);
  };

  const onFilterOptions = async (key: string): Promise<void> => {
    await getUsersByName(key.trim());
  };

  const onDropdownListOpen = async (label: string): Promise<void> => {
    if (label === '' && formActionType === FormActionType.ADD_NEW) {
      await getAllUsers();
    }
  };

  React.useEffect(() => {
    if (visitorInfo?.visitor && formActionType === FormActionType.EDIT) {
      onFilterOptions(visitorInfo.visitor.contactName);
    }
  }, [visitorInfo]);

  React.useEffect(() => {
    const contractorName = location.state?.name;
    if (contractorName) {
      getUsersByName(contractorName.trim());
    }
  }, [location.state]);

  const contractorId = location.state?.id ?? visitorInfo?.visitor.contactId;
  const person = userList.find(user => user.userId == contractorId);
  const defaultOptionCondition = formActionType === FormActionType.EDIT || location.state?.id;
  const defaultContractPerson = person && {
    id: person.userId,
    label: `${person.name} | ${person.userId}`
  };
  return (
    <InfoWrapper>
      <DropDownList
        label={t('visitor.contract_name')}
        type="lazy"
        height={base2XL}
        name="contractPersonName"
        loadOptions={loadOptions}
        loadMoreOptions={loadMoreOptions}
        onFilterOptions={onFilterOptions}
        placeholder={getPlaceholderText(t('visitor.contract_name'))}
        onDropdownListOpen={onDropdownListOpen}
        defaultOption={defaultOptionCondition ? defaultContractPerson : undefined}
        width="74%"
        required
      />
    </InfoWrapper>
  );
};

const { base2XL } = dimensions;

const InfoWrapper = styled(Box)`
  height: ${base2XL};
  width: 100%;
`;
