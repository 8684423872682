import * as React from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as TPicker } from '@mui/x-date-pickers/TimePicker';
import { theme } from '../styles/theme';

type Props = {
  label?: string;
  onChange?: (value: Dayjs) => void;
  width?: string;
  height?: string;
  name?: string;
  defaultValue?: Dayjs;
  required?: boolean;
};

export const TimePicker: React.FunctionComponent<Props> = (props: Props) => {
  const { label = '', onChange, width, height, name = '', defaultValue, required = false } = props;
  const [displayValue, setDisplayValue] = React.useState<Dayjs | null>(defaultValue || null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TPicker
        value={displayValue}
        label={label}
        ampm={false}
        onChange={newValue => {
          setDisplayValue(newValue);
          if (newValue && onChange) {
            onChange(newValue);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            required={required}
            name={name}
            sx={{
              width,
              height,
              '& > .MuiInputBase-root': {
                width,
                height
              },
              'input.MuiInputBase-input': {
                width,
                height
              },
              '& fieldset': {
                borderColor: `${grey[400]}`
              },
              '&:hover fieldset': {
                borderColor: `${grey[400]}`
              }
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const {
  palette: { grey }
} = theme;
