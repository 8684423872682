import { Autocomplete, createFilterOptions, SelectProps, styled, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { showErrorToast, showSuccessToast } from '../../../components/Toast/actions';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';
import { useUsersHook } from '../store';
import { BLANK_STRING, EXCEPTION_ID, INITIAL_VALUE } from '../types';

type SelectBoxType = {
  id: number;
  location: string;
  inputValue?: string;
};

type SelectBoxProps = {
  width?: string;
  label?: string;
  name?: string;

  defaultValue: string;
  setLoading: (value: boolean) => void;
} & SelectProps;

export const LocationDropBox: React.FunctionComponent<SelectBoxProps> = (props: SelectBoxProps) => {
  const { label, name, width, defaultValue, setLoading } = props;

  const [{ locationList }, { createLocation, getLocations }] = useUsersHook();

  const { t } = useTranslation();

  const INITIAL_LOCATION = defaultValue ? { id: EXCEPTION_ID, location: defaultValue } : undefined;

  const [input, setInput] = React.useState<string>(BLANK_STRING);
  const [value, setValue] = React.useState<SelectBoxType | undefined>(INITIAL_LOCATION);
  const [optionList, setOptionList] = React.useState<SelectBoxType[]>([]);
  const filter = createFilterOptions<SelectBoxType>();

  const handleAddMoreLocation = async () => {
    if (!input.trim()) {
      showErrorToast({
        title: t('location.create.fail_toast.title'),
        subtitle: t('location.create.fail_toast.subtitle.invalid_name')
      });
      return;
    }

    const data = {
      name: input,
      minEmergencyTeam: INITIAL_VALUE,
      emergencyTeamPersons: INITIAL_VALUE
    };

    try {
      setLoading(true);

      await createLocation(data);
      await getLocations();

      showSuccessToast({
        title: t('location.create.success_toast.title'),
        subtitle: t('location.create.success_toast.subtitle')
      });
    } catch (error) {
      showErrorToast({
        title: t('location.create.fail_toast.title'),
        subtitle: t('location.create.fail_toast.subtitle.other')
      });
    } finally {
      setLoading(false);
    }
  };

  async function getAllLocation() {
    await getLocations();
    if (defaultValue) {
      const currentLocation = locationList.find(item => item.location === defaultValue);
      currentLocation && setValue(currentLocation);
    }
  }

  React.useEffect(() => {
    getAllLocation();
  }, []);

  React.useEffect(() => {
    const newLocation = optionList.find(item => item.location === input);
    newLocation && setValue(newLocation);
  }, [optionList]);

  React.useEffect(() => {
    setOptionList(locationList);
  }, [locationList]);

  return (
    <Autocomplete
      size="small"
      value={value}
      options={optionList}
      disableClearable={!value}
      renderOption={(props, option) => (
        <OptionItem {...props} variant="body1" color={option.inputValue ? `${info.main}` : `${grey[100]}`}>
          {option.location}
        </OptionItem>
      )}
      isOptionEqualToValue={(option, _value) =>
        option.location === value?.location || option.location === value?.inputValue
      }
      getOptionLabel={option => (option.inputValue ? option.inputValue : option.location)}
      onInputChange={(_event, input) => {
        setInput(input);
      }}
      onChange={(_, currentLocation) => {
        if (currentLocation) {
          currentLocation.inputValue && handleAddMoreLocation();
          setValue(currentLocation);
        } else {
          setValue(undefined);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        if (inputValue !== BLANK_STRING) {
          filtered.push({
            inputValue: input,
            id: EXCEPTION_ID,
            location: t('location.add_new')
          });
        }

        return filtered;
      }}
      sx={autocompleteSX(width)}
      renderInput={params => <TextField {...params} name={name} label={label} />}
    />
  );
};

const { baseSM, dropdownItemHeight } = dimensions;
const { grey, info } = theme.palette;

const OptionItem = styled(Typography)`
  height: ${dropdownItemHeight};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 ${baseSM};
  border-bottom: 1px solid ${grey[400]};
`;

const autocompleteSX = (width?: string) => ({
  width,

  '& .MuiOutlinedInput-root': {
    borderRadius: '2px',
    paddingTop: '0',
    paddingBottom: '0',
    '& fieldset': {
      borderColor: `${grey[400]}`
    },
    '&:hover fieldset': {
      borderColor: `${grey[400]}`
    }
  }
});
