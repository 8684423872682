import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';
import { useLocationStates } from '../store';

export const LocationInformation: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { locationInfo } = useLocationStates();

  return (
    <UserInfoWrapper>
      <InfoLineWrapper>
        <Label variant="body1">{t('location.name')}</Label>

        <Typography variant="body1" color="grey.100">
          {locationInfo.location}
        </Typography>
      </InfoLineWrapper>

      <InfoLineWrapper>
        <Label variant="body1">{t('location.average_emergency')}</Label>

        <Typography variant="body1" color="grey.100">
          {locationInfo.minEmergencyTeam} / {locationInfo.emergencyTeamPersons} {t('location.person')}
        </Typography>
      </InfoLineWrapper>
    </UserInfoWrapper>
  );
};

const { baseLG, baseMD, baseXM } = dimensions;

const UserInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: ${baseMD} ${baseLG};
  width: calc(100% - 48px);
  row-gap: ${baseXM};
`;

const InfoLineWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const Label = styled(Typography)`
  width: 150px;
  color: ${theme.palette.grey[200]};
`;
