import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../styles/theme';

import { PrimaryButton } from '../../components/Button';

import { ReactComponent as Key } from '../../assets/image/Key.svg';
import { ReactComponent as Person } from '../../assets/image/Person.svg';

import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../appContext';
import { ErrorPopup } from '../../components/Popup';
import { dimensions } from '../../styles/dimensions';
import { isAuthenticated } from '../../utils/storage';
import { useAuthHook } from './store';
import { AuthError, InputFieldType } from './type';
import { LoginInfo, LoginResponse } from '../../models';
import { MainLayout, InputField } from './components';

const { primary } = theme.palette;
const { baseSM, baseXL, baseXM, buttonHeightLarge } = dimensions;

const Login: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showLoading, hideLoading } = useAppContext();

  const [isError, setIsError] = React.useState<boolean>(false);
  const [username, setUserName] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [{ authentication }, { login }] = useAuthHook();

  const [error, setError] = React.useState<AuthError>({
    title: '',

    content: ''
  });

  async function handleLogin(loginInformation: LoginInfo) {
    const { username, password } = loginInformation;

    try {
      showLoading();
      const response: LoginResponse = await login(username, password);

      console.log('response', response);
      hideLoading();
      const { twoFaKeySetRequired, twoFaValueExpected, qrImage, secret } = response;

      const encoded = btoa(
        JSON.stringify({
          qrImage: qrImage,
          secret: secret
        })
      );

      if (twoFaKeySetRequired) {
        if (twoFaValueExpected) {
          navigate(`/auth/second-auth/verify/${encoded}`);
        } else {
          navigate(`/auth/second-auth/add-account/${encoded}`);
        }
      } else {
        navigate('/');
      }
    } catch (error) {
      hideLoading();
      setIsError(true);

      setError({
        title: t('login.error.wrong_username_or_password.title'),

        content: t('login.error.wrong_username_or_password.content')
      });
    }
  }
  React.useEffect(() => {
    const isSetup2Fa = authentication && authentication.twoFaKeySetRequired;

    if (!isSetup2Fa && isAuthenticated()) {
      navigate('/');
    }
  }, [authentication]);

  return (
    <MainLayout>
      <Content>
        <Title>
          <Typography variant="h1">{t('login.title.welcome')}</Typography>

          <Typography variant="body1" color="grey.200" textAlign="center" paddingTop={baseSM}>
            {t('login.title.content')}
          </Typography>
        </Title>

        <InputWrapper>
          <InputField
            icon={<Person />}
            type={InputFieldType.TEXT}
            value={username}
            label={t('login.username')}
            placeholder={t('login.input_username')}
            onChange={value => setUserName(value)}
          />

          <InputField
            icon={<Key />}
            type={InputFieldType.PASSWORD}
            value={password}
            label={t('login.password')}
            placeholder={t('login.input_password')}
            onChange={value => setPassword(value)}
          />

          <ForgotPasswordLink to="/auth/forgot-password">{t('forgot_password.title')}</ForgotPasswordLink>
        </InputWrapper>

        <PrimaryButton
          content={t('login.content')}
          width="100%"
          height={buttonHeightLarge}
          onClick={() => handleLogin({ username, password })}
        />

        <ErrorPopup open={isError} onClose={() => setIsError(false)} title={error.title} content={error.content} />
      </Content>
    </MainLayout>
  );
};

export default Login;

const ForgotPasswordLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  color: ${primary.dark};
  align-self: flex-end;
`;

const Content = styled(Box)`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${baseXM};
  padding-bottom: ${baseXL};

  @media (min-height: 541px) {
    padding-top: 8%;
  }
`;
