import React from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps
} from '@mui/material';
import { theme } from '../../styles/theme';
import { dimensions } from '../../styles/dimensions';
import { useDoorHook } from './store';
import { useTranslation } from 'react-i18next';
import { Door } from '../../models/door';
import { POLLING_TIME } from '../Dashboard/types';
import { showErrorToast, showSuccessToast } from '../../components/Toast/actions';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { DoorActions, DoorStatus, DoorTableColumn } from './types';
import { getColWidth, getRowColor, statusBoxBackgroundColor, statusBoxMainColor } from './utils';

const DoorManagement: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [{ doorList }, { getAllDoors, changeDoorStatus }] = useDoorHook();
  const [loading, showLoading] = React.useState<boolean>(false);

  const tableColumns = [
    { dataKey: DoorTableColumn.ID, fieldName: `${t('door.id')}`, padding: `${baseSM} ${baseLG}` },
    { dataKey: DoorTableColumn.DOOR_NAME, fieldName: `${t('door.door_name')}`, padding: baseSM },
    {
      dataKey: DoorTableColumn.DOOR_LOCATION,
      fieldName: `${t('door.door_location')}`,
      padding: baseSM
    },
    { dataKey: DoorTableColumn.STATUS, fieldName: `${t('door.status')}`, padding: baseSM }
  ];

  const dataGetter = (data: Record<string, string | number | Date>, id: string): any => data[id];

  const isActionDisabled = (action: DoorActions, status: DoorStatus): boolean => {
    switch (status) {
      case DoorStatus.CLOSED:
        return false;
      case DoorStatus.DISCONNECTED:
        return true;
      case DoorStatus.OPENING:
        return action === DoorActions.OPEN;
      case DoorStatus.OPEN_PERMANENT:
        return action !== DoorActions.RESET_DEFAULT;
    }
  };

  const getActionColor = (action: DoorActions, status: DoorStatus): string =>
    isActionDisabled(action, status) ? grey[300] : primary.dark;

  const getActionCursor = (action: DoorActions, status: DoorStatus): string =>
    isActionDisabled(action, status) ? 'context-menu' : 'pointer';

  const getDoorStatusByAction = (action: DoorActions): DoorStatus => {
    switch (action) {
      case DoorActions.OPEN:
        return DoorStatus.OPENING;
      case DoorActions.OPEN_PERMANENT:
        return DoorStatus.OPEN_PERMANENT;
      case DoorActions.RESET_DEFAULT:
        return DoorStatus.CLOSED;
    }
  };

  const renderCell = (info: Door, prop: string) => {
    if (prop !== DoorTableColumn.STATUS) return dataGetter(info, prop);

    return <StatusBox status={dataGetter(info, prop)}>{dataGetter(info, prop)}</StatusBox>;
  };

  const onActionClick = async (id: string, action: DoorActions, status: DoorStatus): Promise<void> => {
    if (isActionDisabled(action, status)) return;
    showLoading(true);
    try {
      await changeDoorStatus(id, getDoorStatusByAction(action));
      showSuccessToast({
        title: t('door.toast.success.title'),
        subtitle: t('door.toast.success.subtitle')
      });
    } catch (_err) {
      showErrorToast({
        title: t('door.toast.failed.title'),
        subtitle: t('door.toast.failed.subtitle')
      });
    } finally {
      showLoading(false);
    }
  };

  const getDoors = async () => {
    showLoading(true);
    await getAllDoors();
    showLoading(false);
  };

  React.useLayoutEffect(() => {
    getDoors();
    const pollingGetAllDoors = setInterval(() => {
      getAllDoors();
    }, POLLING_TIME);
    return () => clearInterval(pollingGetAllDoors);
  }, []);

  return (
    <Wrapper>
      <Header>
        <Typography variant="subtitle1" color="grey.100">
          {t('door.page_title')}
        </Typography>
      </Header>

      {loading ? (
        <LoadingIndicator size={48} width="100%" height={`calc(100% - ${rowHeight})`} position="relative" />
      ) : (
        <Body>
          <Container>
            <StyledTable stickyHeader>
              <StyledTableHead>
                <TableRow>
                  {tableColumns.map(column => (
                    <TitleCell key={column.dataKey} paddingitem={column.padding} coltitle={column.dataKey}>
                      {column.fieldName}
                    </TitleCell>
                  ))}
                  <TitleCell paddingitem={baseSM} coltitle={DoorTableColumn.ACTIONS}>
                    {t('door.actions')}
                  </TitleCell>
                </TableRow>
              </StyledTableHead>

              <TableBody>
                {doorList.map(info => (
                  <InformationRow key={info.doorId} status={info.doorStatus}>
                    {tableColumns.map(column => (
                      <InformationCell key={column.dataKey} paddingitem={column.padding} coltitle={column.dataKey}>
                        {renderCell(info, column.dataKey)}
                      </InformationCell>
                    ))}

                    <InformationCell paddingitem={baseSM} coltitle={DoorTableColumn.ACTIONS}>
                      {Object.values(DoorActions).map(action => (
                        <HyperLinkText
                          key={action}
                          onClick={async () => await onActionClick(info.doorId, action, info.doorStatus)}
                          variant="hyperlink"
                          color={getActionColor(action, info.doorStatus)}
                          cursor={getActionCursor(action, info.doorStatus)}>
                          {action}
                        </HyperLinkText>
                      ))}
                    </InformationCell>
                  </InformationRow>
                ))}
              </TableBody>
            </StyledTable>
          </Container>
        </Body>
      )}
    </Wrapper>
  );
};

export default DoorManagement;

const { baseSM, base3XL, baseMD, baseLG, rowHeight } = dimensions;

const {
  palette: {
    grey,
    primary,
    common: { white }
  }
} = theme;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${grey[500]};
  height: 100%;
  overflow: auto;
`;

const Header = styled(Box)`
  background-color: ${white};
  padding-left: ${baseLG};
  height: ${base3XL};
  display: flex;
  align-items: center;
`;

const Body = styled(Box)`
  background-color: ${white};
  height: 100%;
  overflow: auto;
`;

const Container = styled(TableContainer)`
  border: 1px solid ${grey[400]};
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  overflow: auto;
`;

const TitleCell = styled(TableCell)<{ paddingitem?: string; coltitle: DoorTableColumn }>(
  ({ paddingitem, coltitle }) => ({
    color: `${grey[100]}`,
    fontWeight: 700,
    fontSize: '16px',
    padding: paddingitem,
    width: getColWidth(coltitle),
    [theme.breakpoints.down('lg')]: {
      width: getColWidth(coltitle, true)
    }
  })
);

const StyledTable = styled(Table)`
  height: 100%;
  ${props => props.theme.breakpoints.down('xl')} {
    min-height: 300px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`;

const InformationRow = styled(TableRow)<{ status: DoorStatus }>(({ status }) => ({
  backgroundColor: getRowColor(status),
  ['&:nth-of-type(odd)']: {
    backgroundColor: getRowColor(status, true)
  }
}));

const InformationCell = styled(TableCell)<{ paddingitem?: string; coltitle: DoorTableColumn }>(
  ({ paddingitem, coltitle }) => ({
    fontWeight: 500,
    fontSize: '14px',
    padding: paddingitem,
    width: getColWidth(coltitle),
    [theme.breakpoints.down('lg')]: {
      width: getColWidth(coltitle, true)
    }
  })
);

const StatusBox = styled(Box)<{ status: DoorStatus }>(({ status }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '133px',
  height: '34px',
  padding: '0 10px',
  fontSize: 12,
  fontWeight: 600,
  color: statusBoxMainColor(status),
  backgroundColor: statusBoxBackgroundColor(status),
  border: `1px solid ${statusBoxMainColor(status)}`,
  borderRadius: 2
}));

const HyperLinkText = styled(Typography)<TypographyProps & { cursor: string }>(({ cursor }) => ({
  cursor,
  textDecoration: 'underline',
  marginRight: `${baseMD}`
}));

const StyledTableHead = styled(TableHead)`
  height: ${rowHeight};
`;
