import { toast } from 'react-toastify';
import { theme } from '../../styles/theme';
import { Message } from './Message';
import { MessageProps } from './type';

export const showSuccessToast = (props: MessageProps): void => {
  const { title, subtitle } = props;

  toast.success(<Message title={title} subtitle={subtitle} />, {
    style: {
      border: `1px solid ${theme.palette.primary.main}`
    }
  });
};

export const showErrorToast = (props: MessageProps): void => {
  const { title, subtitle } = props;

  toast.error(<Message title={title} subtitle={subtitle} />, {
    style: {
      border: `1px solid ${theme.palette.error.main}`
    }
  });
};
