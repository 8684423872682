import { Route, Routes } from 'react-router-dom';
import User from './User';
import Group from './Group';
import Visitor from './Visitor';
import NotFound from '../../components/NotFound';
import { SideBarItemType } from '../../types/route';

const Users: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path={`/${SideBarItemType.User}/*`} element={<User />} />
      <Route path={`/${SideBarItemType.Group}/*`} element={<Group />} />
      <Route path={`/${SideBarItemType.Visitor}/*`} element={<Visitor />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default Users;
