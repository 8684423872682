import { isEmpty } from 'lodash';
import moment from 'moment';
import { MessageProps } from '../../../components/Toast/type';
import { PopulateGroupParams } from '../../../models';
import { TimeFormatType } from '../../../utils/date';
import { BLANK_STRING } from '../types';
import { ActionType } from './types';

const timeFormat = (time: string) => moment(time, TimeFormatType.HOUR_MINUTE);

type ErrorMessage = {
  data?: any;
} & MessageProps;
export const validateGroupForm = (param: PopulateGroupParams, type: ActionType): ErrorMessage | undefined => {
  const { timeDetails, groupName } = param;

  if (groupName.trim() === BLANK_STRING)
    return {
      title: `group.${type}.message.fail.title`,
      subtitle: `group.${type}.message.fail.missing_required_field`
    };

  const invalidTimes = timeDetails.filter(item => timeFormat(item.startTime).isAfter(timeFormat(item.endTime)));

  if (!isEmpty(invalidTimes)) {
    const invalidDates = invalidTimes.map(time => time.day).join(', ');

    return {
      title: `group.${type}.message.fail.title`,
      subtitle: `group.${type}.message.fail.standard_time_not_valid`,
      data: { days: invalidDates }
    };
  }
};
