import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUsersStates } from '../../../store';
import GridTable from './GridTable';

export const UsersInGroup: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { groupInformation } = useUsersStates();

  const titles = [
    t('group.users_in_group.column.id'),
    t('group.users_in_group.column.username'),
    t('group.users_in_group.column.location')
  ];

  const users = groupInformation.usersInGroup?.users ?? [];

  return (
    <GridTable
      header={t('group.users_in_group.content')}
      titles={titles}
      rows={users.map(it => ({ userId: it.userId, userName: it.userName, location: it.locationName }))} // eslint-disable-line
    />
  );
};
