import { createActionsHook, createHook, createStateHook, createStore, StoreActionApi } from 'react-sweet-state';
import { Location, LocationResponse, AddNewLocationResponse } from '../../models';
import { $get, $post, $put } from '../../utils/http';
import { PopulateLocationForm } from './type';

type State = {
  totalLocation: number;
  locationList: Location[];
  locationInfo: Location;
};

const initialState: State = {
  totalLocation: 0,

  locationList: [],

  locationInfo: {
    id: -1,
    location: '',
    minEmergencyTeam: 0,
    emergencyTeamPersons: 0
  }
};

export const actions = {
  getLocations:
    (keyword?: string, id?: number) =>
    async ({ setState }: StoreActionApi<State>) => {
      //TODO: will be alinged in https://sioux.atlassian.net/browse/ECA-342
      const response: LocationResponse = await $get('location/all', {
        params: { q: keyword, id: id }
      });
      const { data, total } = response;

      if (data && total) {
        setState({ locationList: data.locations, totalLocation: total });
      }
    },

  getLocationInfo:
    (id: string) =>
    async ({ setState }: StoreActionApi<State>) => {
      const response: LocationResponse = await $get(`location/${id}`);

      const { data } = response;

      if (data && data.locations[0]) {
        setState({ locationInfo: data.locations[0] });
      }
    },

  getLocationByName:
    (value: string) =>
    async ({ setState }: StoreActionApi<State>) => {
      // TODO: Will be implemented in https://sioux.atlassian.net/browse/ECA-333
      const response: LocationResponse = await $get('location/search', {
        params: {
          q: value
        }
      });
      const { data, total } = response;

      if (data) {
        setState({ locationList: data.locations, totalLocation: total });
      }
    },

  createLocation: (locationData: PopulateLocationForm) => async () => {
    const res: AddNewLocationResponse = await $post('location/new', locationData);

    const { data } = res;

    if (!data || !data.success) {
      throw new Error();
    }

    return data.id;
  },

  editLocation: (id: string, data: PopulateLocationForm) => async () => {
    await $put(`location/${id}`, data);
  },

  setInitialLocationList:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      setState({ locationList: initialState.locationList });
    }
};

const Store = createStore({
  initialState,
  actions
});

export const useLocationHook = createHook(Store);
export const useLocationStates = createStateHook(Store);
export const useLocationActions = createActionsHook(Store);
