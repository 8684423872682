import { Box, styled } from '@mui/material';
import { dimensions } from '../../../styles/dimensions';

const { base } = dimensions;

export const TemporaryAccess = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: ${base};
`;
