import { Box, Typography } from '@mui/material';
import { dimensions } from '../../styles/dimensions';
import { MessageProps } from './type';

export const Message: React.FunctionComponent<MessageProps> = (props: MessageProps) => {
  const { title, subtitle } = props;
  return (
    <Box display="flex" flexDirection="column" margin={`0 ${dimensions.baseXM}`}>
      <Typography variant="subtitle2" color="grey.100">
        {title}
      </Typography>
      <Typography variant="body2" color="grey.100">
        {subtitle}
      </Typography>
    </Box>
  );
};
