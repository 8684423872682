import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../components/Input';
import { useUsersStates } from '../../store';

type Props = {
  onGroupNameChanged: (value: string) => void;
};

const GroupNameInput: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { onGroupNameChanged } = props;

  const { groupInformation } = useUsersStates();

  if (!groupInformation) return <></>;

  return (
    <TextInput
      required={true}
      size="small"
      defaultValue={groupInformation.groupName}
      label={t('group.add_new.group_name.content')}
      placeholder={t('group.add_new.group_name.placeholder')}
      onChange={e => {
        onGroupNameChanged(e.target.value);
      }}
    />
  );
};

export default GroupNameInput;
