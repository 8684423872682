import { Box, styled, TableContainer, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column, CustomTable } from '../../components/CustomTable';
import { SelectBox, SelectBoxType } from '../../components/SelectBox';
import { Location } from '../../models';
import { EmergencyInfo } from '../../models/emergency';
import { dimensions } from '../../styles/dimensions';
import { theme } from '../../styles/theme';
import { useEmergencyHook } from './store';
import { ALL, DEFAULT_ID, EmergencyKeys, StatusEmergencyTeam } from './type';
import { CircularLoading } from '../Users/Group/components';
import { combineMemberInMultipleLocation } from './utils';

const EmergencyTeam: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [{ locationList, locationsInfo, emergencyList }, { getLocations, getEmergencyTeams }] = useEmergencyHook();

  const [loading, setLoading] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<Location>();
  const [emergencyMembers, setEmergencyMembers] = useState<EmergencyInfo[]>([]);

  const listOptions: SelectBoxType[] = locationList.map(item => ({
    id: item.id,
    value: item.location
  }));

  listOptions.unshift({ id: DEFAULT_ID, value: ALL });

  const columns: Column[] = [
    {
      dataKey: EmergencyKeys.ID,
      fieldName: t('emergency_team.id')
    },
    {
      dataKey: EmergencyKeys.NAME,
      fieldName: t('emergency_team.name')
    },
    {
      dataKey: EmergencyKeys.LOCATION,
      fieldName: t('emergency_team.location')
    },
    {
      dataKey: EmergencyKeys.STATUS,
      fieldName: t('emergency_team.status'),
      align: 'center'
    }
  ];

  const sortListByStatus = (emergencyMembers: EmergencyInfo[]) => {
    const availableTeam = emergencyMembers.filter(team => team.status === StatusEmergencyTeam.ON);
    const unavailableTeam = emergencyMembers.filter(team => team.status === StatusEmergencyTeam.OFF);
    return availableTeam.concat(unavailableTeam);
  };

  const setDataColor = (colName: string, rowData: string | number | Date): string | undefined => {
    if (colName !== EmergencyKeys.STATUS) return;

    return rowData === StatusEmergencyTeam.ON ? primary.main : grey[100];
  };

  const handleChooseLocation = async (value: string) => {
    if (value === ALL) {
      setEmergencyMembers(combineMemberInMultipleLocation(emergencyList));
      setCurrentLocation(undefined);
    } else {
      const members = emergencyList.filter(member => member.location === value);
      const locationInfo = locationsInfo.find(item => item.location === value);

      setEmergencyMembers(members);
      setCurrentLocation(locationInfo);
    }
  };

  const initData = async () => {
    setLoading(true);
    await Promise.all([getLocations(), getEmergencyTeams()]);
    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    setEmergencyMembers(currentLocation ? emergencyList : combineMemberInMultipleLocation(emergencyList));
  }, [emergencyList]);

  return (
    <Wrapper>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <HeaderWrapper>
            <Typography variant="subtitle1" color="grey.100">
              {currentLocation?.minEmergencyTeam
                ? t('emergency_team.title', { persons: currentLocation?.minEmergencyTeam })
                : t('emergency_team.title_without_persons')}
            </Typography>

            <Typography variant="subtitle4" color="grey.100">
              {t('emergency_team.location')}
            </Typography>

            <SelectBox width="300px" listItem={listOptions} defaultValue={ALL} onChose={handleChooseLocation} />
            {currentLocation && currentLocation.id !== DEFAULT_ID && (
              <Typography variant="subtitle4" color="grey.100">
                {t('emergency_team.persons_available', {
                  available: currentLocation.minEmergencyTeam,
                  max: currentLocation.emergencyTeamPersons
                })}
              </Typography>
            )}
          </HeaderWrapper>
          <EmergencyTeamContainer>
            <CustomTable columns={columns} dataList={sortListByStatus(emergencyMembers)} setDataColor={setDataColor} />
          </EmergencyTeamContainer>
        </>
      )}
    </Wrapper>
  );
};

export default EmergencyTeam;

const { common, primary, grey } = theme.palette;

const { baseLG, baseMD, baseXM, base } = dimensions;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: ${baseXM};
  padding: ${baseLG} 0 0 ${baseLG};
`;

const Wrapper = styled(Box)`
  height: calc(100%);
  display: flex;
  flex-direction: column;
  row-gap: ${baseMD};
  position: relative;
  background-color: ${common.white};
  margin-bottom: ${base};
`;

const EmergencyTeamContainer = styled(TableContainer)`
  height: calc(100% - 48px);
  overflow-y: auto;
`;
