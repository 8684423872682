import { AllGroupInformationResponse } from '../../models';

export const mockedGroupExportData: AllGroupInformationResponse = {
  data: [
    {
      groupId: '1',
      groupName: 'Algemeen1',
      groupTmpAccessEnabled: '1',
      timeDetails: [
        {
          day: 'Monday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Tuesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Wednesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Thursday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Friday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Saturday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Sunday',
          startTime: '00:00',
          endTime: '00:00'
        }
      ],
      objectsEnabled: [
        {
          doorId: '1',
          doorLocation: 'Eindhoven',
          doorName: '#Deur Garage 1',
          moduleName: 'Xport X1',
          doorDisabled: 0,
          doorLocationId: 1
        }
      ],
      usersInGroup: {
        totalUsers: 2,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      },
      visitorsInGroup: {
        totalVisitors: 1,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '46',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      }
    },
    {
      groupId: '2',
      groupName: 'Algemee2',
      groupTmpAccessEnabled: '1',
      timeDetails: [
        {
          day: 'Monday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Tuesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Wednesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Thursday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Friday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Saturday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Sunday',
          startTime: '00:00',
          endTime: '00:00'
        }
      ],
      objectsEnabled: [
        {
          doorId: '1',
          doorLocation: 'Eindhoven',
          doorName: '#Deur Garage 1',
          moduleName: 'Xport X1',
          doorDisabled: 0,
          doorLocationId: 1
        }
      ],
      usersInGroup: {
        totalUsers: 2,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      },
      visitorsInGroup: {
        totalVisitors: 1,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '46',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      }
    },
    {
      groupId: '3',
      groupName: 'Algemeen3',
      groupTmpAccessEnabled: '1',
      timeDetails: [
        {
          day: 'Monday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Tuesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Wednesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Thursday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Friday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Saturday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Sunday',
          startTime: '00:00',
          endTime: '00:00'
        }
      ],
      objectsEnabled: [
        {
          doorId: '1',
          doorLocation: 'Eindhoven',
          doorName: '#Deur Garage 1',
          moduleName: 'Xport X1',
          doorDisabled: 0,
          doorLocationId: 1
        }
      ],
      usersInGroup: {
        totalUsers: 2,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      },
      visitorsInGroup: {
        totalVisitors: 1,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '46',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      }
    },
    {
      groupId: '4',
      groupName: 'Algemeen4',
      groupTmpAccessEnabled: '1',
      timeDetails: [
        {
          day: 'Monday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Tuesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Wednesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Thursday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Friday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Saturday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Sunday',
          startTime: '00:00',
          endTime: '00:00'
        }
      ],
      objectsEnabled: [
        {
          doorId: '1',
          doorLocation: 'Eindhoven',
          doorName: '#Deur Garage 1',
          moduleName: 'Xport X1',
          doorDisabled: 0,
          doorLocationId: 1
        }
      ],
      usersInGroup: {
        totalUsers: 2,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      },
      visitorsInGroup: {
        totalVisitors: 1,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '46',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      }
    },
    {
      groupId: '5',
      groupName: 'Algemeen5',
      groupTmpAccessEnabled: '1',
      timeDetails: [
        {
          day: 'Monday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Tuesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Wednesday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Thursday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Friday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Saturday',
          startTime: '00:00',
          endTime: '23:59'
        },
        {
          day: 'Sunday',
          startTime: '00:00',
          endTime: '00:00'
        }
      ],
      objectsEnabled: [
        {
          doorId: '1',
          doorLocation: 'Eindhoven',
          doorName: '#Deur Garage 1',
          moduleName: 'Xport X1',
          doorDisabled: 0,
          doorLocationId: 1
        }
      ],
      usersInGroup: {
        totalUsers: 2,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      },
      visitorsInGroup: {
        totalVisitors: 1,
        users: [
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '45',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '433',
            userName: 'Albers, Teddy (student)',
            locationName: 'Eindhoven'
          },
          {
            userId: '46',
            userName: 'Aart, Jop van (student)',
            locationName: 'Eindhoven'
          }
        ]
      }
    }
  ],
  code: null,
  message: null
};
