import React from 'react';
import { Moment } from 'moment';
import { Box, Fade, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { dimensions } from '../../../../styles/dimensions';
import { TimeFormatType } from '../../../../utils/date';

import { Content, ContentHeader, ContentWrapper, StandardTime, TableWrapper, TimeList, Title } from '../../components';
import { PrimaryButton, SecondaryButton } from '../../../../components/Button';

import { BelongUsers, BelongVisitors, DoorAuthorization } from './Table';
import { DayOfWeek, getDefaultNewGroup, TimePick } from './types';

import GroupNameInput from './GroupNameInput';
import TemporaryAccessCheckbox from './TemporaryAccessCheckbox';

import { Popup } from '../../../../components/Popup';
import { SideBarItemType } from '../../../../types/route';

import { useUsersActions } from '../../store';
import { useCallbackPrompt } from '../../../../utils/useCallbackPrompt';
import { showSuccessToast, showErrorToast } from '../../../../components/Toast/actions';
import { PopulateGroupParams } from '../../../../models';
import { StandardTimeItem } from '../components';
import { validateGroupForm } from '../utils';
import { ActionType } from '../types';
import { BooleanValue } from '../../../../types';

const AddNew: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getExistedUsers, getExistedVisitors, getExistedDoors, addNewGroup, addUserToGroup } = useUsersActions();
  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();
  const addNewGroupParam = React.useRef<PopulateGroupParams>(getDefaultNewGroup());

  const handleStandardTimeItemChanged = (moment: Moment, day: string, type: TimePick) => {
    const time = timeDetails.find(item => item.day === day);

    if (time) {
      if (type === TimePick.START_TIME) {
        time.startTime = moment.format(TimeFormatType.HOUR_MINUTE);
      } else {
        time.endTime = moment.format(TimeFormatType.HOUR_MINUTE);
      }

      const newStandardTimes = timeDetails.map(item => (item.day === day ? time : item));

      addNewGroupParam.current.timeDetails = newStandardTimes;
    }
  };

  const handleGroupNameChanged = (value: string) => {
    addNewGroupParam.current.groupName = value;
  };

  const handleTemporaryAccessChanged = (value: string) => {
    addNewGroupParam.current.temporaryAccess = value;
  };

  const handleBelongUserIdsChanged = (userIds: string[]) => {
    addNewGroupParam.current.belongUserIds = userIds;
  };

  const handleBelongVisitorIdsChanged = (visitorIds: string[]) => {
    addNewGroupParam.current.belongUserIds = visitorIds;
  };

  const handleUsedDoorIdsChanged = (doorIds: string[]) => {
    addNewGroupParam.current.usedDoorIds = doorIds;
  };

  const handleAddNewGroup = async () => {
    const errorMessage = validateGroupForm(addNewGroupParam.current, ActionType.ADD_NEW);

    if (errorMessage) {
      const { title, subtitle, data } = errorMessage;

      showErrorToast({
        title: t(title),
        subtitle: t(subtitle, data)
      });

      return;
    }

    try {
      const params = addNewGroupParam.current;
      const dayOfWeek = Object.values(DayOfWeek);

      const timeConvert: { [key: string]: any } = {};
      params.timeDetails.forEach((standardTime, idx) => {
        const { startTime, endTime } = standardTime;
        timeConvert[dayOfWeek[idx]] = `${startTime},${endTime}`;
      });

      const newGroupData = {
        name: params.groupName,
        proId: '1',
        active: '0',
        objectEnable: {
          objects: params.usedDoorIds?.join(',') ?? ''
        },
        time: timeConvert,
        LocationId: '1',
        tmpAccess: params.temporaryAccess ?? '0'
      };

      const newGroupId = await addNewGroup(newGroupData);

      await addUserToGroup(`${newGroupId}`, params.belongUserIds);

      showSuccessToast({
        title: t('group.add_new.message.success.title'),
        subtitle: t('group.add_new.message.success.subtitle')
      });

      navigate(`/users/${SideBarItemType.Group}/${newGroupId}`);

      confirmNavigation();
    } catch (error) {
      showErrorToast({
        title: t('group.add_new.message.fail.title'),
        subtitle: t('group.add_new.message.fail.subtitle')
      });
    }
  };

  const { timeDetails } = addNewGroupParam.current;

  React.useEffect(() => {
    getExistedDoors();
    getExistedUsers();
    getExistedVisitors();
  }, []);

  return (
    <ContentWrapper>
      <Fade in={true}>
        <Content>
          <ContentHeader>
            <Typography variant="subtitle4">{t('group.add_new.content')}</Typography>

            <ButtonWrapper>
              <SecondaryButton
                content={t('group.add_new.button.cancel')}
                height={buttonHeight}
                onClick={() => navigate(-1)}
              />
              <PrimaryButton
                content={t('group.add_new.button.save_new_group')}
                height={buttonHeight}
                onClick={handleAddNewGroup}
              />
            </ButtonWrapper>
          </ContentHeader>
          <Box paddingTop={baseMD} width="35%" minWidth="400px">
            <GroupNameInput onGroupNameChanged={handleGroupNameChanged} />
          </Box>

          <StandardTime>
            <Title variant="subtitle3">{t('group.select_standard_time')}</Title>

            <TimeList>
              {timeDetails.map(item => (
                <StandardTimeItem key={item.day} item={item} onTimeChanged={handleStandardTimeItemChanged} />
              ))}
            </TimeList>

            <TemporaryAccessCheckbox
              onAccessChanged={value => handleTemporaryAccessChanged(value ? BooleanValue.TRUE : BooleanValue.FALSE)}
            />
          </StandardTime>
          <TableWrapper container spacing={3}>
            <DoorAuthorization onUsedDoorIdsChanged={handleUsedDoorIdsChanged} />

            <BelongUsers onBelongUserIdsChanged={handleBelongUserIdsChanged} />

            <BelongVisitors onBelongVisitorIdsChanged={handleBelongVisitorIdsChanged} />
          </TableWrapper>
        </Content>
      </Fade>

      <Popup
        title={t('group.add_new.popup.title')}
        content={t('group.add_new.popup.content')}
        open={showPrompt}
        onClose={cancelNavigation}
        closeactiontext={t('group.add_new.button.cancel')}
        action={{
          name: t('group.add_new.button.quit'),
          onAction: confirmNavigation
        }}
      />
    </ContentWrapper>
  );
};

export default AddNew;

const { baseMD, buttonHeight } = dimensions;

const ButtonWrapper = styled(Box)`
  display: flex;
  column-gap: ${baseMD};
`;
