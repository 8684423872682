import React from 'react';

import { Box, Grow, styled } from '@mui/material';

import FieldSelect from './FieldSelect';
import PlaceSelect from './PlaceSelect';
import TimeSelect from './TimeSelect';
import UserSelect from './UserSelect';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';

const FilterOptions: React.FunctionComponent = () => {
  const handleCreateReport = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    //TODO: use in https://sioux.atlassian.net/browse/ECA-357
    const _data = Object.fromEntries(new FormData(e.target));
  };

  return (
    <Grow in={true}>
      <Wrapper>
        <Box
          component="form"
          width="98%"
          height="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          rowGap={baseMD}
          columnGap={base}
          overflow="auto"
          onSubmit={handleCreateReport}>
          <UserSelect />

          <PlaceSelect />

          <TimeSelect />

          <FieldSelect />
        </Box>
      </Wrapper>
    </Grow>
  );
};

export default FilterOptions;

const { baseMD, base } = dimensions;

const Wrapper = styled(Box)`
  width: 100%;
  height: 170px;
  padding-top: ${base};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 7px ${theme.palette.grey[300]};
  overflow: auto;
`;
