import { Box, CircularProgress, Divider, Fade, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../../../components/Button';
import { Popup } from '../../../../components/Popup';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast/actions';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { SideBarItemType } from '../../../../types/route';
import { useCallbackPrompt } from '../../../../utils/useCallbackPrompt';
import { ContentWrapper } from '../../components/ContentWrapper';
import { LoadingWrapper } from '../../components/LoadingWrapper';
import { useUsersActions } from '../../store';
import { BLANK_STRING } from '../../types';
import { AccessInformationForm } from '../components/AccessInformationForm';
import { VisitorInformationForm } from '../components/VisitorInformationForm';
import {
  CheckBoxFormValue,
  convertCheckboxValue,
  FormActionType,
  isPopulateVisitorForm,
  validatePopulateVisitorFormData
} from '../type';
import { useAppContext } from '../../../../appContext';
import { TimeFormatType } from '../../../../utils/date';
import moment from 'moment';

export const AddNewVisitor: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();

  const { getUserInfo, addNewVisitor, sendEmailToVisitor, getVisitorList } = useUsersActions();
  const { locations } = useAppContext();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>(BLANK_STRING);

  const handleSaveNewVisitor = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target));

    if (!isPopulateVisitorForm(data)) return;
    setLoading(true);
    try {
      validatePopulateVisitorFormData(data);

      const contractPersonId = data.contractPersonName.split('|')[1] || '';
      const locationId = locations.find(item => item.location === data.location)?.id;

      if (!locationId) throw new Error('Location Not Found!');

      const userInfo = await getUserInfo(contractPersonId.trim());

      if (!userInfo) throw new Error('visitor.toast.failed.invalid_contract_person');

      if (email && data.isSendEmail === CheckBoxFormValue.ON) sendEmailToVisitor(email);

      const newVisitorId = await addNewVisitor({
        email,
        contactId: Number(userInfo.id),
        startDate: `${moment(data.startDate).format(TimeFormatType.REVERSED_SHORTDATE)} ${data.startTime}:00`,
        endDate: `${moment(data.endDate).format(TimeFormatType.REVERSED_SHORTDATE)} ${data.endTime}:00`,
        locationId: `${locationId}`,
        name: data.name,
        phone: data.phone,
        pin: data.pin,
        tagActive: convertCheckboxValue(data.tagActive),
        tagNumber: data.tagNumber,
        tagText: data.tagText
      });

      await getVisitorList();

      showSuccessToast({
        title: t('visitor.toast.success.add_new'),
        subtitle: t('visitor.toast.success.add_new_subtitle')
      });
      navigate(`/users/${SideBarItemType.Visitor}/${newVisitorId}`);
      confirmNavigation();
    } catch (error) {
      const { message } = error as any;
      showErrorToast({
        title: t('visitor.toast.failed.add_new'),
        subtitle: t(message)
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fade in={true}>
      <ContentWrapper>
        {loading && (
          <LoadingWrapper>
            <CircularProgress color="primary" size={40} />
          </LoadingWrapper>
        )}

        <Popup
          title={t('visitor.add_visitor.popup.title')}
          content={t('visitor.add_visitor.popup.content')}
          open={showPrompt}
          onClose={cancelNavigation}
          closeactiontext={t('logout.button.cancel')}
          action={{
            name: `${t('visitor.add_visitor.popup.confirm_action_text')}`,
            onAction: confirmNavigation
          }}
        />

        <Box component="form" onSubmit={handleSaveNewVisitor}>
          <HeaderWrapper>
            <Typography variant="subtitle4" color="grey.100">
              {t('visitor.add_visitor.page_title')}
            </Typography>
            <ButtonWrapper columnGap={base}>
              <SecondaryButton content={t('cancel')} height={buttonHeight} onClick={() => navigate(-1)} />
              <PrimaryButton
                content={t('visitor.add_visitor.button.save_new_visitor')}
                height={buttonHeight}
                type="submit"
              />
            </ButtonWrapper>
          </HeaderWrapper>

          <HorizontalLine />

          <BodyWrapper>
            <VisitorInformationForm
              onEmailChange={em => setEmail(em)}
              formActionType={FormActionType.ADD_NEW}
              setLoading={setLoading}
            />

            <AccessInformationForm email={email} formActionType={FormActionType.ADD_NEW} />
          </BodyWrapper>
        </Box>
      </ContentWrapper>
    </Fade>
  );
};

const { base, baseMD, baseLG, base2XL, buttonHeight } = dimensions;
const { grey } = theme.palette;

const HorizontalLine = styled(Divider)`
  margin: 0 ${baseLG};
  background-color: ${grey[400]};
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${base} ${baseLG};
  align-items: center;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BodyWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  column-gap: ${base2XL};
  padding: ${baseMD} ${baseLG};
  ${props => props.theme.breakpoints.down('xl')} {
    flex-direction: column;
    row-gap: ${baseMD};
    overflow: auto;
  }
`;
