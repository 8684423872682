import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Header from '../../components/Header';
import { Route, Navigate, Routes } from 'react-router-dom';
import SideBar from '../../components/SideBar';
import { SideBarItemType } from '../../types/route';
import ProtectedRoute from '../../components/ProtectedRoute';
import Users from '../Users';
import Location from '../Location';
import Report from '../Report';
import DoorManagement from '../Door';
import EmergencyTeam from '../EmergencyTeam';
import Dashboard from '../Dashboard';
import NotFound from '../../components/NotFound';
import { getCookie } from '../../utils/cookie';
import { $get } from '../../utils/http';
import { StorageKeys } from '../../types/storage';
import React from 'react';

// 4 minutes
const REFRESH_TOKEN_POLLING_TIME = 240000;

export const Master = () => {
  async function getToken() {
    const refreshToken = getCookie(StorageKeys.REFRESH_TOKEN);
    if (!refreshToken) {
      return;
    }

    const response = await $get('RefreshToken/', { headers: { RefreshToken: refreshToken } });

    const { data } = response;

    if (data) {
      const { Authorization } = data;

      Authorization && localStorage.setItem(StorageKeys.TOKEN, Authorization);
    }
  }

  React.useEffect(() => {
    const pollingGetToken = setInterval(() => {
      getToken();
    }, REFRESH_TOKEN_POLLING_TIME);

    return () => clearInterval(pollingGetToken);
  }, []);

  return (
    <Box display="flex" height="100%" width="100%" minHeight="540px" minWidth="960px">
      <SideBar />
      <ContentWrapper>
        <Header />
        <RouteWrapper>
          <Routes>
            <Route
              path=""
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path={`/${SideBarItemType.Dashboard}/*`} element={<Navigate to="/" replace />} />
            <Route
              path={`/users/*`}
              element={
                <ProtectedRoute>
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/${SideBarItemType.Location}/*`}
              element={
                <ProtectedRoute>
                  <Location />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/${SideBarItemType.Report}/*`}
              element={
                <ProtectedRoute>
                  <Report />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/${SideBarItemType.Door}/*`}
              element={
                <ProtectedRoute>
                  <DoorManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path={`/${SideBarItemType.EmergencyTeam}/*`}
              element={
                <ProtectedRoute>
                  <EmergencyTeam />
                </ProtectedRoute>
              }
            />
            <Route path={`/*`} element={<NotFound />} />
          </Routes>
        </RouteWrapper>
      </ContentWrapper>
    </Box>
  );
};

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up('md')} {
    width: calc(100% - 200px);
  }
  ${props => props.theme.breakpoints.down('lg')} {
    width: calc(100% - 150px);
  }
`;

const RouteWrapper = styled(Box)`
  height: calc(100% - 48px);
`;
