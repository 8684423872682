import React from 'react';

import { Box, SelectProps, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { SelectBox, SelectBoxType } from './SelectBox';

type Props = {
  label?: string;
  width?: string;
  selectBoxWidth?: string;
  listItem: SelectBoxType[];
} & SelectProps;

export const SelectBoxWithLabel: React.FunctionComponent<Props> = (props: Props) => {
  const { label, width, selectBoxWidth, listItem, ...others } = props;

  const defaultValue = isEmpty(listItem) ? '' : listItem[0].value.toString();

  return (
    <Box width={width} display="flex" justifyContent="space-between" alignItems="center">
      {label && (
        <Typography color="grey.200" noWrap overflow="hidden">
          {label}
        </Typography>
      )}
      <SelectBox listItem={listItem} width={selectBoxWidth} {...others} defaultValue={defaultValue} />
    </Box>
  );
};
