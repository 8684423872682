import { Box, styled } from '@mui/material';
import { theme } from '../../../styles/theme';

export const LoadingWrapper = styled(Box)`
  background-color: ${theme.palette.grey[500]};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 70%;
  z-index: 999;
  position: absolute;
  width: calc(100% - 340px);
  ${props => props.theme.breakpoints.down('lg')} {
    width: calc(100% - 240px);
  }
`;
