import { Box, styled } from '@mui/material';
import { dimensions } from '../../../styles/dimensions';

const { baseXS } = dimensions;

export const FilterOptionsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: ${baseXS};
`;
