import { styled, Box, CircularProgress } from '@mui/material';
import React from 'react';

export const CircularLoading: React.FunctionComponent = () => (
  <LoadingWrapper>
    <CircularProgress color="primary" size={40} />
  </LoadingWrapper>
);

const LoadingWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
