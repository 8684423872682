import { theme } from '../../../styles/theme';
import { Typography, styled, TypographyProps } from '@mui/material';

const { primary, common } = theme.palette;

export const ListItem = styled(Typography)<TypographyProps>`
  display: flex;
  align-items: center;
  padding: 0 0 0 3%;
  width: 96%;
  line-height: 40px;
  border-radius: 2px;
  cursor: pointer;
  &.active {
    background-color: ${primary.main};
    color: ${common.white};
    font-weight: 700;
  }
`;
