import { createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import React from 'react';
import { colors } from './colors';
import { dimensions } from './dimensions';
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    subtitle4: true;
    body3: true;
    hyperlink: true;
  }
}

declare module '@mui/material/styles' {
  interface SimplePaletteColorOptions {
    lighter?: string;
  }
  interface PaletteColor {
    lighter?: string;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  subtitle3: React.CSSProperties;
  subtitle4: React.CSSProperties;
  body3: React.CSSProperties;
  hyperlink: React.CSSProperties;
}

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Lato'
    },
    h1: {
      fontSize: '40px',
      fontWeight: '700'
    },
    h2: {
      fontSize: '32px',
      fontWeight: '700'
    },
    h3: {
      fontSize: '24px',
      fontWeight: '700'
    },
    h4: {
      fontSize: '20px',
      fontWeight: '700'
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: '700'
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: '700'
    },
    subtitle3: {
      fontSize: '14px',
      fontWeight: '800'
    },
    subtitle4: {
      fontSize: '14px',
      fontWeight: '600'
    },
    body1: {
      fontSize: '16px',
      fontWeight: '500'
    },
    body2: {
      fontSize: '14px',
      fontWeight: '500'
    },
    body3: {
      fontSize: '12px',
      fontWeight: '500'
    },
    hyperlink: {
      fontSize: '14px',
      fontWeight: '600'
    }
  } as ExtendedTypographyOptions,
  palette: {
    common: {
      white: colors.white
    },
    primary: {
      dark: colors.darkGreen,
      main: colors.mainGreen,
      light: colors.lightGreen,
      lighter: colors.lighterGreen
    },
    grey: {
      '100': colors.grey1,
      '200': colors.grey2,
      '300': colors.grey3,
      '400': colors.grey4,
      '500': colors.grey5
    },
    info: {
      main: colors.mainInfoBlue,
      light: colors.lightInfoBlue
    },
    warning: {
      main: colors.mainWarningOrange,
      light: colors.lightWarningOrange,
      lighter: colors.lighterWarningOrange
    },
    error: {
      main: colors.mainErrorRed,
      light: colors.lightErrorRed
    }
  }
});

theme.components = {
  ...theme.components,
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        marginTop: dimensions.baseXS,
        boxShadow: theme.shadows[3],
        borderRadius: dimensions.baseXS
      },
      option: {
        ':hover': { background: theme.palette.grey[500] }
      }
    }
  }
};

theme.shadows[1] = `0px 2px 10px ${colors.shadow1Grey}`;
theme.shadows[2] = `0px 2px 6px ${colors.shadow2Grey}`;
theme.shadows[3] = `0px 4px 20px ${colors.shadow3Grey}`;
theme.shadows[4] = `0px 1px 1px ${colors.shadow4Grey}`;
theme.shadows[5] = `0px 0px 8px ${colors.shadow5Green}`;
