import React from 'react';
import _ from 'lodash';

import { ReactComponent as Eye } from '../../../assets/image/Eye.svg';
import { ReactComponent as EyeOff } from '../../../assets/image/EyeOff.svg';

import { Box, FormControl, IconButton, InputAdornment, OutlinedInput, styled, Typography } from '@mui/material';

import { theme } from '../../../styles/theme';
import { dimensions } from '../../../styles/dimensions';
import { InputFieldType } from '../type';

type Props = {
  value: string;
  label: string;
  icon: JSX.Element;
  type: InputFieldType;
  placeholder: string;
  onChange: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

export const InputField: React.FunctionComponent<Props> = (props: Props) => {
  const { value, icon, label, placeholder, type, onChange, onKeyDown } = props;

  const [visibility, setVisibility] = React.useState<boolean>(_.isEqual(type, InputFieldType.PASSWORD));
  const [inputType, setInputType] = React.useState<InputFieldType>(InputFieldType.TEXT);

  React.useEffect(() => {
    setInputType(type);
  }, []);

  React.useEffect(() => {
    if (_.isEqual(inputType, InputFieldType.TEXT) && visibility) {
      setInputType(InputFieldType.PASSWORD);
    } else {
      setInputType(InputFieldType.TEXT);
    }
  }, [visibility]);

  return (
    <Box width="100%">
      <Label>
        {icon}

        <Typography variant="body1" color={theme.palette.grey[100]}>
          {label}
        </Typography>
      </Label>

      <FormControl variant="outlined" fullWidth>
        <Input
          type={inputType}
          value={value}
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          onKeyDown={onKeyDown}
          endAdornment={
            _.isEqual(type, InputFieldType.PASSWORD) && (
              <IconAdditional position="end">
                <IconButton onClick={() => setVisibility(!visibility)} edge="end">
                  {visibility ? <Eye /> : <EyeOff />}
                </IconButton>
              </IconAdditional>
            )
          }
        />
      </FormControl>
    </Box>
  );
};

const { inputHeight, baseXL } = dimensions;

const Label = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 3%;
  padding-bottom: 2%;
`;

const Input = styled(OutlinedInput)`
  height: ${inputHeight};
  width: 300px;

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

const IconAdditional = styled(InputAdornment)`
  width: ${baseXL};
`;
