import React from 'react';

import {
  Box,
  Grid,
  Grow,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

import { theme } from '../../../../styles/theme';
import { isEmpty } from 'lodash';
import { dimensions } from '../../../../styles/dimensions';
import { TableAction } from '../Edit/types';
import SearchBar from '../../../../components/SearchBar';

type Props = {
  header: string;
  titles: string[];
  placeholder?: string;
  columnWidths?: string[];
  action: (item: any) => void;
  actionType: TableAction;
  rows: { [key: string]: any }[];
  onSearch?: (value: string) => void;
  onClear?: () => void;
  onStopTyping?: (value: string) => void;
};

enum FilterObjectKey {
  USERNAME = 'userName',
  DOOR_NAME = 'doorName',
  VISITOR_NAME = 'visitorName'
}

export const GridTable: React.FunctionComponent<Props> = (props: Props) => {
  const { header, titles, rows, actionType, action, placeholder, onSearch, onClear, onStopTyping, columnWidths } =
    props;

  const [keyword, setKeyword] = React.useState<string>('');

  const actionButton = actionType === TableAction.ADD ? <AddCircleOutline color="primary" /> : <RemoveCircleOutline />;

  const isNotLazyTable = !(onSearch && onClear && onStopTyping);

  const renderRows = (key: string) => {
    if (!isNotLazyTable) return rows;

    const lowercaseKey = key.toLowerCase();

    const filteredRows: any[] = [];

    rows.forEach(row => {
      if (FilterObjectKey.VISITOR_NAME in row && row.visitorName.toLowerCase().includes(lowercaseKey)) {
        filteredRows.push(row);
      }

      if (FilterObjectKey.USERNAME in row && row.userName.toLowerCase().includes(lowercaseKey)) {
        filteredRows.push(row);
      }

      if (FilterObjectKey.DOOR_NAME in row && row.doorName.toLowerCase().includes(lowercaseKey)) {
        filteredRows.push(row);
      }
    });

    return filteredRows;
  };

  return (
    <Wrapper item xs={12} lg={6}>
      <Header>
        <Typography variant="subtitle3" flex="1" marginTop={base}>
          {header}
        </Typography>

        <Box flex="1.5">
          <SearchBar
            onSearch={onSearch}
            onClear={onClear}
            placeHolder={placeholder}
            keyword={keyword}
            onKeywordChanged={value => setKeyword(value)}
          />
        </Box>
      </Header>

      <Container>
        <StyledTable stickyHeader>
          <TableHead>
            <Row>
              {titles.map((item, index) => (
                <TableTitle key={index} width={columnWidths && columnWidths[index]}>
                  {item}
                </TableTitle>
              ))}
            </Row>
          </TableHead>

          <Body>
            {!isEmpty(rows) &&
              renderRows(keyword).map((item, index) => (
                <Grow key={index} in={true}>
                  <Row>
                    {Object.values(item).map((val: any, idx) => (
                      <RowContent key={idx} width={columnWidths && columnWidths[idx]}>
                        {val}
                      </RowContent>
                    ))}
                    <RowContent align="justify">
                      <IconButton onClick={() => action(item)}>{actionButton}</IconButton>
                    </RowContent>
                  </Row>
                </Grow>
              ))}
          </Body>
        </StyledTable>
      </Container>
    </Wrapper>
  );
};

const { grey } = theme.palette;
const { base, baseXS, baseMD, baseLG, inputHeight } = dimensions;

const Wrapper = styled(Grid)`
  margin-top: ${baseLG};
  height: 466.5px;
  display: flex;
  flex-direction: column;
  row-gap: ${baseXS};
`;

const Header = styled(Box)`
  padding-bottom: ${baseLG};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Container = styled(TableContainer)`
  flex: 1;
  width: 100%;
  height: 100%;
  border: 1px solid ${grey[400]};
`;

const Body = styled(TableBody)`
  overflow: auto;
`;

const StyledTable = styled(Table)`
  border: 1px solid ${grey[400]};
`;

const TableTitle = styled(TableCell)`
  padding: ${baseXS} ${baseMD};
  height: ${inputHeight};
  color: ${grey[100]};
  font-weight: 700;
  font-size: 16px;
`;

const RowContent = styled(TableCell)`
  padding: ${baseXS} ${baseMD};
  font-weight: 500;
  font-size: 14px;
`;

const Row = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${grey[500]};
  }
`;
