// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Lato);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody {\n  height: 100%;\n  min-width: 960px;\n  min-height: 540px;\n  margin: 0;\n}\n\nbody {\n  font-family: 'Lato', cursive;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n#root {\n  height: 100%;\n  background-color: #f6f9fa;\n}\n\n::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n}\n\n::-webkit-scrollbar-track {\n  background: #FFFFFF;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #ACB8BD;\n  border-radius: 12px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: #D8DFE0;\n}", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,4BAA4B;EAC5B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Lato');\n\nhtml,\nbody {\n  height: 100%;\n  min-width: 960px;\n  min-height: 540px;\n  margin: 0;\n}\n\nbody {\n  font-family: 'Lato', cursive;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n#root {\n  height: 100%;\n  background-color: #f6f9fa;\n}\n\n::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n}\n\n::-webkit-scrollbar-track {\n  background: #FFFFFF;\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: #ACB8BD;\n  border-radius: 12px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: #D8DFE0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
