import React from 'react';

import { Box, styled, TextField } from '@mui/material';
import { EMPTY_STRING, FIRST_INDEX, INSERT_INPUT_TYPE, RE_DIGIT } from '../type';
import { dimensions } from '../../../styles/dimensions';

interface Props {
  onFocus?: boolean;
  value: string[];
  onChange: (value: string[]) => void;
}

enum SiblingPosition {
  NEXT = 'nextElementSibling',
  PREV = 'previousSibling'
}

export const PinInputGrid: React.FunctionComponent<Props> = (props: Props) => {
  const { value, onChange, onFocus } = props;

  const focusToInput = (target: HTMLElement, position: SiblingPosition) => {
    let elementSibling = null;
    const parenElement = target.parentElement?.parentElement;
    if (parenElement) {
      elementSibling = parenElement[position]?.firstChild?.firstChild as HTMLInputElement | null;
    }

    if (elementSibling && onFocus !== false) {
      elementSibling.focus();
    }
  };

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, idx: number) => {
    const target = e.target;

    if (!RE_DIGIT.test(target.value) && !(target.value === EMPTY_STRING)) {
      return;
    }

    const newValue = [...value];
    const inputEvent = e.nativeEvent as InputEvent;
    const isInsertInput = inputEvent.inputType === INSERT_INPUT_TYPE;

    newValue.splice(idx, 1, target.value.slice(-1));

    onChange(newValue);

    if (isInsertInput) {
      focusToInput(target, SiblingPosition.NEXT);
    } else {
      focusToInput(target, SiblingPosition.PREV);
    }
  };

  return (
    <Wrapper>
      {value.map((digit, index) => (
        <PinInput
          autoFocus={index === FIRST_INDEX}
          onBlur={e => e.preventDefault()}
          inputMode="numeric"
          autoComplete="one-time-code"
          value={digit}
          key={index}
          onChange={event => inputOnChange(event, index)}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PinInput = styled(TextField)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 56px;
  margin: ${dimensions.baseSM};
  .MuiInputBase-input {
    text-align: center;
    font-size: 16px;
  }
`;
