import React from 'react';
import { Box, styled } from '@mui/material';
import { Route, Routes, useMatch } from 'react-router-dom';

import { theme } from '../../../styles/theme';

import GroupList from './GroupList';
import GroupInfo from './GroupInfo';
import { SideBarItemType } from '../../../types/route';
import { EXPORT_GROUP_FILE_NAME, GroupRouteEndpoints } from './types';
import { BLANK_STRING } from '../types';
import AddNew from './AddNew';
import { useUsersHook } from '../store';
import { isEmpty } from 'lodash';
import NoticeMessage from '../components/NoticeMessage';
import { useTranslation } from 'react-i18next';
import Edit from './Edit';
import { startCaseKeysObj } from '../../../utils/object';
import { downloadCSVFile, exportCSV } from '../../../utils/fileHelper';
import { showErrorToast, showSuccessToast } from '../../../components/Toast/actions';
import { exportGroupConverter } from '../converter';
import { CircularLoading } from './components';
import { ContentWrapper } from '../components';

const Group: React.FunctionComponent = () => {
  const match = useMatch(`/users/${SideBarItemType.Group}/:${GroupRouteEndpoints.ID}`);
  const groupId = match?.params[GroupRouteEndpoints.ID];

  const { t } = useTranslation();
  const [{ groupList }, { getAllGroupInformation }] = useUsersHook();

  const [loading, setLoading] = React.useState<boolean>(false);

  async function handleExportGroups(): Promise<void> {
    setLoading(true);

    const startCaseDataList: { [key: string]: string }[] = [];
    try {
      const exportUserInfoList = await getAllGroupInformation();

      exportUserInfoList.forEach(info => {
        const convertInfo = exportGroupConverter(info);
        startCaseDataList.push(startCaseKeysObj(convertInfo));
      });

      const csvData = exportCSV(startCaseDataList);
      downloadCSVFile(csvData, EXPORT_GROUP_FILE_NAME);

      showSuccessToast({
        title: t('user.export_user_toast.success.title'),
        subtitle: t('user.export_user_toast.success.subtitle')
      });
    } catch (error) {
      showErrorToast({
        title: t('user.export_user_toast.failed.title'),
        subtitle: t('user.export_user_toast.failed.subtitle')
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Wrapper>
      <GroupList groupId={groupId ? groupId : BLANK_STRING} onExportGroups={handleExportGroups} />

      <Routes>
        <Route
          path={`:${GroupRouteEndpoints.ID}`}
          element={
            loading ? (
              <ContentWrapper>
                <CircularLoading />
              </ContentWrapper>
            ) : (
              <GroupInfo />
            )
          }
        />
        <Route path={`${GroupRouteEndpoints.ADD_NEW}`} element={<AddNew />} />
        <Route path={`:${GroupRouteEndpoints.ID}/${GroupRouteEndpoints.EDIT}`} element={<Edit />} />

        <Route
          path="/"
          element={
            loading ? (
              <ContentWrapper>
                <CircularLoading />
              </ContentWrapper>
            ) : (
              <NoticeMessage showBlankPage={isEmpty(groupList)} message={t('group.view_details')} />
            )
          }
        />
      </Routes>
    </Wrapper>
  );
};

export default Group;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${theme.palette.grey[500]};
`;
