export enum LatestLogActionType {
  APPEND = 'Append',
  CACHE = 'Cache'
}

export enum SettingColumnAction {
  EDIT = 'edit',
  APPLY = 'apply'
}

export enum OrderBy {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum RowPosition {
  ODD = 'odd',
  EVEN = 'even'
}

export enum LogHistoryColumn {
  TYPE = 'Type',
  NAME = 'Name',
  TIME = 'Time',
  MESSAGE = 'Message',
  DOOR = 'Door',
  DOOR_LOCATION = 'DoorLocation',
  DEPARTMENT = 'Department',
  ROOM = 'Room',
  CAR_PLATE = 'CarPlate'
}

export type Column = {
  title: LogHistoryColumn;
  display: boolean;
};

export const EMPTY_STRING = '';
export const FIRST_INDEX = 0;

export const TAKEN_NUMBER = 30;
export const LATEST_HIGHLIGHTED_LOG_MINUTES = 3;

export const POLLING_TIME = 5000;

export enum FilterLevel {
  ALL = 'All',
  ALARMS_WARNINGS = 'Alarms & Warnings',
  ALARMS = 'Alarms',
  WARNINGS = 'Warnings'
}
