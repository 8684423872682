import { Box, styled, Typography } from '@mui/material';

import { theme } from '../styles/theme';

import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { $get } from '../utils/http';
import { useAppContext } from '../appContext';
import _ from 'lodash';
import { dimensions } from '../styles/dimensions';

type CountUsersResponse = {
  data: {
    countUser: string;
    doorLocations: string[] | null;
  };
  code: string;
  message: string;
};

const Header = (): JSX.Element => {
  const POLLING_TIME = 5000;
  const { t } = useTranslation();
  const [totalUsersIn, setTotalUsersIn] = useState<number>(0);

  const { adminProfile, loadAdminProfile } = useAppContext();

  const getTotalUsersIn = async () => {
    const response: CountUsersResponse = await $get('user/countUserIn');

    const { data } = response;

    if (!data || !data.countUser) return;

    const { countUser } = data;

    setTotalUsersIn(!isNaN(Number(countUser)) ? Number(countUser) : totalUsersIn);
  };

  React.useEffect(() => {
    loadAdminProfile();
    const pollingCountUsersIn = setInterval(() => {
      getTotalUsersIn();
    }, POLLING_TIME);
    return () => clearInterval(pollingCountUsersIn);
  }, []);

  return (
    <Wrapper>
      <UserInfo>
        <Typography variant="subtitle3">{totalUsersIn}</Typography>
        <Typography marginLeft="6px" variant="body2">
          {t('dashboard.persons')}
        </Typography>
        <Box width="2px" margin="24px 24px" height="36px" bgcolor="grey.300"></Box>
        <Typography variant="subtitle3">{adminProfile && adminProfile.fullName}</Typography>
        <Typography marginLeft="6px" variant="body2">
          {t('dashboard.client')}: {adminProfile && adminProfile.client}
        </Typography>
      </UserInfo>
    </Wrapper>
  );
};

export default Header;

const { rowHeight, baseLG } = dimensions;

const Wrapper = styled(Box)`
  height: ${rowHeight};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, ${theme.palette.grey[500]} 10%, ${theme.palette.grey[400]} 80%);
`;

const UserInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: ${baseLG};
`;
