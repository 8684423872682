import { Typography } from '@mui/material';

const NotFound: React.FunctionComponent = () => {
  return (
    <>
      <Typography variant="h2" textTransform="uppercase" color="gray">
        This is not found page !
      </Typography>
    </>
  );
};

export default NotFound;
