import { Box, styled, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from '../../../../../assets/image/Copy.svg';
import QRCodeLogo from '../../../../../assets/image/QRCodeLogo.png';
import { ReactComponent as SendEmailIcon } from '../../../../../assets/image/SendEmail.svg';
import { SecondaryButton } from '../../../../../components/Button';
import { dimensions } from '../../../../../styles/dimensions';
import { useUsersActions } from '../../../store';

type Props = {
  tagNumber: string;
  email: string;
};

export const QRCodeView: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { tagNumber, email } = props;

  const { sendEmailToVisitor } = useUsersActions();
  const [emailSended, setEmailSended] = React.useState<boolean>(false);

  const copyQRCode = (): void => {
    if (!tagNumber) return;
    const canvas = document.getElementById('qrCodeImage') as HTMLCanvasElement;

    if (!canvas) return;
    canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({ 'image/png': blob! })]));
  };

  const sendEmail = (): void => {
    sendEmailToVisitor(email);
    setEmailSended(true);
  };

  return (
    <QRCodeValueWrapper columnGap={baseMD}>
      <QRCodeImageWrapper>
        {tagNumber ? (
          <QRCode
            id="qrCodeImage"
            value={tagNumber}
            renderAs="canvas"
            size={80}
            imageSettings={{ src: QRCodeLogo, width: 16, height: 19, excavate: false }}
          />
        ) : (
          <Box component="img" src={''} alt={t('qr_code_image')} marginRight={10} />
        )}
      </QRCodeImageWrapper>
      <ButtonWrapper rowGap={baseSM}>
        <QRCodeButton startIcon={<CopyIcon />} onClick={copyQRCode} disabled={!tagNumber}>
          <Typography textTransform="initial" variant="body3" color="primary">
            {t('visitor.copy_qr_code')}
          </Typography>
        </QRCodeButton>
        <QRCodeButton startIcon={<SendEmailIcon />} onClick={sendEmail} disabled={!tagNumber}>
          <Typography textTransform="initial" variant="body3" color="primary">
            {t(`visitor.${emailSended ? 'resend_email_to_visitor' : 'send_email_to_visitor'}`)}
          </Typography>
        </QRCodeButton>
      </ButtonWrapper>
    </QRCodeValueWrapper>
  );
};

const { baseSM, baseMD, baseLG } = dimensions;

const QRCodeValueWrapper = styled(Box)`
  display: flex;
  padding: ${baseSM} ${baseSM} ${baseSM} ${baseMD};
`;

const QRCodeImageWrapper = styled(Box)`
  width: 80px;
  height: 80px;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const QRCodeButton = styled(SecondaryButton)`
  width: fit-content;
  height: ${baseLG};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: flex-start;
`;
