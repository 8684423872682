import Papa from 'papaparse';
import { FileExtension } from '../types/file';
export const importCSV = async (file: File): Promise<any[]> => {
  return new Promise(resolve => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: results => {
        const newsItems = results.data;
        return resolve(newsItems);
      }
    });
  });
};

export const exportCSV = (newsItems: any[]): string => {
  const headerKeys = Object.keys(Object.assign({}, ...newsItems));
  const csv = Papa.unparse({
    fields: headerKeys,
    data: newsItems
  });

  return csv;
};

export const downloadCSVFile = (fileContent: string, fileName?: string) => {
  const file = new Blob([fileContent]);
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = (fileName || 'news-exported-' + Date.now()) + '.csv';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const convertFileToBase64 = (file?: File) => {
  if (!file) return Promise.resolve('');

  return new Promise<string>((resolve: (value: string) => void, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

export const isCSVFile = (fileName: string): boolean => {
  if (!fileName || !fileName.trim()) return false;

  const fileExt = fileName.trim().split('.').pop();

  return !!fileExt && fileExt === FileExtension.CSV;
};

export const getFileExtensionContentType = (fileExt: FileExtension): string => {
  switch (fileExt) {
    case FileExtension.CSV:
      return '.csv';
    default:
      return '';
  }
};
