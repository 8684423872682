import { Box, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useMatch } from 'react-router';
import { theme } from '../../styles/theme';
import { LocationRouteEndpoints } from '../../types/locationRouteEndpoints';
import { SideBarItemType } from '../../types/route';
import LocationList from './components/LocationList';
import NoticeMessage from './components/NoticeMessage';
import { BLANK_STRING } from './type';

import { AddNew } from './AddNew';
import { LocationInfo } from './LocationInfo';
import { Edit } from './Edit';

const Location: React.FunctionComponent = () => {
  const match = useMatch(`/${SideBarItemType.Location}/:id/*`);

  const { t } = useTranslation();

  const locationId = match?.params.id;

  const getLocationId = (id?: string) =>
    !id || [LocationRouteEndpoints.ADD_NEW as string].includes(id) ? BLANK_STRING : id;

  return (
    <Wrapper>
      <LocationList id={getLocationId(locationId)} />

      <Routes>
        <Route path={`/${LocationRouteEndpoints.ADD_NEW}`} element={<AddNew />} />

        <Route path="/:id/" element={<LocationInfo />} />

        <Route path={`/:id/${LocationRouteEndpoints.EDIT}`} element={<Edit />} />

        <Route path="/" element={<NoticeMessage message={t('location.view_details')} />} />
      </Routes>
    </Wrapper>
  );
};

export default Location;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${theme.palette.grey[500]};
`;
