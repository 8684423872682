import { Box, InputLabel, styled } from '@mui/material';
import React from 'react';
import { theme } from '../../styles/theme';
import { LazyLoadDropDownList } from './LazyLoadDropDownList';
import { NormalDropDownList } from './NormalDropDownList';
import { LazyLoadDropDownListProps, NormalDropDownListProps } from './type';

type Props = NormalDropDownListProps | LazyLoadDropDownListProps;

const isNormalDropDownListProps = (
  props: Partial<Props> & { type: 'normal' | 'lazy' }
): props is NormalDropDownListProps => {
  return props.type === 'normal';
};

const isLazyLoadDropDownListProps = (
  props: Partial<Props> & { type: 'normal' | 'lazy' }
): props is LazyLoadDropDownListProps => {
  return props.type === 'lazy';
};

export const DropDownList: React.FunctionComponent<Props> = (props: Props) => {
  const { label, width, ...rest } = props;

  const renderDropDownList = (props: Props) => {
    if (isLazyLoadDropDownListProps(props)) {
      const { type: _type, ...rest } = props;
      return <LazyLoadDropDownList {...rest} />;
    }

    if (isNormalDropDownListProps(props)) {
      const { type: _type, ...rest } = props;
      return <NormalDropDownList {...rest} />;
    }
  };

  return (
    <Box display="flex" height={props.height} width="100%" flexDirection="row" alignItems="center">
      {label && (
        <Label required={props.required} shrink={false}>
          {label}
        </Label>
      )}
      <Box width={width}>{renderDropDownList({ ...rest })}</Box>
    </Box>
  );
};

const { palette } = theme;

const Label = styled(InputLabel)`
  color: ${palette.grey[200]};
  width: 40%;
  .MuiInputLabel-asterisk {
    color: ${palette.error.main} !important;
  }
`;
