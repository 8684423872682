type GroupNamesRes = {
  groupID: string;
  groupName: string;
  groupLocation: string;
};

export const exportUserConverter = (data: { [key: string]: any }): { [key: string]: string } => {
  const { groupNames, id: _id, avatar: _avatar, ...rest } = data;

  const groupName = groupNames.map((item: GroupNamesRes) => item.groupName).join(',');
  const groupLocations = groupNames.map((item: GroupNamesRes) => item.groupLocation).join(',');

  return {
    ...rest,
    groupNames: groupName,
    groupLocations
  };
};
