import { PopulateGroupParams } from '../../../../models';

const DEFAULT_START_TIME = '00:00';
const DEFAULT_END_TIME = '23:59';

export enum DayOfWeek {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}

export enum TimePick {
  START_TIME = 'startTime',
  END_TIME = 'endTime'
}

export enum TableAction {
  ADD = 'add',
  REMOVE = 'remove'
}

export const getDefaultNewGroup = (): PopulateGroupParams => ({
  groupName: '',
  timeDetails: [
    {
      day: DayOfWeek.MONDAY,
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME
    },
    {
      day: DayOfWeek.TUESDAY,
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME
    },
    {
      day: DayOfWeek.WEDNESDAY,
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME
    },
    {
      day: DayOfWeek.THURSDAY,
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME
    },
    {
      day: DayOfWeek.FRIDAY,
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME
    },
    {
      day: DayOfWeek.SATURDAY,
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME
    },
    {
      day: DayOfWeek.SUNDAY,
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME
    }
  ],
  temporaryAccess: '0',
  usedDoorIds: [],
  belongUserIds: [],
  belongVisitorIds: []
});
