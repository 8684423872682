import { Box, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MainLayout } from '.';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';
import { EMPTY_STRING } from '../type';

const { base, baseXL, baseLG } = dimensions;
const { grey, common, primary } = theme.palette;

type Props = {
  isForgotPasswordMode: boolean;
};

type DecodeData = {
  qrImage: string;
  secret: string;
};

const CreateAuthAccount: React.FunctionComponent<Props> = ({ isForgotPasswordMode }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const naviagate = useNavigate();

  const [data, setData] = useState<DecodeData>();

  const QRImageEncode = location.pathname.split('/').at(-1);

  useEffect(() => {
    if (QRImageEncode) {
      try {
        const decodedData: DecodeData = JSON.parse(atob(QRImageEncode));
        decodedData && setData({ qrImage: decodedData.qrImage, secret: decodedData.secret });
      } catch (error) {
        naviagate('/auth/login');
      }
    }
  }, []);

  return (
    <MainLayout>
      <AddAuthAppContent>
        <Typography color="grey.100" variant="h1" align="center">
          {t('two_fa.add_2fa')}
        </Typography>

        <Box width="395px" marginTop={baseXL}>
          <Typography variant="body1">{t('two_fa.add_2fa_tutorial.step_1')}</Typography>

          <Typography variant="body1">{t('two_fa.add_2fa_tutorial.step_2')}</Typography>

          <Box>
            <Typography display="inline" variant="body1">
              {t('two_fa.add_2fa_tutorial.step_3.part_1')}
            </Typography>

            <Typography display="inline" variant="subtitle2">
              {t('two_fa.add_2fa_tutorial.step_3.part_2')}
            </Typography>

            <Typography display="inline" variant="body1">
              {t('two_fa.add_2fa_tutorial.step_3.part_3')}
            </Typography>
          </Box>
        </Box>

        <QRWrapper>
          <QRImage>
            <Box
              component="img"
              src={data ? data.qrImage : EMPTY_STRING}
              height="120px"
              width="120px"
              alt={t('two_fa.title_component.qr_image')}
              title={t('two_fa.title_component.qr_image')}
            />
          </QRImage>

          <QRValue>
            <Typography variant="h4" color="grey.100" title={t('two_fa.title_component.qr_code')}>
              {data ? data.secret : EMPTY_STRING}
            </Typography>
          </QRValue>
        </QRWrapper>

        <Typography color="grey.200" variant="body1">
          {t('two_fa.already_added')}
        </Typography>

        <LoginLink
          to={isForgotPasswordMode ? '/auth/forgot-password/verify' : `/auth/second-auth/verify/${QRImageEncode}`}
          replace={true}
          state={null}>
          <LinkContent>{t('two_fa.go_to_verify')}</LinkContent>
        </LoginLink>
      </AddAuthAppContent>
    </MainLayout>
  );
};

export default CreateAuthAccount;

const LoginLink = styled(Link)`
  text-decoration: none;
`;

const LinkContent = styled(Typography)`
  color: ${primary.dark};
  border-bottom: 1px solid ${theme.palette.primary.dark};
  margin-bottom: ${base};
`;

const QRWrapper = styled(Box)`
  width: 277px;
  box-shadow: 0px 4px 20px rgba(65, 69, 72, 0.2);
  border-radius: 4px;
  margin: ${baseXL} 0;
  padding: 0 ${baseLG};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AddAuthAppContent = styled(Box)`
  min-width: 460px;
  margin: ${base} 0 ${baseLG};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${common.white};
`;

const QRImage = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${base} 0;
  border-bottom: 1px solid ${grey[400]};
`;

const QRValue = styled(Box)`
  width: 249px;
  height: 44px;
  background-color: ${grey[500]};
  display: flex;
  margin: ${base} 0;
  justify-content: center;
  align-items: center;
  border: 1px solid ${grey[400]};
`;
