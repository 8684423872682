import { formatDateTime, TimeFormatType } from '../../../utils/date';

export enum GroupRouteEndpoints {
  ID = 'id',
  ADD_NEW = 'add-new',
  EDIT = 'edit'
}

export enum ActionType {
  EDIT = 'edit',
  ADD_NEW = 'add_new'
}

export const EXPORT_GROUP_FILE_NAME = `entrada_group_${formatDateTime(new Date(), TimeFormatType.FILENAME)}`;
