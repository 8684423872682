export const dimensions = {
  baseXS: '4px',
  baseSM: '8px',
  base: '12px',
  baseMD: '16px',
  baseXM: '20px',
  baseLG: '24px',
  baseXL: '32px',
  base2XL: '40px',
  base3XL: '64px',
  base4XL: '70px',
  base5XL: '80px',

  buttonHeightSmall: '24px',
  buttonHeight: '32px',
  buttonHeightLarge: '48px',

  buttonWidth: '32px',

  inputHeightSM: '36px',
  inputHeight: '40px',
  dropdownItemHeight: '40px',

  rowHeight: '48px',
  rowHeightLG: '64px',

  iconSize: '20px',
  iconSizeSmall: '16px'
};
