import React from 'react';
import { Box, IconButton, InputBase, Paper, styled } from '@mui/material';
import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { dimensions } from '../styles/dimensions';
import { theme } from '../styles/theme';
import { KeyBoard } from '../types/keyboard';
import { UnderLine } from '../types/searchBar';

type Props = {
  onSearch?: (value: string) => void;
  placeHolder?: string;
  onClear?: () => void;
  keyword?: string;
  onKeywordChanged?: (value: string) => void;
  justuderline?: UnderLine;
};

const EMPTY_STRING = '';

const SearchBar: React.FunctionComponent<Props> = (props: Props) => {
  const { onSearch, placeHolder, onClear, onKeywordChanged, keyword, justuderline } = props;

  const typingTimeoutRef = React.useRef<NodeJS.Timeout>();

  const handleStopTyping = (value: string) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current =
      onSearch &&
      setTimeout(async () => {
        await onSearch(value);
      }, 200);
  };

  return (
    <Wrapper>
      <SearchPaper justuderline={justuderline}>
        <SearchInput
          className="search_input"
          placeholder={placeHolder ? placeHolder : EMPTY_STRING}
          value={keyword}
          onChange={event => {
            onKeywordChanged && onKeywordChanged(event.target.value);

            handleStopTyping(event.target.value);
            if (event.target.value === '') {
              onClear && onClear();
            }
          }}
          onKeyDown={node => {
            if (node.key === KeyBoard.ENTER && keyword && onSearch) {
              node.preventDefault();
              onSearch(keyword);
            }
          }}
        />
        {keyword && (
          <ClearIconCustom
            onClick={() => {
              if (keyword !== EMPTY_STRING) {
                onKeywordChanged && onKeywordChanged(EMPTY_STRING);
                onClear && onClear();
              }
            }}
          />
        )}

        <SearchIcon
          type="submit"
          onClick={e => {
            e.preventDefault();

            if (keyword && onSearch) onSearch(keyword);
          }}>
          <Search />
        </SearchIcon>
      </SearchPaper>
    </Wrapper>
  );
};

export default SearchBar;

const { grey, error } = theme.palette;
const { baseMD, base } = dimensions;

const Wrapper = styled(Box)`
  width: 100%;
  height: 40px;
  color: ${grey[200]};
  margin-bottom: ${baseMD};
  margin-top: ${base};
`;

const SearchPaper = styled(Paper)(({ justuderline }: { justuderline: UnderLine | undefined }) => ({
  display: 'flex',
  width: '94%',
  height: '40px',
  padding: '0 3%',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: 'none',
  transition: 'ease-in-out 0.3s',
  border: justuderline === UnderLine.YES ? 'none' : `1px solid  ${grey[400]}`,
  borderBottom: justuderline === UnderLine.YES ? `1px solid  ${grey[400]}` : '',
  borderRadius: '2px',
  cursor: 'pointer'
}));

const SearchInput = styled(InputBase)`
  height: 100%;
  flex: 1;
  font-weight: 500;
  font-size: 16px;
`;

const SearchIcon = styled(IconButton)`
  margin-left: 2%;
`;

const ClearIconCustom = styled(ClearIcon)`
  color: ${grey[200]};
  :active {
    color: ${error.main};
  }
`;
