import { Box, styled, TableContainer, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTable } from '../../../../components/CustomTable';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { mockLogHistory } from '../mockData';
import { useUsersHook } from '../../store';

export const LogHistory: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [{ userLogs, userInfo }, { getLogsOfUser }] = useUsersHook();

  enum TableColumnKeys {
    DateTimeLog = 'time',
    DoorName = 'doorName',
    Location = 'doorLocation',
    Message = 'message'
  }

  const headerColumns = [
    {
      dataKey: TableColumnKeys.DateTimeLog,
      fieldName: t('user.log.time')
    },
    {
      dataKey: TableColumnKeys.DoorName,
      fieldName: t('user.log.door_name')
    },
    {
      dataKey: TableColumnKeys.Location,
      fieldName: t('user.log.door_location')
    },
    {
      dataKey: TableColumnKeys.Message,
      fieldName: t('user.log.message')
    }
  ];

  React.useEffect(() => {
    if (userInfo) getLogsOfUser(userInfo?.id);
  }, []);

  return (
    <Wrapper>
      <Title variant="body1">{t('user.log.title')}</Title>

      <LogContainer>
        <CustomTable columns={headerColumns} dataList={userLogs} cellMaxWidth="130px" />
      </LogContainer>
    </Wrapper>
  );
};

const { grey } = theme.palette;
const { base } = dimensions;

const Wrapper = styled(Box)`
  flex: 1.5;
  min-height: 500px;
  overflow-y: auto;
  padding-bottom: ${base};
`;

const LogContainer = styled(TableContainer)`
  height: calc(100% - 48px);
  overflow-y: auto;
`;

const Title = styled(Typography)`
  color: ${grey[100]};
  margin: ${base} 0;
`;
