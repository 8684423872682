export enum InputFieldType {
  TEXT = 'text',
  PASSWORD = 'password'
}

export type AuthError = {
  title: string;
  content: string;
};

export const DEFAULT_AUTH_ERROR: AuthError = {
  title: '',
  content: ''
};

export const FIRST_INDEX = 0;

export const CODE_LENGTH = 6;

export const EMPTY_STRING = '';

export const INSERT_INPUT_TYPE = 'insertText';

export const RE_DIGIT = new RegExp(/^\d+$/);

export const RE_PASSWORD = new RegExp('^(?=.{8,})');
