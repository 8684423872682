import { Box, Button, Dialog, Grow, styled, Typography } from '@mui/material';
import moment from 'moment';
import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useAppContext } from '../appContext';
import { ReactComponent as Dashboard } from '../assets/image/dashboard.svg';
import { ReactComponent as Door } from '../assets/image/door.svg';
import { ReactComponent as EmergencyTeam } from '../assets/image/emergencyTeam.svg';
import { ReactComponent as EntradaSide } from '../assets/image/EntradaSide.svg';
import { ReactComponent as Group } from '../assets/image/group.svg';
import { ReactComponent as Location } from '../assets/image/location.svg';
import { ReactComponent as Logout } from '../assets/image/logout.svg';
import { ReactComponent as Print } from '../assets/image/print.svg';
import { ReactComponent as Report } from '../assets/image/report.svg';
import { ReactComponent as User } from '../assets/image/user.svg';
import { ReactComponent as Activated } from '../assets/image/Users/Activated.svg';
import { ReactComponent as Vector } from '../assets/image/Vector.svg';
import { ReactComponent as Visitor } from '../assets/image/visitor.svg';
import { useAuthAction } from '../modules/Auth/store';

import { dimensions } from '../styles/dimensions';
import { theme } from '../styles/theme';
import { DropDownListType } from '../types';
import { PopupAction } from '../types/action';
import { SideBarItem, SideBarItemType } from '../types/route';
import { TimeFormatType } from '../utils/date';
import { PrimaryButton } from './Button';
import { Popup } from './Popup';
import { TableEvacuation } from './TableEvacuation';

const SideBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuthAction();
  const { adminProfile, locations } = useAppContext();

  const componentToPrint = useRef<HTMLDivElement>(null);

  const [openLogout, setOpenLogout] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<DropDownListType>();
  const [showEvacuationPopup, setShowEvacuationPopup] = useState<boolean>(false);

  const ACTIVE = 'active';
  const BLANK_STRING = '';
  const path = useLocation().pathname;
  const sideBarList: SideBarItem[] = [
    {
      type: SideBarItemType.Dashboard,
      title: t('sidebar.dashboard'),
      icon: <Dashboard />,
      linkTo: '/'
    },
    {
      type: SideBarItemType.User,
      title: t('sidebar.user'),
      icon: <User />,
      linkTo: `/users/${SideBarItemType.User}`
    },
    {
      type: SideBarItemType.Visitor,
      title: t('sidebar.visitor'),
      icon: <Visitor />,
      linkTo: `/users/${SideBarItemType.Visitor}`
    },
    {
      type: SideBarItemType.Group,
      title: t('sidebar.group'),
      icon: <Group />,
      linkTo: `/users/${SideBarItemType.Group}`
    },
    {
      type: SideBarItemType.Location,
      title: t('sidebar.location'),
      icon: <Location />,
      linkTo: `/${SideBarItemType.Location}`
    },
    {
      type: SideBarItemType.Report,
      title: t('sidebar.report'),
      icon: <Report />,
      linkTo: `/${SideBarItemType.Report}`
    },
    {
      type: SideBarItemType.Door,
      title: t('sidebar.door'),
      icon: <Door />,
      linkTo: `/${SideBarItemType.Door}`
    },
    {
      type: SideBarItemType.EmergencyTeam,
      title: t('sidebar.emergency_team'),
      icon: <EmergencyTeam />,
      linkTo: `/${SideBarItemType.EmergencyTeam}`
    }
  ];
  const logoutAction: PopupAction = {
    name: t('logout.button.logout'),
    onAction: async () => {
      await logout();
      navigate('/auth/login', { replace: true });
      navigate(0);
    }
  };
  const evacuationLocation: DropDownListType[] = locations.map(location => {
    return {
      id: location.id,
      label: location.location
    };
  });

  const handleLogoutOpen = () => {
    setOpenLogout(true);
  };

  const handleLogoutClose = () => {
    setOpenLogout(false);
  };

  const getCurrentItem = () => {
    const paths = path.split('/');
    const comparedPath = paths[2] || paths[1];
    if (comparedPath === BLANK_STRING) {
      return SideBarItemType.Dashboard;
    }

    const sideBarItems = Object.values(SideBarItemType).slice(1);
    for (const index in sideBarItems) {
      if (paths.includes(sideBarItems[index])) {
        return sideBarItems[index];
      }
    }
  };

  const currentItem = getCurrentItem();

  const handleTriggerEvacuate = useReactToPrint({
    content: () => componentToPrint.current
  });

  const handleCloseEvacuationDialog = () => {
    setShowEvacuationPopup(false);
  };

  const handleSetupLocationToEvacuate = () => {
    setShowEvacuationPopup(false);
    //TODO: Will integrate with API in https://sioux.atlassian.net/browse/ECA-393
  };

  useEffect(() => {
    setCurrentLocation(evacuationLocation[0]);
  }, [locations]);

  return (
    <Wrapper>
      <Dialog onClose={handleCloseEvacuationDialog} open={showEvacuationPopup}>
        <TitleDialogWrapper>
          <Typography variant="h4">{t('evacuation.location_setting_title')}</Typography>
          <Typography variant="body2" color="grey.200">
            {t('evacuation.location_setting_subtitle')}
          </Typography>
        </TitleDialogWrapper>

        <CurrentLocationWrapper>
          <Typography variant="body1" color="grey.100">
            {t('evacuation.current_location')}
          </Typography>
          <Grow in={true}>
            <Typography variant="subtitle2">{currentLocation ? currentLocation.label : BLANK_STRING}</Typography>
          </Grow>
        </CurrentLocationWrapper>

        <StyledList>
          <OptionsWrapper>
            {evacuationLocation.map(item => (
              <LocationTag
                key={item.id}
                className={currentLocation && currentLocation.label === item.label ? ACTIVE : BLANK_STRING}
                onClick={() => setCurrentLocation(item)}>
                <LocationOption
                  className={currentLocation && currentLocation.label === item.label ? ACTIVE : BLANK_STRING}
                  variant="body1"
                  color="grey.100">
                  {item.label}
                </LocationOption>

                <Activated
                  display={currentLocation && currentLocation.label === item.label ? 'inline-block' : 'none'}
                />
              </LocationTag>
            ))}
          </OptionsWrapper>

          <Box margin={`${baseLG} 0`} display="flex" justifyContent="flex-end">
            <PrimaryButton content={t('confirm')} onClick={handleSetupLocationToEvacuate} />
          </Box>
        </StyledList>
      </Dialog>

      <ItemsWrapper>
        <LogoEntradaWrapper>
          <EntradaSide>
            <Link to={{ pathname: '/' + SideBarItemType.Dashboard }} />
          </EntradaSide>
        </LogoEntradaWrapper>

        <SideBarItemGroup>
          {sideBarList.map((item: SideBarItem, index: number) => (
            <SideBarItemLink to={{ pathname: item.linkTo }} key={index} replace={true}>
              <WrapperItem className={currentItem == item.type ? ACTIVE : BLANK_STRING}>
                <Box marginLeft={`${baseMD}`} width={`${baseXM}`} height={`${baseXM}`}>
                  {item.icon}
                </Box>

                <Item
                  marginLeft="4%"
                  variant="subtitle4"
                  title={item.title}
                  className={currentItem == item.type ? ACTIVE : BLANK_STRING}>
                  {item.title}
                </Item>
              </WrapperItem>
            </SideBarItemLink>
          ))}
        </SideBarItemGroup>

        <EvacuationWrapper>
          <ReactToPrint
            trigger={() => (
              <EvacuationButton onClick={handleTriggerEvacuate}>
                <Print />

                <EvacuationContent>{t('sidebar.evacuation')}</EvacuationContent>
              </EvacuationButton>
            )}
            content={() => componentToPrint.current}
            pageStyle="@page { size: auto }"
            documentTitle={`${adminProfile && adminProfile.client}-${
              currentLocation ? currentLocation.label : BLANK_STRING
            }-${moment(new Date()).format(TimeFormatType.FILE_DDMMYYY)}-${moment(new Date()).format(
              TimeFormatType.FILE_HHMM
            )}`}
          />

          <LocationButton onClick={() => setShowEvacuationPopup(true)}>
            <Vector />
          </LocationButton>
        </EvacuationWrapper>

        <Box display="none">
          <TableEvacuation componentToPrint={componentToPrint} />
        </Box>
      </ItemsWrapper>

      <LogoutButton startIcon={<Logout />} onClick={handleLogoutOpen}>
        {t('sidebar.logout')}
      </LogoutButton>

      <Popup
        title={t('logout.content')}
        open={openLogout}
        onClose={handleLogoutClose}
        action={logoutAction}
        content={t('logout.popup_content')}
        closeactiontext={t('logout.button.cancel')}
      />
    </Wrapper>
  );
};

export default SideBar;

const { common, grey, primary } = theme.palette;
const { baseXM, baseMD, baseLG, base, base2XL, baseSM, rowHeight, baseXS, inputHeight } = dimensions;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background: linear-gradient(180deg, ${grey[100]} 10%, ${grey[200]} 80%);
  overflow: auto;

  ${props => props.theme.breakpoints.up('md')} {
    width: 220px;
  }
  ${props => props.theme.breakpoints.down('lg')} {
    width: 150px;
  }
`;

const ItemsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const LogoEntradaWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: ${baseLG};
  ${props => props.theme.breakpoints.up('md')} {
    width: 100%;
  }
  ${props => props.theme.breakpoints.down('lg')} {
    width: 70%;
  }
`;

const SideBarItemGroup = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${baseLG};
  width: 100%;
`;

const EvacuationWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: ${baseSM};
  margin-top: ${baseLG};
`;

const EvacuationContent = styled(Typography)`
  margin-left: ${baseSM};
  ${props => props.theme.breakpoints.down('lg')} {
    display: none;
  }
`;

const EvacuationButton = styled(Button)`
  border: 1px solid ${primary.light};
  width: 60%;
  text-transform: unset !important;
  color: ${primary.light};
  ${props => props.theme.breakpoints.down('lg')} {
    width: 30%;
  }
  :hover {
    color: ${common.white};
    border: 1px solid ${primary.main};
    background-color: ${primary.main};
  }
  :active {
    color: ${common.white};
    border: 1px solid ${primary.dark};
    background-color: ${primary.dark};
  }
`;

const LogoutButton = styled(Button)`
  background-color: ${grey[200]};
  color: ${common.white};
  width: 80%;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: ${base};
  margin-top: ${base};
  ${props => props.theme.breakpoints.down('lg')} {
    font-size: 12px;
  }
  :hover {
    background-color: ${grey[100]};
  }
`;

const WrapperItem = styled(Box)`
  min-height: ${rowHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-left: ${baseXS} solid transparent;
  color: ${grey[300]};

  :hover {
    cursor: pointer;
    background-color: ${grey[100]};
  }
  &.active {
    color: ${primary.light};
    border-left: ${baseXS} solid ${primary.main};
    background-color: ${grey[100]};
  }
`;

const Item = styled(Typography)`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${grey[300]};

  ${props => props.theme.breakpoints.down('lg')} {
    font-size: 10px;
    font-weight: 600;
  }

  &.active {
    color: ${primary.light};
  }
`;

const SideBarItemLink = styled(Link)`
  text-decoration: none;
  display: flex;
`;

const TitleDialogWrapper = styled(Box)`
  margin: ${base2XL} ${base2XL} 0;
`;

const CurrentLocationWrapper = styled(Box)`
  display: flex;
  height: 40px;
  flex-direction: row;
  align-items: flex-end;
  column-gap: ${baseSM};
  margin: ${baseLG} ${base2XL};
`;

const StyledList = styled(Box)`
  width: 480px;
  margin: 0 ${base2XL};
`;

const LocationButton = styled(Button)`
  width: ${base2XL};
  height: ${base2XL};
  min-width: 0;
  border: 1px solid ${grey[200]};
  &:hover {
    background-color: ${grey[200]};
  }
`;

const OptionsWrapper = styled(Box)`
  max-height: 300px;
  width: 100%;
  border: 1px solid ${grey[300]};
  border-radius: 4px;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
`;

const LocationOption = styled(Typography)`
  cursor: pointer;
  &.active {
    color: ${primary.main};
    font-size: 16px;
    font-weight: 700;
  }
  &:hover {
    background-color: ${primary.lighter};
  }
`;

const LocationTag = styled(Box)`
  width: 100%;
  height: ${inputHeight};
  padding: 0 4%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: ${primary.lighter};
  }
`;
