import { Navigate, Route, Routes } from 'react-router-dom';
import { isAuthenticated } from '../../utils/storage';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import SecondAuth from './SecondAuth';

const Authentication: React.FunctionComponent = () => {
  if (isAuthenticated()) return <Navigate to="/" replace />;
  else
    return (
      <Routes>
        <Route path="" element={<Navigate to="login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password/*" element={<ForgotPassword />} />
        <Route path="/second-auth/*" element={<SecondAuth />} />
      </Routes>
    );
};

export default Authentication;
