import { Close } from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';
import { dimensions } from '../../styles/dimensions';
import { TIME_TO_CLOSE } from './type';

const { baseMD } = dimensions;

export const ToastMuiContainer: React.FunctionComponent = () => (
  <ToastContainer
    position="top-right"
    autoClose={TIME_TO_CLOSE}
    closeOnClick={false}
    draggable={false}
    hideProgressBar
    theme="light"
    closeButton={CloseButton}
    style={{ minWidth: '400px', marginTop: `${baseMD}` }}
  />
);

const CloseButton = ({ closeToast }: any) => (
  <Close onClick={closeToast} style={{ margin: `${baseMD} ${baseMD} 0 0` }} />
);
