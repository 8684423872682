import { styled } from '@mui/material';
import InfinityScroll from 'react-infinite-scroll-component';

/** When using this component, height property of infinity scroll MUST be defined */
export const ResponsiveInfinityScroll = styled(InfinityScroll)`
  @media screen and (max-height: 599px) {
    height: 71vh !important;
  }
  @media screen and (min-height: 600px) and (max-height: 749px) {
    height: 65.5vh !important;
  }
  @media screen and (min-height: 750px) and (max-height: 850px) {
    height: 70vh !important;
  }
  @media screen and (min-height: 851px) {
    height: 74.5vh !important;
  }
`;
