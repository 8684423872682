import React from 'react';
import { Moment } from 'moment';
import { Box, Fade, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { dimensions } from '../../../../styles/dimensions';
import { TimeFormatType } from '../../../../utils/date';

import { Content, ContentHeader, ContentWrapper, StandardTime, TableWrapper, TimeList, Title } from '../../components';
import { PrimaryButton, SecondaryButton } from '../../../../components/Button';

import { BelongUsers, BelongVisitors, DoorAuthorization } from './Table';
import { DayOfWeek, TimePick } from './types';

import GroupNameInput from './GroupNameInput';
import TemporaryAccessCheckbox from './TemporaryAccessCheckbox';

import { Popup } from '../../../../components/Popup';
import { SideBarItemType } from '../../../../types/route';

import { useUsersHook } from '../../store';
import { useCallbackPrompt } from '../../../../utils/useCallbackPrompt';
import { showSuccessToast, showErrorToast } from '../../../../components/Toast/actions';
import { PopulateGroupParams } from '../../../../models';
import { CircularLoading, StandardTimeItem } from '../components';
import { validateGroupForm } from '../utils';
import { ActionType } from '../types';
import { BooleanValue } from '../../../../types';

const Edit: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();

  const [{ groupInformation }, { getGroupById, editGroup, updateParticipantInGroup, updateDoorsInGroup }] =
    useUsersHook();

  const [loading, setLoading] = React.useState<boolean>(false);

  const editGroupParam = React.useRef<PopulateGroupParams>();

  const getGroupInformation = async () => {
    setLoading(true);

    id && (await getGroupById(id));

    setLoading(false);
  };

  const handleStandardTimeItemChanged = (moment: Moment, day: string, type: TimePick) => {
    const params = editGroupParam.current;

    if (!params) return;

    const time = params.timeDetails.find(item => item.day === day);

    if (time) {
      if (type === TimePick.START_TIME) {
        time.startTime = moment.format(TimeFormatType.HOUR_MINUTE);
      } else {
        time.endTime = moment.format(TimeFormatType.HOUR_MINUTE);
      }

      const newStandardTimes = params.timeDetails.map(item => (item.day === day ? time : item));

      params.timeDetails = newStandardTimes;
    }
  };

  const handleGroupNameChanged = (value: string) => {
    const params = editGroupParam.current;

    if (!params) return;

    params.groupName = value;
  };

  const handleTemporaryAccessChanged = (value: string) => {
    const params = editGroupParam.current;

    if (!params) return;

    params.temporaryAccess = value;
  };

  const handleBelongUserIdsChanged = (userIds: string[]) => {
    const params = editGroupParam.current;

    if (!params) return;

    params.belongUserIds = userIds;
  };

  const handleBelongVisitorIdsChanged = (visitorIds: string[]) => {
    const params = editGroupParam.current;

    if (!params) return;

    params.belongVisitorIds = visitorIds;
  };

  const handleUsedDoorIdsChanged = (doorIds: string[]) => {
    const params = editGroupParam.current;

    if (!params) return;

    params.usedDoorIds = doorIds;
  };

  const handleEditGroup = async () => {
    setLoading(true);

    const errorMessage = editGroupParam.current && validateGroupForm(editGroupParam.current, ActionType.EDIT);

    if (errorMessage) {
      const { title, subtitle, data } = errorMessage;

      setLoading(false);

      showErrorToast({
        title: t(title),
        subtitle: t(subtitle, data)
      });

      return;
    }

    try {
      const params = editGroupParam.current;

      if (!id || !params) {
        return;
      }

      const dayOfWeek = Object.values(DayOfWeek);
      const timeConvert: { [key: string]: any } = {};
      params.timeDetails.forEach((standardTime, idx) => {
        const { startTime, endTime } = standardTime;
        timeConvert[dayOfWeek[idx]] = `${startTime},${endTime}`;
      });

      const newGroupData = {
        name: params.groupName,
        proId: '1',
        active: '0',
        objectEnable: {
          objects: params.usedDoorIds?.join(',') ?? ''
        },
        time: timeConvert,
        LocationId: '1',
        temporaryAccess: params.temporaryAccess
      };
      await editGroup(id, newGroupData);

      const { belongUserIds, belongVisitorIds, usedDoorIds } = params;

      await Promise.all([
        updateParticipantInGroup([...belongUserIds, ...belongVisitorIds]),
        updateDoorsInGroup(usedDoorIds)
      ]);

      setLoading(false);

      showSuccessToast({
        title: t('group.edit.message.success.title'),
        subtitle: t('group.edit.message.success.subtitle')
      });
    } catch (error) {
      setLoading(false);

      showErrorToast({
        title: t('group.edit.message.fail.title'),
        subtitle: t('group.edit.message.fail.subtitle')
      });

      return;
    }

    navigate(`/users/${SideBarItemType.Group}/${id}`);

    confirmNavigation();
  };

  const handleEditGroupParamChanged = () => {
    if (!groupInformation) return;
    const {
      objectsEnabled: doorAuthorization,
      groupName,
      timeDetails,
      groupTmpAccessEnabled,
      usersInGroup,
      visitorsInGroup
    } = groupInformation;

    const userIds = usersInGroup?.users.map(item => item.userId);
    const visitorIds = visitorsInGroup?.users.map(item => item.userId);
    const doorIds = doorAuthorization?.map(item => item.doorId);

    editGroupParam.current = {
      groupName: groupName,
      usedDoorIds: doorIds ?? [],
      belongUserIds: userIds ?? [],
      timeDetails: timeDetails,
      belongVisitorIds: visitorIds ?? [],
      temporaryAccess: groupTmpAccessEnabled
    };
  };

  React.useEffect(() => {
    getGroupInformation();
  }, []);

  React.useEffect(() => {
    groupInformation && handleEditGroupParamChanged();
  }, [groupInformation]);

  const standardTimes = editGroupParam.current ? editGroupParam.current.timeDetails : [];

  return (
    <ContentWrapper>
      {loading ? (
        <CircularLoading />
      ) : (
        <Fade in={true}>
          <Content>
            <ContentHeader>
              <Typography variant="subtitle4">{t('group.edit.title')}</Typography>

              <ButtonWrapper>
                <SecondaryButton
                  content={t('group.edit.button.cancel')}
                  height={buttonHeight}
                  onClick={() => navigate(-1)}
                />
                <PrimaryButton content={t('group.edit.button.save')} height={buttonHeight} onClick={handleEditGroup} />
              </ButtonWrapper>
            </ContentHeader>
            <Box paddingTop={baseMD} width="35%" minWidth="400px">
              <GroupNameInput onGroupNameChanged={handleGroupNameChanged} />
            </Box>

            <StandardTime>
              <Title variant="subtitle3">{t('group.select_standard_time')}</Title>

              <TimeList>
                {standardTimes.map(item => (
                  <StandardTimeItem key={item.day} item={item} onTimeChanged={handleStandardTimeItemChanged} />
                ))}
              </TimeList>

              <TemporaryAccessCheckbox
                onAccessChanged={value => handleTemporaryAccessChanged(value ? BooleanValue.TRUE : BooleanValue.FALSE)}
              />
            </StandardTime>
            <TableWrapper container spacing={3}>
              <DoorAuthorization onUsedDoorIdsChanged={handleUsedDoorIdsChanged} />

              <BelongUsers onBelongUserIdsChanged={handleBelongUserIdsChanged} />

              <BelongVisitors onBelongVisitorIdsChanged={handleBelongVisitorIdsChanged} />
            </TableWrapper>
          </Content>
        </Fade>
      )}

      <Popup
        title={t('group.edit.popup.title')}
        content={t('group.edit.popup.content')}
        open={showPrompt}
        onClose={cancelNavigation}
        closeactiontext={t('group.edit.button.cancel')}
        action={{
          name: t('group.edit.button.quit'),
          onAction: confirmNavigation
        }}
      />
    </ContentWrapper>
  );
};

export default Edit;

const { baseMD, buttonHeight } = dimensions;

const ButtonWrapper = styled(Box)`
  display: flex;
  column-gap: ${baseMD};
`;
