import { Box, styled } from '@mui/material';
import { theme } from '../../../styles/theme';

const { grey } = theme.palette;

export const ListHeader = styled(Box)`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${grey[400]};
`;
