export const colors = {
  //Primary
  white: '#FFFFFF',
  darkGreen: '#698223',
  mainGreen: '#96C11F',
  lightGreen: '#D1EC86',
  lighterGreen: '#F2FBD7',

  //Text color
  grey1: '#414548',
  grey2: '#727980',
  grey3: '#ACB8BD',
  grey4: '#D8DFE0',
  grey5: '#F6F9FA',

  //Signal color
  mainInfoBlue: '#0D8BFF',
  lightInfoBlue: '#D6EBFF',
  mainWarningOrange: '#FF900D',
  lightWarningOrange: '#FFE9CE',
  lighterWarningOrange: '#FFF4D1',
  mainErrorRed: '#FF3D00',
  lightErrorRed: '#FFE1D8',

  //Shadow
  shadow1Grey: '#ACB8BD4D',
  shadow2Grey: '#ACB8BD40',
  shadow3Grey: '#41454833',
  shadow4Grey: '#ACB8BD4D',
  shadow5Green: '#9FBF4399'
};
