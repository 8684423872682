import { Box, Typography, styled } from '@mui/material';
import { dimensions } from '../../../styles/dimensions';

const { baseSM, baseMD, baseXL, baseLG } = dimensions;

export const StandardTime = styled(Box)`
  padding-top: ${baseMD};
`;

export const Title = styled(Typography)`
  padding-bottom: ${baseXL};
`;

export const TimeList = styled(Box)`
  padding: ${baseXL} 0 ${baseLG};
  row-gap: ${baseSM};
`;
