export const enum StatusEmergencyTeam {
  ON = 'ON',
  OFF = 'OFF'
}

export enum EmergencyKeys {
  ID = 'id',
  NAME = 'name',
  LOCATION = 'location',
  STATUS = 'status'
}

export const DEFAULT_ID = -1;
export const ALL = 'All';
