import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { RefObject } from 'react';
import { theme } from '../styles/theme';

type Props = {
  componentToPrint: RefObject<HTMLDivElement>;
};

export const TableEvacuation: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <TableContainer ref={props.componentToPrint}>
      <Table>
        <TableHead>
          <RowWrapper>
            {Object.keys(mockLogHistory[0]).map(title => (
              <TableCell key={title}>{title}</TableCell>
            ))}
          </RowWrapper>
        </TableHead>

        <TableBody>
          {mockLogHistory.map((log, index) => (
            <RowWrapper key={index}>
              {Object.values(log).map((content, index) => (
                <TableCell key={index}>{content}</TableCell>
              ))}
            </RowWrapper>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const { grey } = theme.palette;

const RowWrapper = styled(TableRow)`
  width: 100%;
  &:nth-of-type(odd) {
    background-color: ${grey[500]};
  }
`;

//TODO: Will be remove in https://sioux.atlassian.net/browse/ECA-367
export const mockLogHistory = [
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  },
  {
    User: 'Long Nhat',
    DateTime: '11h30 18/03/2023',
    DoorName: 'Wing C 31',
    DoorLocation: 'Eindhoven',
    Room: 'A11',
    Department: 'development',
    Phone: '0911476091'
  }
];
