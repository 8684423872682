/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { StatusCode } from '../types/http';
import { StorageKeys } from '../types/storage';
import { isAuthenticated } from './storage';

const apiURL = `${process.env.REACT_APP_BACKEND_URL}api/V2/`;

const headers = () => ({
  Authorization: localStorage.getItem(StorageKeys.TOKEN)
});

axios.interceptors.response.use(
  response => response,
  async error => {
    const { status } = error.response;
    if (status === StatusCode.UNAUTHORIZED && isAuthenticated()) {
      localStorage.clear();
      window.location.replace('/auth/login');
    }
    return Promise.reject(error);
  }
);

export const $get = async (endpoint: string, option?: any): Promise<any> => {
  const config = {
    headers: headers(),
    ...option
  };

  const response: AxiosResponse<any> = await axios.get(`${apiURL}${endpoint}`, config);

  return response.data;
};

export const $post = async (endpoint: string, data?: any, option?: any): Promise<any> => {
  const config = {
    headers: headers(),
    ...option
  };

  const response: AxiosResponse<any> = await axios.post(`${apiURL}${endpoint}`, data, config);

  return response.data;
};

export const $put = async (endpoint: string, data: any, option?: any): Promise<any> => {
  const config = {
    headers: headers(),
    ...option
  };

  const response: AxiosResponse<any> = await axios.put(` ${apiURL}${endpoint}`, data, config);

  return response.data;
};

export const $delete = async (endpoint: string, option?: any): Promise<any> => {
  const config = {
    headers: headers(),
    ...option
  };

  const response: AxiosResponse<any> = await axios.delete(` ${apiURL}${endpoint}`, config);

  return response.data;
};
