import { Box, styled } from '@mui/material';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';

const { grey } = theme.palette;
const { baseMD, baseXM } = dimensions;

export const ContentHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${baseMD} 0 ${baseXM};
  border-bottom: 1px solid ${grey[400]};
`;
