import { styled, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useLocationStates } from '../store';
import { ContentWrapper } from './ContentWrapper';

type Props = {
  message: string;
};

const NoticeMessage: React.FunctionComponent<Props> = ({ message }: Props) => {
  const { locationList } = useLocationStates();

  const isEmptyList = isEmpty(locationList);
  return (
    <MessageWrapper>
      {!isEmptyList && (
        <Typography variant="h2" color="grey.300">
          {message}
        </Typography>
      )}
    </MessageWrapper>
  );
};

export default NoticeMessage;

const MessageWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
