import React from 'react';

import { SelectProps, Typography, Box } from '@mui/material';
import { SelectBox, SelectBoxType } from './SelectBox';
import { isEmpty } from 'lodash';

type Props = {
  label?: string;
  labelWidth?: string;
  width?: string;
  selectBoxWidth?: string;
  minWidth?: string;
  listItem: SelectBoxType[];
} & SelectProps;

export const SelectBoxWithLabel: React.FunctionComponent<Props> = (props: Props) => {
  const { label, labelWidth, width, minWidth, selectBoxWidth, listItem, ...others } = props;

  const defaultValue = isEmpty(listItem) ? '' : listItem[0].value.toString();

  return (
    <Box width={width} minWidth={minWidth} display="flex" alignItems="center">
      {label && (
        <Typography minWidth={labelWidth} color="grey.200" noWrap overflow="hidden">
          {label}
        </Typography>
      )}
      <SelectBox listItem={listItem} width={selectBoxWidth} {...others} defaultValue={defaultValue} />
    </Box>
  );
};
