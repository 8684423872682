import React from 'react';
import { useTranslation } from 'react-i18next';
import { DoorAuthorization as DoorAuthorizationType } from '../../../../../models';
import { useUsersHook } from '../../../store';
import { GridTable } from '../../components';
import { TableAction } from '../types';
import _ from 'lodash';

type Props = {
  onUsedDoorIdsChanged: (doorIds: string[]) => void;
};

export const DoorAuthorization: React.FunctionComponent<Props> = (props: Props) => {
  const { onUsedDoorIdsChanged } = props;

  const { t } = useTranslation();

  const [{ groupInformation, existedDoors }, { getExistedDoors }] = useUsersHook();
  const { objectsEnabled: doorAuthorization } = groupInformation;

  const [usedDoors, setUsedDoors] = React.useState<DoorAuthorizationType[]>([]);
  const [notUsedDoors, setNotUsedDoors] = React.useState<DoorAuthorizationType[]>([]);

  const titles = [
    t('group.door_authorization.column.id'),
    t('group.door_authorization.column.door'),
    t('group.door_authorization.column.location'),
    t('group.door_authorization.column.module'),
    t('group.action')
  ];

  const placeHolder = t('group.door_authorization.placeholder');

  const sortDoorsByDoorId = (doors: DoorAuthorizationType[]) =>
    doors.sort((prev, next) => parseInt(prev.doorId) - parseInt(next.doorId));

  const handleAddDoorIntoGroup = (door: DoorAuthorizationType) => {
    const newNotUsedDoorsInGroup = sortDoorsByDoorId(notUsedDoors.filter(item => item.doorId !== door.doorId));
    const newUsedDoor = [door, ...usedDoors];

    onUsedDoorIdsChanged(newUsedDoor.map(item => item.doorId));

    setUsedDoors(newUsedDoor);
    setNotUsedDoors(newNotUsedDoorsInGroup);
  };

  const handleRemoveDoorIntoGroup = (door: DoorAuthorizationType) => {
    const newUsedDoorsInGroup = sortDoorsByDoorId(usedDoors.filter(item => item.doorId !== door.doorId));

    onUsedDoorIdsChanged(newUsedDoorsInGroup.map(item => item.doorId));

    setNotUsedDoors(sortDoorsByDoorId([door, ...notUsedDoors]));
    setUsedDoors(newUsedDoorsInGroup);
  };

  React.useEffect(() => {
    getExistedDoors();
  }, []);

  React.useEffect(() => {
    setNotUsedDoors(sortDoorsByDoorId(_.differenceBy(existedDoors, doorAuthorization, 'doorId')));
    setUsedDoors(sortDoorsByDoorId(doorAuthorization ?? []));
  }, [doorAuthorization]);

  return (
    <>
      <GridTable
        header={t('group.door_authorization.used_in_the_group')}
        titles={titles}
        rows={usedDoors.map(it => ({
          doorId: it.doorId,
          doorName: it.doorName,
          doorLocation: it.doorLocation,
          moduleName: it.moduleName
        }))}
        actionType={TableAction.REMOVE}
        action={handleRemoveDoorIntoGroup}
        placeholder={placeHolder}
      />

      <GridTable
        header={t('group.door_authorization.not_used_in_group')}
        titles={titles}
        rows={notUsedDoors.map(it => ({
          doorId: it.doorId,
          doorName: it.doorName,
          doorLocation: it.doorLocation,
          moduleName: it.moduleName
        }))}
        actionType={TableAction.ADD}
        action={handleAddDoorIntoGroup}
        placeholder={placeHolder}
      />
    </>
  );
};
