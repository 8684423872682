import { Box, Divider, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { OptionButton, OptionButtonType } from '../../../../components/Button/OptionButton';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { useUsersStates } from '../../store';
import { BLANK_STRING } from '../../types';
import { VisitorRouteEndpoints } from '../type';

export const Header: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { visitorInfo } = useUsersStates();

  const handleEdit = () => {
    navigate(`${VisitorRouteEndpoints.EDIT}`);
  };

  if (!visitorInfo) return <></>;

  const { name, phone, email, visitor: contractPerson } = visitorInfo;
  let contractPersonName = BLANK_STRING;
  let contractPersonPhone = BLANK_STRING;

  const visitor = {
    id: id || BLANK_STRING,
    name,
    phone,
    email
  };

  const visitorPhone = visitor.phone ? `+${visitor.phone}` : BLANK_STRING;

  if (contractPerson) {
    contractPersonName =
      contractPerson.contactName && contractPerson.contactId
        ? `${contractPerson.contactName} | ${contractPerson.contactId}`
        : BLANK_STRING;

    contractPersonPhone = contractPerson.phone ? `+${contractPerson.phone}` : BLANK_STRING;
  }

  return (
    <Wrapper>
      <InfoWrapper flexDirection="column" rowGap={`${baseXS}`}>
        <Typography variant="h3" color="grey.100">
          {visitor.name}
        </Typography>
        <Box display="flex" flexDirection="row" rowGap={`${baseXS}`}>
          <Box display="flex" flexDirection="column" rowGap={`${baseXS}`}>
            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.phone_number')}</TextWrapper>
              <Typography variant="subtitle4" color="grey.200">
                {visitorPhone}
              </Typography>
            </InfoWrapper>

            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.email')}</TextWrapper>
              <Typography variant="subtitle4" color="grey.200">
                {visitor.email}
              </Typography>
            </InfoWrapper>
          </Box>

          <VerticalLine orientation="vertical" />

          <UserImageWrapper component="img" src={contractPerson ? contractPerson.contactAvatar : BLANK_STRING} />

          <Box display="flex" flexDirection="column" rowGap={`${baseXS}`} paddingLeft="20px">
            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.contract_name')}</TextWrapper>
              <ContractPersonNameWrapper variant="subtitle4" color="grey.100">
                {contractPersonName}
              </ContractPersonNameWrapper>
            </InfoWrapper>

            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.department')}</TextWrapper>
              <Typography variant="subtitle4" color="grey.200">
                {contractPerson ? contractPerson.departement : BLANK_STRING}
              </Typography>
            </InfoWrapper>

            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.phone_number')}</TextWrapper>
              <Typography variant="subtitle4" color="grey.200">
                {contractPersonPhone}
              </Typography>
            </InfoWrapper>
          </Box>
        </Box>
      </InfoWrapper>

      <ButtonWrapper>
        <OptionButton type={OptionButtonType.EDIT} content={t('edit')} onClick={handleEdit} isscaledown={false} />
      </ButtonWrapper>
    </Wrapper>
  );
};

const { base, baseSM, baseXS, baseMD, baseLG, buttonHeight } = dimensions;
const { grey } = theme.palette;

const Wrapper = styled(Box)`
  padding: ${baseMD} ${baseLG};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UserImageWrapper = styled(Box)(({ src }: { src: string | undefined }) => ({
  width: '64px',
  height: '64px',
  border: `1px solid ${grey[400]}`,
  borderRadius: `${baseXS}`,
  boxShadow: `${theme.shadows[2]}`,
  src: src,
  marginRight: `${baseSM}`,
  flexShrink: 0
}));

const InfoWrapper = styled(Box)`
  display: flex;
  min-width: 200px;
  column-gap: 0;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  column-gap: ${base};
  row-gap: ${base};
  height: ${buttonHeight};
`;

const TextWrapper = styled(Typography)`
  width: 109px;
  color: ${grey[200]};
`;

const ContractPersonNameWrapper = styled(TextWrapper)`
  border-bottom: 1px solid ${grey[100]};
  width: fit-content;
`;

const VerticalLine = styled(Divider)`
  background-color: ${grey[400]};
  height: 64px;
  margin: 0 ${baseMD};
`;
