import { Input } from '@mui/material';
import React from 'react';
import { FileExtension } from '../types/file';
import { getFileExtensionContentType } from '../utils/fileHelper';

type Props = {
  inputFileRef: React.RefObject<HTMLInputElement>;
  onFileSelected: (selectedFile: File) => void;
  /**
   * File selector will filter to show only file with these extensions.
   * Note that user can still select file that not in one of these extensions
   */
  fileExts?: FileExtension[];
};

const FileSelector: React.FunctionComponent<Props> = ({ inputFileRef, onFileSelected, fileExts }) => {
  const getAcceptedType = (): string => {
    if (!fileExts || fileExts.length === 0) return '';

    const contentTypeList = fileExts.map(ext => getFileExtensionContentType(ext));

    return contentTypeList.join(',');
  };

  const clearInputFileRef = (): void => {
    const { current } = inputFileRef;

    if (current) current.value = '';
  };

  React.useEffect(() => {
    return () => clearInputFileRef();
  }, []);

  return (
    <Input
      type="file"
      id="filePicker"
      inputRef={inputFileRef}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files && files.length > 0) {
          onFileSelected(files[0]);
          clearInputFileRef();
        }
      }}
      inputProps={{ accept: getAcceptedType() }}
      sx={{ display: 'none' }}
    />
  );
};

export default FileSelector;
