import React from 'react';
import { ReportWrapper } from '../components';
import { useReportActions } from '../store';
import FilterOptions from './FilterOptions';
import GridTable from './GridTable';

const AuthReport: React.FunctionComponent = () => {
  const { getReportAuthLogs } = useReportActions();

  React.useEffect(() => {
    getReportAuthLogs();
  }, []);

  return (
    <ReportWrapper>
      <FilterOptions />

      <GridTable />
    </ReportWrapper>
  );
};

export default AuthReport;
