import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogProps,
  styled,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { PopupAction } from '../../types/action';
import { PrimaryButton, SecondaryButton } from '../Button';

type PopupProps = {
  title: string;
  content: string;
  closeactiontext: string;
  action: PopupAction;
} & DialogProps;

export const Popup: React.FunctionComponent<PopupProps> = (props: PopupProps) => {
  const { content, closeactiontext, title, action, onClose } = props;
  const { onAction, name } = action;

  const handleClosePopup = () => {
    onClose && onClose({}, 'escapeKeyDown');
  };

  return (
    <PopupContainer {...props}>
      <Box padding="20px">
        <Title variant="h4">
          {title}
          <IconButton onClick={handleClosePopup}>
            <CloseIcon />
          </IconButton>
        </Title>

        <Content>
          <DialogContentText variant="body1" color="common.black">
            {content}
          </DialogContentText>
        </Content>

        <DialogActions>
          <SecondaryButton width="100px" onClick={handleClosePopup} content={closeactiontext} />
          <PrimaryButton min-width="100px" onClick={onAction} content={name} />
        </DialogActions>
      </Box>
    </PopupContainer>
  );
};

const PopupContainer = styled(Dialog)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;
const Content = styled(DialogContent)`
  width: 460px;
  height: 100px;
`;
const Title = styled(DialogTitle)`
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
