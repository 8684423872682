import { styled, Typography } from '@mui/material';
import { theme } from '../../../styles/theme';

const { primary, common } = theme.palette;

export const Item = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 0 0 3%;
  width: 96%;
  line-height: 40px;
  border-radius: 2px;
  cursor: pointer;
  &.active {
    background-color: ${primary.main};
    color: ${common.white};
    font-weight: 700;
  }
`;
