import React from 'react';
import { theme } from '../../../styles/theme';
import EntradaLogoSVG from '../../../assets/image/EntradaLogo.svg';
import SideGroundImg from '../../../assets/image/loginSideGround.png';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EntradaLogo } from '../../../assets/image/Entrada.svg';
import { dimensions } from '../../../styles/dimensions';
import { Link } from 'react-router-dom';

type Props = {
  children: JSX.Element;
};

const { common, primary } = theme.palette;
const { base, base2XL, base3XL, base5XL } = dimensions;

export const MainLayout: React.FunctionComponent<Props> = (props: Props) => {
  const { children } = props;

  const { t } = useTranslation();

  return (
    <Wrapper>
      <Box height="14px" width="100%" bgcolor={`${primary.main}`} />

      <Body>
        <SideBackGround>
          <SubDescription>
            <Box component="img" src={EntradaLogoSVG} />

            <Typography variant="h3" color={primary.main}>
              {t('login.entrada_security_system')}
            </Typography>
          </SubDescription>
        </SideBackGround>

        <SideContent>
          <Content>
            <LogoLink to="/auth/login">
              <EntradaLogo />
            </LogoLink>
            {children}
          </Content>
        </SideContent>
      </Body>
    </Wrapper>
  );
};

const Body = styled(Box)`
  width: 100%;
  height: calc(100% - 14px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LogoLink = styled(Link)`
  width: 100%;
  margin: ${base5XL} 0 ${base3XL};
  display: flex;
  justify-content: center;
`;

const SideBackGround = styled(Box)`
  position: relative;
  width: 50%;
  background-image: linear-gradient(340.58deg, rgba(65, 69, 72, 0.8) 2.65%, rgba(114, 121, 128, 0.8) 89.35%),
    url(${SideGroundImg});
  background-size: cover;
`;

const SideContent = styled(Box)`
  width: 50%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  background-color: ${common.white};
`;

const SubDescription = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  column-gap: ${base};
  bottom: ${base2XL};
  left: ${base2XL};
  z-index: 2;
`;

const Content = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
