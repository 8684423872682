import { ReactNode } from 'react';

export enum SideBarItemType {
  Dashboard = '',
  User = 'user',
  Visitor = 'visitor',
  Group = 'group',
  Location = 'location',
  Report = 'report',
  Door = 'door',
  EmergencyTeam = 'emergencyTeam'
}

export type SideBarItem = {
  type: SideBarItemType;
  icon: ReactNode;
  title: string;
  linkTo: string;
};
