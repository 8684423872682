import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Fade, styled, Typography } from '@mui/material';

import { ReactComponent as Activated } from '../../../../assets/image/Users/Activated.svg';
import { ReactComponent as Edit } from '../../../../assets/image/edit.svg';

import { dimensions } from '../../../../styles/dimensions';

import {
  Content,
  ContentHeader,
  ContentWrapper,
  StandardTime,
  TemporaryAccess,
  TimeList,
  Title,
  TableWrapper
} from '../../components';

import { useUsersHook } from '../../store';

import { DoorAuthorization, UsersInGroup, VisitorsInGroup } from './Table';

import StandardTimeItem from './StandardTimeItem';

import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '../../../../components/Button';
import { GroupRouteEndpoints } from '../types';

const GroupInfo: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { id } = useParams();

  const [{ groupInformation }, { getGroupById }] = useUsersHook();

  const [loading, setLoading] = React.useState<boolean>(false);

  const getGroupInformation = async () => {
    if (id) {
      try {
        setLoading(true);

        await getGroupById(id);

        setLoading(false);
      } catch (error) {
        navigate('/not-found');
      }
    }
  };

  React.useEffect(() => {
    getGroupInformation();
  }, [id]);

  const { groupName, timeDetails, groupTmpAccessEnabled: temporaryAccess } = groupInformation;

  return (
    <ContentWrapper>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress color="primary" size={40} />
        </LoadingWrapper>
      ) : (
        <Fade in={!loading}>
          <Content>
            <ContentHeader>
              <Typography variant="h3">{groupName}</Typography>

              <SecondaryButton
                height={dimensions.buttonHeight}
                content={t('edit')}
                startIcon={<Edit />}
                onClick={() => navigate(GroupRouteEndpoints.EDIT)}
              />
            </ContentHeader>

            <StandardTime>
              <Title variant="subtitle3">{t('group.standard_time')}</Title>

              <TimeList>
                {timeDetails.map(item => (
                  <StandardTimeItem key={item.day} item={item} />
                ))}
              </TimeList>

              {temporaryAccess === '1' && (
                <TemporaryAccess>
                  <Typography>{t('group.ask_for_temporary_group_access')}</Typography>

                  <Activated />
                </TemporaryAccess>
              )}
            </StandardTime>

            <TableWrapper container spacing={3}>
              <DoorAuthorization />

              <UsersInGroup />

              <VisitorsInGroup />
            </TableWrapper>
          </Content>
        </Fade>
      )}
    </ContentWrapper>
  );
};

export default GroupInfo;

const LoadingWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
