import React from 'react';

import { Box, Grow, styled } from '@mui/material';

import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import UserSelect from './UserSelect';
import PlaceSelect from './PlaceSelect';
import { PrimaryButton } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';

const FilterOptions: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const handleCreateReport = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    //TODO: use in https://sioux.atlassian.net/browse/ECA-357
    const _data = Object.fromEntries(new FormData(e.target));
  };

  return (
    <Grow in={true}>
      <Wrapper>
        <Box
          component="form"
          width="98%"
          height="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          rowGap={baseMD}
          columnGap={base3XL}
          overflow="auto"
          onSubmit={handleCreateReport}>
          <Box minWidth="750px" width="70%" display="flex" flexDirection="row">
            <UserSelect />
            <PlaceSelect />
          </Box>

          <Box width="30%" display="flex" justifyContent="flex-end">
            <PrimaryButton content={t('report.create')} width="145px" height={dimensions.buttonHeight} type="submit" />
          </Box>
        </Box>
      </Wrapper>
    </Grow>
  );
};

export default FilterOptions;

const { baseMD, base, base3XL } = dimensions;

const Wrapper = styled(Box)`
  width: 100%;
  height: 120px;
  padding-top: ${base};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 7px ${theme.palette.grey[300]};
  overflow: auto;
`;
