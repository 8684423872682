import React from 'react';

import { theme } from '../../../styles/theme';
import { useTranslation } from 'react-i18next';
import { Box, styled, Typography } from '@mui/material';

import { ErrorPopup } from '../../../components/Popup';
import { ReactComponent as Person } from '../../../assets/image/Person.svg';

import { PrimaryButton } from '../../../components/Button';
import { dimensions } from '../../../styles/dimensions';
import { AuthError, DEFAULT_AUTH_ERROR, EMPTY_STRING, InputFieldType } from '../type';
import { useAuthAction } from '../store';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../appContext';
import { InputField, MainLayout } from '../components';
import { isEqual } from 'lodash';

const { grey } = theme.palette;
const { baseSM, base3XL, buttonHeightLarge } = dimensions;

const Request: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { check2FaEnableWithUsername } = useAuthAction();
  const { showLoading, hideLoading } = useAppContext();

  const [username, setUserName] = React.useState<string>('');
  const [error, setError] = React.useState<AuthError>(DEFAULT_AUTH_ERROR);

  const handleTwoFaValueCheck = async () => {
    showLoading();
    try {
      const data = await check2FaEnableWithUsername(username);
      hideLoading();

      if (!data) {
        setError({
          title: t('forgot_password.error.wrong_username.title'),

          content: t('forgot_password.error.wrong_username.content')
        });
        return;
      }

      const { twoFa } = data;

      if (!twoFa) {
        setError({
          title: t('forgot_password.error.feature_not_available.title'),

          content: t('forgot_password.error.feature_not_available.content')
        });
        return;
      }

      navigate('verify', { state: { username: username } });
    } catch (_err) {
      setError({
        title: t('forgot_password.error.unexpected_error.title'),

        content: t('forgot_password.error.unexpected_error.content')
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <MainLayout>
      <Content>
        <Title>
          <Typography variant="h1">{t('forgot_password.title')}</Typography>

          <Typography variant="body1" fontStyle="normal" color={grey[200]} textAlign="center">
            {t('forgot_password.content')}
          </Typography>
        </Title>

        <ActionWrapper>
          <InputField
            icon={<Person />}
            type={InputFieldType.TEXT}
            value={username}
            label={t('forgot_password.username')}
            placeholder={t('forgot_password.input_username')}
            onChange={value => setUserName(value)}
          />

          <PrimaryButton
            width="100%"
            height={buttonHeightLarge}
            onClick={handleTwoFaValueCheck}
            content={t('forgot_password.next')}
          />
        </ActionWrapper>

        <ErrorPopup
          open={!isEqual(error.title, EMPTY_STRING)}
          onClose={() => setError(DEFAULT_AUTH_ERROR)}
          title={error.title}
          content={error.content}
        />
      </Content>
    </MainLayout>
  );
};

export default Request;

const Content = styled(Box)`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Title = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: ${baseSM};
`;

const ActionWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${base3XL};
  padding-top: ${base3XL};
`;
