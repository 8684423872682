import { Box, CircularProgress, Divider, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../../../../components/Button';
import { Popup } from '../../../../components/Popup';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast/actions';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { SideBarItemType } from '../../../../types/route';
import { useCallbackPrompt } from '../../../../utils/useCallbackPrompt';
import { ContentWrapper } from '../../components/ContentWrapper';
import { LoadingWrapper } from '../../components/LoadingWrapper';
import { useUsersHook } from '../../store';
import { BLANK_STRING } from '../../types';
import { AccessInformationForm } from '../components/AccessInformationForm';
import { VisitorInformationForm } from '../components/VisitorInformationForm';
import { CheckBoxFormValue, FormActionType, isPopulateVisitorForm, validatePopulateVisitorFormData } from '../type';
import { useAppContext } from '../../../../appContext';
import moment from 'moment';
import { TimeFormatType } from '../../../../utils/date';

function convertCheckboxValue(tagActive: CheckBoxFormValue): string {
  switch (tagActive) {
    case CheckBoxFormValue.ON:
      return '1';
    case CheckBoxFormValue.OFF:
      return '0';
  }
}

export const EditVisitor: React.FunctionComponent = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();
  const { locations } = useAppContext();
  const [{ visitorInfo }, { getLocations, getAllUsers, getUserInfo, editVisitor, sendEmailToVisitor, getVisitorInfo }] =
    useUsersHook();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>(BLANK_STRING);

  const handleEditVisitor = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target));

    if (!isPopulateVisitorForm(data) || !id) return;

    setLoading(true);
    try {
      validatePopulateVisitorFormData(data);

      const { contractPersonName, email, isSendEmail, location } = data;
      const contractPersonId = contractPersonName.split('|')[1] || BLANK_STRING;
      const locationId = locations.find(item => item.location === location)?.id;

      if (!locationId) throw new Error('Location Not Found!');

      const userInfo = await getUserInfo(contractPersonId.trim());

      if (!userInfo) throw new Error('visitor.toast.failed.invalid_contract_person');

      if (isSendEmail === CheckBoxFormValue.ON) sendEmailToVisitor(email);

      await editVisitor(id, {
        email,
        contactId: Number(userInfo.id),
        startDate: `${moment(data.startDate).format(TimeFormatType.REVERSED_SHORTDATE)} ${data.startTime}:00`,
        endDate: `${moment(data.endDate).format(TimeFormatType.REVERSED_SHORTDATE)} ${data.endTime}:00`,
        locationId: `${locationId}`,
        name: data.name,
        phone: data.phone,
        pin: data.pin,
        tagActive: convertCheckboxValue(data.tagActive),
        tagNumber: data.tagNumber,
        tagText: data.tagText
      });

      showSuccessToast({
        title: t('visitor.toast.success.edit'),
        subtitle: t('visitor.toast.success.edit_subtitle')
      });
      navigate(`/users/${SideBarItemType.Visitor}/${id}`);
      confirmNavigation();
    } catch (error) {
      const { message } = error as any;
      showErrorToast({
        title: t('visitor.toast.failed.edit'),
        subtitle: t(message)
      });
    } finally {
      setLoading(false);
    }
  };

  const initVisitorInformation = async (id?: string) => {
    setLoading(true);
    id && (await getVisitorInfo(id));
    await Promise.all([getAllUsers(), getLocations()]);
    setLoading(false);
  };

  React.useLayoutEffect(() => {
    !visitorInfo && initVisitorInformation(id);
  }, []);

  return (
    <ContentWrapper>
      <Popup
        title={t('visitor.edit_visitor.popup.title')}
        content={t('visitor.edit_visitor.popup.content')}
        open={showPrompt}
        onClose={cancelNavigation}
        closeactiontext={t('logout.button.cancel')}
        action={{
          name: `${t('visitor.edit_visitor.popup.confirm_action_text')}`,
          onAction: confirmNavigation
        }}
      />

      {loading ? (
        <LoadingWrapper>
          <CircularProgress color="primary" size={40} />
        </LoadingWrapper>
      ) : (
        <Box component="form" onSubmit={handleEditVisitor}>
          <HeaderWrapper>
            <Typography variant="subtitle4" color="grey.100">
              {t('visitor.edit_visitor.page_title')}
            </Typography>
            <ButtonWrapper columnGap={base}>
              <SecondaryButton content={t('cancel')} height={buttonHeight} onClick={() => navigate(-1)} />
              <PrimaryButton content={t('visitor.edit_visitor.button.save')} height={buttonHeight} type="submit" />
            </ButtonWrapper>
          </HeaderWrapper>

          <HorizontalLine />

          <BodyWrapper>
            <VisitorInformationForm
              onEmailChange={em => setEmail(em)}
              formActionType={FormActionType.EDIT}
              setLoading={setLoading}
            />

            <AccessInformationForm email={email} formActionType={FormActionType.EDIT} />
          </BodyWrapper>
        </Box>
      )}
    </ContentWrapper>
  );
};

const { base, baseMD, baseLG, base2XL, buttonHeight } = dimensions;
const { grey } = theme.palette;

const HorizontalLine = styled(Divider)`
  margin: 0 ${baseLG};
  background-color: ${grey[400]};
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${base} ${baseLG};
  align-items: center;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BodyWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  column-gap: ${base2XL};
  padding: ${baseMD} ${baseLG};
  ${props => props.theme.breakpoints.down('xl')} {
    flex-direction: column;
    row-gap: ${baseMD};
    overflow: auto;
  }
`;
