import { Box, InputBaseProps } from '@mui/material';
import React from 'react';
import { TextInput } from '../../../../components/Input';
import { NUMERIC_REGEX } from '../../../../utils/regex';

type Props = {
  width?: string;
  height?: string;
  label?: string;
  error?: boolean;
  required?: boolean;
  defaultValue?: string;
} & InputBaseProps;

export const PhoneInputForm: React.FunctionComponent<Props> = (props: Props) => {
  const { defaultValue = '', ...rest } = props;

  const [displayedValue, setDisplayValue] = React.useState<string>(defaultValue);

  const onInputChange = (value: string) => {
    if (value === '' || NUMERIC_REGEX.test(value)) setDisplayValue(value);
  };

  return (
    <Box>
      <TextInput {...rest} value={displayedValue} onChange={e => onInputChange(e.target.value)} />
    </Box>
  );
};
