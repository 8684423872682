import { Box, InputBase, InputBaseProps, InputLabel, styled } from '@mui/material';
import React from 'react';
import { theme } from '../../styles/theme';

type Props = {
  width?: string;
  height?: string;
  label?: string;
  error?: boolean;
  required?: boolean;
  inputwidth?: string;
} & InputBaseProps;

const defaultProps: Props = {
  height: '40px',
  inputwidth: '100%'
};

export const TextInput: React.FunctionComponent<Props> = (props: Props) => {
  const { height, label, error, required, inputwidth } = props;
  return (
    <Box display="flex" height={height} width="100%" flexDirection="row" alignItems="center">
      {label && (
        <Label required={required} shrink={false}>
          {label}
        </Label>
      )}
      <InputField width={inputwidth} height="100%" className={`${error ? 'input-error' : ''}`} {...props} />
    </Box>
  );
};

TextInput.defaultProps = defaultProps;

const { typography, palette } = theme;

const Label = styled(InputLabel)`
  color: ${palette.grey[200]};
  width: 39%;
  .MuiInputLabel-asterisk {
    color: ${palette.error.main} !important;
  }
`;

const InputField = styled(InputBase)(
  ({ width, height }: { width: string | undefined; height: string | undefined }) => ({
    width: width,
    height: height,
    padding: `10px 16px`,
    variant: `${typography.body1}`,
    color: `${palette.grey[100]}`,
    border: `1px solid ${palette.grey[400]}`,
    borderRadius: `2px`,
    '&:focus-within': {
      border: `1px solid ${palette.primary.main}`
    },
    '&.Mui-disabled': {
      backgroundColor: `${palette.grey[400]}`
    },
    '&.MuiInputBase-readOnly': {
      border: `none`
    },
    '&.input-error': {
      border: `1px solid ${palette.error.main}`
    }
  })
);
