import React from 'react';

import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../styles/theme';

import { isEqual } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../appContext';
import { ErrorPopup } from '../../../components/Popup';
import { dimensions } from '../../../styles/dimensions';
import { MainLayout, PinInputGrid } from '../components';
import { useAuthAction } from '../store';
import { AuthError, CODE_LENGTH, DEFAULT_AUTH_ERROR, EMPTY_STRING } from '../type';

const { grey } = theme.palette;
const { baseSM, base3XL } = dimensions;

const DEFAULT_OTP = Array(CODE_LENGTH).fill(EMPTY_STRING);

const Verify: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { check2FaValueWithCode } = useAuthAction();
  const { showLoading, hideLoading } = useAppContext();

  const [OTP, setOTP] = React.useState<string[]>(DEFAULT_OTP);
  const [error, setError] = React.useState<AuthError>(DEFAULT_AUTH_ERROR);

  const onCodeChange = (value: string[]) => setOTP(value);

  const handleCodeVerified = async () => {
    const verifiedCode = OTP.join('');

    showLoading();

    const res = await check2FaValueWithCode(state.username, verifiedCode);

    hideLoading();

    if (res && res.twoFa) {
      navigate('/auth/forgot-password/create-new-password', { replace: true, state: { username: state.username } });
    } else {
      setError({
        title: t('forgot_password.error.invalid_code.title'),
        content: t('forgot_password.error.invalid_code.content')
      });
    }

    setOTP(DEFAULT_OTP);
  };

  React.useEffect(() => {
    if (!state) {
      navigate('/auth/login');
    }
  }, []);

  React.useEffect(() => {
    if (!OTP.includes(EMPTY_STRING)) handleCodeVerified();
  }, [OTP]);

  return (
    <MainLayout>
      <Content>
        <Box>
          <Title>
            <Typography variant="h1">{t('forgot_password.verify.content')}</Typography>

            <Typography variant="body1" fontStyle="normal" color={grey[200]} textAlign="center">
              {t('forgot_password.verify.title')}
            </Typography>
          </Title>

          <PinInputGrid value={OTP} onChange={onCodeChange} />
        </Box>

        <ErrorPopup
          open={!isEqual(error.title, EMPTY_STRING)}
          onClose={() => setError(DEFAULT_AUTH_ERROR)}
          title={error.title}
          content={error.content}
        />
      </Content>
    </MainLayout>
  );
};

export default Verify;

const Content = styled(Box)`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  row-gap: 105px;
`;

const Title = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: ${baseSM};
  margin-bottom: ${base3XL};
`;
