import { Box, CircularProgress, Fade, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../appContext';
import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import { Popup } from '../../../components/Popup';
import { showErrorToast, showSuccessToast } from '../../../components/Toast/actions';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';
import { SideBarItemType } from '../../../types/route';
import { useCallbackPrompt } from '../../../utils/useCallbackPrompt';
import { Body } from '../components/Body';
import { ContentWrapper } from '../components/ContentWrapper';
import { useLocationActions } from '../store';
import { isPopulateLocationForm, PopulateLocationForm } from '../type';

export const Edit: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { showLoading, hideLoading } = useAppContext();
  const { getLocations, editLocation, getLocationInfo } = useLocationActions();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = Object.fromEntries(new FormData(e.target));

    if (!isPopulateLocationForm(data)) return;

    const { minEmergencyTeam, emergencyTeamPersons, ...rest } = data;

    const editFields: PopulateLocationForm = {
      ...rest,
      minEmergencyTeam: minEmergencyTeam ? Number(minEmergencyTeam) : 0,
      emergencyTeamPersons: emergencyTeamPersons ? Number(emergencyTeamPersons) : 0
    };

    try {
      showLoading();
      id && (await editLocation(id, editFields));
      await getLocations();

      showSuccessToast({
        title: t('location.edit.toast.success_toast.title'),
        subtitle: t('location.edit.toast.success_toast.subtitle')
      });

      navigate(`/${SideBarItemType.Location}/${id}`);

      confirmNavigation();
    } catch (e) {
      showErrorToast({
        title: t('location.edit.toast.fail_toast.title'),
        subtitle: t('location.edit.toast.fail_toast.subtitle')
      });
    } finally {
      hideLoading();
    }
  };

  async function getLocationInformation(id: string) {
    setIsLoading(true);
    await getLocationInfo(id);
    setIsLoading(false);
  }

  useEffect(() => {
    id && getLocationInformation(id);
  }, [id]);

  return (
    <Fade in={true}>
      <ContentWrapper>
        <Popup
          title={t('location.edit.popup.title')}
          content={t('location.edit.popup.content')}
          open={showPrompt}
          onClose={cancelNavigation}
          closeactiontext={t('cancel')}
          action={{
            name: t('location.edit.popup.button'),
            onAction: confirmNavigation
          }}
        />

        {isLoading ? (
          <LoadingWrapper>
            <CircularProgress color="primary" size={40} />
          </LoadingWrapper>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <Wrapper>
              <Typography variant="subtitle4" color="grey.100" textTransform="uppercase">
                {t('location.edit.title')}
              </Typography>

              <ButtonWrapper>
                <SecondaryButton content={t('cancel')} onClick={() => navigate(-1)} />
                <PrimaryButton content={t('location.save')} type="submit" />
              </ButtonWrapper>
            </Wrapper>

            <Body isEditing={true} />
          </Box>
        )}
      </ContentWrapper>
    </Fade>
  );
};

const { base, baseLG, baseMD } = dimensions;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${baseLG};
  padding: ${baseMD} 0;
  border-bottom: 1px solid ${theme.palette.grey[400]};
`;

const ButtonWrapper = styled(Box)`
  height: initial;
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  column-gap: ${base};
`;

const LoadingWrapper = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.grey[500]};
  opacity: 70%;
  width: 100%;
`;
