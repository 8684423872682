import { Log, LogResponse, Location, ColumnSettingsResponse, LogLevel, ColumnSettingsParams } from '../../models';
import { createStore, StoreActionApi, createHook, createActionsHook } from 'react-sweet-state';
import { Column, LatestLogActionType, LogHistoryColumn, OrderBy, SettingColumnAction } from './types';
import { isEmpty } from 'lodash';
import { $get, $put } from '../../utils/http';
import { StorageKeys } from '../../types/storage';
import { formatDateTime, TimeFormatType } from '../../utils/date';

//TODO: change sound, this sound is Example
import audio from '../../assets/audio/warning_sound.mp3';
import { playAudio } from '../../utils/audio';
import { getDoorLocationIds, getLogLevel as getFilterLevel } from './utils';

type State = {
  logs: Log[];
  totalLogs: number;
  locations: Location[];
  columns: Column[];
};

const initialState: State = {
  logs: [],
  totalLogs: 0,
  locations: [],
  columns: []
};

export const actions = {
  getAllLogs:
    (order: OrderBy, take: number, fromId?: string) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      const date = formatDateTime(new Date(), TimeFormatType.REVERSED_SHORTDATE);
      const doorLocationIds = getDoorLocationIds();
      const filterLevel = getFilterLevel();

      const res: LogResponse = await $get('log/all', {
        params: { order, fromId, doorLocationIds, filterLevel, date }
      });

      const { data, total } = res;

      if (!isEmpty(data)) setState({ logs: [...getState().logs, ...(data as Log[])], totalLogs: total ? total : 0 });
    },
  getLatestLogs:
    (action: LatestLogActionType) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      const prevLogs = getState().logs;

      if (isEmpty(prevLogs)) return;

      const fromId = prevLogs[0].id;
      const doorLocationIds = getDoorLocationIds();
      const filterLevel = getFilterLevel();

      const res: LogResponse = await $get('log/latest', {
        params: { fromId, doorLocationIds, filterLevel }
      });

      const { data, total } = res;

      if (data && data.find(item => [LogLevel.HIGH, LogLevel.MEDIUM].includes(item.logLevel))) {
        playAudio(audio);
      }

      if (action === LatestLogActionType.APPEND) {
        setState({ logs: [...(data || []), ...prevLogs], totalLogs: total ? total : 0 });
      } else sessionStorage.setItem(StorageKeys.LOGS, JSON.stringify(data as Log[]));
    },
  updateLogsFromCache:
    () =>
    ({ setState, getState }: StoreActionApi<State>) => {
      const prevLogs = getState().logs;
      const storedLogs = sessionStorage.getItem(StorageKeys.LOGS);
      const cachedLogs: Log[] = storedLogs ? [...JSON.parse(storedLogs)] : [];

      if (!isEmpty(cachedLogs)) {
        setState({
          logs: [...cachedLogs, ...prevLogs]
        });

        sessionStorage.removeItem(StorageKeys.LOGS);
      }
    },

  setInitialLog:
    () =>
    ({ setState }: StoreActionApi<State>) => {
      setState({
        logs: []
      });
    },

  getColumnSetting:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      const res: ColumnSettingsResponse = await $get('log/columnSetting');

      const { data } = res;

      if (data) {
        const { settings } = data;

        if (settings) {
          const column = Object.values(LogHistoryColumn).map(item => ({
            title: item,

            display: settings[item]
          }));

          setState({ columns: column });
        }
      }
    },
  settingColumns:
    (action: SettingColumnAction, value?: Column[]) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      if (action === SettingColumnAction.EDIT) {
        setState({ columns: value });
      } else {
        const getColumnDisplay = (columnName: LogHistoryColumn) => {
          const prevCol = getState().columns;

          const col = prevCol.find(item => item.title === columnName);

          return col ? col.display : false;
        };

        const params: ColumnSettingsParams = {
          settings: {
            Type: getColumnDisplay(LogHistoryColumn.TYPE),
            Name: getColumnDisplay(LogHistoryColumn.NAME),
            Message: getColumnDisplay(LogHistoryColumn.MESSAGE),
            Door: getColumnDisplay(LogHistoryColumn.DOOR),
            DoorLocation: getColumnDisplay(LogHistoryColumn.DOOR_LOCATION),
            Room: getColumnDisplay(LogHistoryColumn.ROOM),
            CarPlate: getColumnDisplay(LogHistoryColumn.CAR_PLATE),
            Time: getColumnDisplay(LogHistoryColumn.TIME),
            Department: getColumnDisplay(LogHistoryColumn.DEPARTMENT)
          }
        };

        await $put('log/columnSetting', params);
      }
    }
};

const Store = createStore({
  initialState,
  actions
});

export const useLogHook = createHook(Store);
export const useLogActions = createActionsHook(Store);
