import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { throttle, debounce } from 'lodash';
import { autocompleteSX } from './style';
import { LazyLoadDropDownListProps } from './type';
import { DropDownListType } from '../../types/components';

type Props = Omit<LazyLoadDropDownListProps, 'type' | 'label'>;

export const LazyLoadDropDownList: React.FunctionComponent<Props> = (props: Props) => {
  const BOTTOM_DISTANCE_TO_LOAD_MORE = 15;
  const {
    width,
    height,
    defaultOption,
    loadOptions,
    loadMoreOptions,
    onChangeLastOption,
    onFilterOptions,
    onDropdownListOpen,
    onChange,
    ...others
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<DropDownListType[]>([]);
  const [option, setOption] = React.useState<DropDownListType>();

  const throttleLoadMoreOptions = throttle(loadMoreOptions, 1000, { trailing: false });
  const debounceFilter = React.useCallback(debounce(onFilterOptions, 500, { leading: false }), [options]);
  const filterOptionRef = React.useRef<string>('');

  const setInitOptions = async () => {
    const users = await loadOptions();
    setOptions(users);
  };

  const onInputOptionChange = async (filterOption: string): Promise<void> => {
    filterOptionRef.current = filterOption;
    debounceFilter(filterOption);
  };

  const onAutoCompleteOpen = async (filterOption: string) => {
    if (!filterOption) {
      filterOptionRef.current = '';
    }
    onDropdownListOpen && onDropdownListOpen(filterOption);
  };

  React.useEffect(() => {
    onChangeLastOption && onChangeLastOption(options[options.length - 1]);
    setLoading(false);
  }, [options]);

  React.useEffect(() => {
    setInitOptions();
  }, [loadOptions]);

  React.useEffect(() => {
    setOption(defaultOption);
  }, [defaultOption]);

  return (
    <Autocomplete
      disablePortal
      value={option || null}
      options={options}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={option => option.label}
      filterOptions={options => options}
      onChange={(_, option) => {
        if (onChange) onChange(option);
        if (option) setOption(option);
      }}
      onOpen={async e => {
        setLoading(true);
        await onAutoCompleteOpen((e.target as any).value);
      }}
      onClose={() => {
        setLoading(false);
      }}
      loading={loading}
      ListboxProps={{
        //There is a bug on AutoComplete which causes scroll to top when options are updated. This set is a workaround to fix this bug.
        //https://github.com/mui/material-ui/issues/30249
        role: '',
        onScroll: async (event: React.SyntheticEvent) => {
          event.stopPropagation();
          const currentTarget = event.currentTarget;
          if (
            Math.abs(currentTarget.scrollHeight - currentTarget.clientHeight - currentTarget.scrollTop) <
            BOTTOM_DISTANCE_TO_LOAD_MORE
          ) {
            await throttleLoadMoreOptions(filterOptionRef.current);
          }
        }
      }}
      sx={{ ...autocompleteSX(width, height) }}
      renderInput={params => (
        <TextField {...params} {...others} onChange={e => onInputOptionChange(e.target.value || '')} />
      )}
    />
  );
};
