import { Box, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useMatch } from 'react-router-dom';
import { theme } from '../../../styles/theme';
import { LocationRouteEndpoints } from '../../../types/locationRouteEndpoints';
import { SideBarItemType } from '../../../types/route';
import NoticeMessage from '../components/NoticeMessage';
import { useUsersStates } from '../store';
import { BLANK_STRING } from '../types';

import { AddNew } from './AddNew';
import { EditUser } from './Edit';
import { UserInfo } from './UserInfo';
import UserList from './UserList';

const User: React.FunctionComponent = () => {
  const match = useMatch(`/users/${SideBarItemType.User}/:id/*`);

  const { t } = useTranslation();

  const userId = match?.params.id;

  const { userList } = useUsersStates();

  const getUserId = (id?: string) =>
    !id || [LocationRouteEndpoints.ADD_NEW as string].includes(id) ? BLANK_STRING : id;

  return (
    <Wrapper>
      <UserList id={getUserId(userId)} />

      <Routes>
        <Route path="/add-new" element={<AddNew />} />

        <Route path="/:id" element={<UserInfo />} />

        <Route path="/:id/edit" element={<EditUser />} />

        <Route
          path="/"
          element={<NoticeMessage showBlankPage={isEmpty(userList)} message={t('user.view_details')} />}
        />
      </Routes>
    </Wrapper>
  );
};

export default User;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${theme.palette.grey[500]};
`;
