import { Box, Fade, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../appContext';
import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import { Popup } from '../../../components/Popup';
import { showErrorToast, showSuccessToast } from '../../../components/Toast/actions';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';
import { useCallbackPrompt } from '../../../utils/useCallbackPrompt';
import { Body } from '../components/Body';
import { ContentWrapper } from '../components/ContentWrapper';
import { useLocationActions } from '../store';
import { isPopulateLocationForm, PopulateLocationForm } from '../type';

export const AddNew: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useAppContext();
  const { getLocations, createLocation } = useLocationActions();

  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = Object.fromEntries(new FormData(e.target));

    if (!isPopulateLocationForm(data)) return;

    const { minEmergencyTeam, emergencyTeamPersons, ...rest } = data;

    const createFields: PopulateLocationForm = {
      ...rest,
      minEmergencyTeam: minEmergencyTeam ? Number(minEmergencyTeam) : 0,
      emergencyTeamPersons: emergencyTeamPersons ? Number(emergencyTeamPersons) : 0
    };

    try {
      showLoading();
      const newLocationId = await createLocation(createFields);
      await getLocations();

      showSuccessToast({
        title: t('location.create.success_toast.title'),
        subtitle: t('location.create.success_toast.subtitle')
      });
      navigate(`/location/${newLocationId}`);

      confirmNavigation();
    } catch (e) {
      showErrorToast({
        title: t('location.create.fail_toast.title'),
        subtitle: t('location.create.fail_toast.subtitle')
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <Fade in={true}>
      <ContentWrapper>
        <Popup
          title={t('location.popup.title')}
          content={t('location.popup.content')}
          open={showPrompt}
          onClose={cancelNavigation}
          closeactiontext={t('cancel')}
          action={{
            name: t('location.popup.button'),
            onAction: confirmNavigation
          }}
        />

        <Box component="form" onSubmit={handleSubmit}>
          <Wrapper>
            <Typography variant="subtitle4" color="grey.100" textTransform="uppercase">
              {t('location.list_header')}
            </Typography>

            <ButtonWrapper>
              <SecondaryButton content={t('cancel')} onClick={() => navigate(-1)} />
              <PrimaryButton content={t('location.save_location')} type="submit" />
            </ButtonWrapper>
          </Wrapper>

          <Body />
        </Box>
      </ContentWrapper>
    </Fade>
  );
};

const { base, baseLG, baseMD } = dimensions;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${baseLG};
  padding: ${baseMD} 0;
  border-bottom: 1px solid ${theme.palette.grey[400]};
`;

const ButtonWrapper = styled(Box)`
  height: initial;
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  column-gap: ${base};
`;
