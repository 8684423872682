import { styled, Typography } from '@mui/material';
import { ReactComponent as AddNewIcon } from '../../../assets/image/Users/AddNewIcon.svg';
import { ReactComponent as ExportIcon } from '../../../assets/image/Users/ExportIcon.svg';
import { ReactComponent as ImportIcon } from '../../../assets/image/Users/ImportIcon.svg';
import { ReactComponent as EditIcon } from '../../../assets/image/Users/EditIcon.svg';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';
import { OptionButtonType } from './type';
import { SecondaryButton } from '../SecondaryButton';

type Props = {
  type: OptionButtonType;
  content: string;
  onClick?: () => void;
  isscaledown: boolean;
};

export const OptionButton: React.FunctionComponent<Props> = (props: Props) => {
  const { type, content, onClick, isscaledown } = props;

  const renderIcon = () => {
    switch (type) {
      case OptionButtonType.ADD_NEW:
        return <AddNewIcon />;

      case OptionButtonType.EXPORT:
        return <ExportIcon />;

      case OptionButtonType.IMPORT:
        return <ImportIcon />;

      case OptionButtonType.EDIT:
        return <EditIcon />;

      default:
        return <></>;
    }
  };

  return (
    <OptionalButton onClick={onClick} isscaledown={isscaledown.toString()}>
      {renderIcon()}
      <ContentButton variant="subtitle4" isscaledown={isscaledown.toString()}>
        {content}
      </ContentButton>
    </OptionalButton>
  );
};

const { baseMD, baseXS, baseSM, buttonWidth } = dimensions;

const ContentButton = styled(Typography)(({ isscaledown }: { isscaledown: string }) => ({
  textTransform: 'initial',
  margin: `0 0 0 ${baseXS}`,

  [theme.breakpoints.down('lg')]: {
    display: isscaledown === 'true' ? 'none' : 'block'
  }
}));

const OptionalButton = styled(SecondaryButton)(({ isscaledown }: { isscaledown: string }) => ({
  minWidth: `${buttonWidth}`,
  padding: `${baseXS} ${baseSM} ${baseXS} 0`,

  [theme.breakpoints.down('lg')]: {
    padding: isscaledown === 'true' ? `0 ${baseMD}` : '0px'
  }
}));
