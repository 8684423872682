import { Box, CircularProgress, Divider, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { ContentWrapper } from '../../components/ContentWrapper';
import { useUsersHook } from '../../store';
import { Body } from './Body';
import { Header } from './Header';

export const VisitorDetails: React.FunctionComponent = () => {
  const [{ visitorList, visitorInfo }, { getVisitorInfo, getLogsOfUser }] = useUsersHook();
  const { id } = useParams();

  const [loading, showLoading] = React.useState<boolean>(false);

  const getVisitorInfoById = async (visitorId: string): Promise<void> => {
    showLoading(true);
    await Promise.all([getVisitorInfo(visitorId), getLogsOfUser(visitorId)]);
    showLoading(false);
  };

  React.useLayoutEffect(() => {
    id && getVisitorInfoById(id);
  }, [id]);

  return (
    <ContentWrapper>
      {isEmpty(visitorList) ? (
        <Box width="100%" height="100%" />
      ) : loading || !visitorInfo ? (
        <LoadingWrapper>
          <CircularProgress color="primary" size={40} />
        </LoadingWrapper>
      ) : (
        <>
          <Header />

          <HorizontalLine />

          <Body />
        </>
      )}
    </ContentWrapper>
  );
};

const { baseLG } = dimensions;
const { grey } = theme.palette;

const HorizontalLine = styled(Divider)`
  margin: 0 ${baseLG};
  background-color: ${grey[400]};
`;

const LoadingWrapper = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.grey[500]};
  opacity: 70%;
  width: 100%;
  box-shadow: ${theme.shadows[3]};
`;
