import { Box, Input, InputLabel, styled, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';

type Props = {
  defaultAvatar: string;
  name?: string;
};

export const AvatarBox: React.FunctionComponent<Props> = ({ defaultAvatar, name }) => {
  const { t } = useTranslation();

  const [avatar, setAvatar] = useState<Blob | MediaSource>();

  const handleAddAvatar = (avatar: any) => {
    const cacheFiles = avatar.target;

    if (!isEmpty(cacheFiles.files)) {
      setAvatar(cacheFiles.files[0]);
    }
  };

  return (
    <Wrapper>
      <Typography color="grey.200">{t('user.avatar')}</Typography>

      <Box display="flex" width="72.5%" columnGap={`${baseSM}`}>
        <UserImageWrapper src={avatar ? URL.createObjectURL(avatar) : defaultAvatar} />

        <ChooseAvatarLabel>
          <Typography color="primary.dark" borderBottom={`1px solid ${primary.dark}`}>
            {t('user.create.choose_avatar')}
          </Typography>

          <ImportFile type="file" name={name} onChange={handleAddAvatar} />
        </ChooseAvatarLabel>
      </Box>
    </Wrapper>
  );
};

const { baseSM } = dimensions;
const { primary } = theme.palette;

const ImportFile = styled(Input)`
  display: none;
  cursor: pointer;
`;

const ChooseAvatarLabel = styled(InputLabel)`
  cursor: pointer;
`;

const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
`;

const UserImageWrapper = styled(Box)(({ src }: { src: string | undefined }) => ({
  width: '88px',
  height: '99px',
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
}));
