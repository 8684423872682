import { styled, Box } from '@mui/material';
import { dimensions } from '../../../styles/dimensions';

export const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${dimensions.base};
`;
