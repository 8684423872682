import { Box, InputBaseProps, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../components/Input';
import { theme } from '../../../../styles/theme';
import { EMAIL_REGEX } from '../../../../utils/regex';

type Props = {
  width?: string;
  height?: string;
  label?: string;
  error?: boolean;
  required?: boolean;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
} & InputBaseProps;

export const EmailInputForm: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { defaultValue = '', error = false, onChange, ...rest } = props;

  const [displayedValue, setDisplayValue] = React.useState<string>(defaultValue);
  const [isError, setIsError] = React.useState<boolean>(error);
  const [isBlur, setIsBlur] = React.useState<boolean>(false);
  const isValidText = (value: string) => {
    return !value || EMAIL_REGEX.test(value);
  };

  return (
    <Box>
      <TextInput
        {...rest}
        value={displayedValue}
        onFocus={() => {
          setIsError(false);
          setIsBlur(false);
        }}
        onBlur={e => {
          setIsError(!isValidText(e.target.value));
          setIsBlur(true);
        }}
        onChange={e => {
          onChange && onChange(e);
          setDisplayValue(e.target.value);
        }}
        error={isError}
      />
      {isBlur && !isValidText(displayedValue) && (
        <Typography variant="body2" color={theme.palette.error.main} marginLeft="35%">
          {t('error_message.invalid_email_format')}
        </Typography>
      )}
    </Box>
  );
};
