import { styled, Typography } from '@mui/material';
import { ContentWrapper } from './ContentWrapper';

type Props = {
  message: string;
  showBlankPage?: boolean;
};

const NoticeMessage: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <MessageWrapper>
      {!props.showBlankPage && (
        <Typography variant="h2" color="grey.300">
          {props.message}
        </Typography>
      )}
    </MessageWrapper>
  );
};

export default NoticeMessage;

const MessageWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
