import { Box, CircularProgress, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from '../../../../styles/theme';
import { ContentWrapper } from '../../components/ContentWrapper';
import { useUsersHook } from '../../store';
import { HeaderUserInformation } from './HeaderUserInformation';
import { UserInformation } from './UserInformation';

export const UserInfo: React.FunctionComponent = () => {
  const [{ userList }, { getUserInfo, getFreeTextLabel }] = useUsersHook();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { id } = useParams();

  async function getUserInformation(id: string) {
    setIsLoading(true);
    await getUserInfo(id);
    await getFreeTextLabel();
    setIsLoading(false);
  }

  useEffect(() => {
    id && getUserInformation(id);
  }, [id]);

  return (
    <ContentWrapper>
      {isEmpty(userList) ? (
        <Box width="100%" height="100%" />
      ) : isLoading ? (
        <LoadingWrapper>
          <CircularProgress color="primary" size={40} />
        </LoadingWrapper>
      ) : (
        <>
          <HeaderUserInformation />

          <UserInformation />
        </>
      )}
    </ContentWrapper>
  );
};

const LoadingWrapper = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.grey[500]};
  opacity: 70%;
  width: 100%;
  box-shadow: ${theme.shadows[3]};
`;
