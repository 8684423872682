export interface IUser {
  name: string;
}

export class User implements IUser {
  constructor(name: string) {
    this.name = name;
  }
  public name: string;
}

export type UserShortInfo = {
  id: string;
  userId: string;
  name: string;
  location: string;
};

type BasicInfo = {
  id: string;
  name: string;
  pin: string;
  departement: string;
  locationName: string;
  comment: string;
  phone: string;
  employeeNr: string;
  tagText: string;
  email: string;
  tagNumber: string;
  tagActive: string;
  freeTextText1: string;
  freeTextText2: string;
  plate: string;
  vehicle: string;
  emergency: string;
  endDate: string;
  startDate: string;
};

export type UserInfo = BasicInfo & {
  roomNumber: string;
  avatar: string;
  client: string;
  groupNames: GroupNames[];
};

export type GroupNames = {
  groupID: string;
  groupName: string;
  groupLocation: string;
};

export type UserLog = {
  time: string;
  doorName: string;
  doorLocation: string;
  message: string;
};

export type UpdateFields = BasicInfo & {
  // will be enable after API update was fixed
  // GroupName: string;
};

export type UserField = {
  name: string;
  emergency: string;
  avatar: string;
  employeeNr: string;
  locationId: string;
  departement: string;
  phone: string;
  email: string;
  tagNumber: string;
  pin: string;
  tagActive: string;
  tagText: string;
  plate: string;
  vehicle: string;
  startDate: string;
  endDate: string;
  freeTextText1: string;
  freeTextText2: string;
  comment: string;
  groupIds: string;
};

export type VisitorInfo = Omit<UserInfo, 'id'> & {
  visitorID: string;
  isSendEmail: string;
  company: string;
  startDate: string;
  endDate: string;
  visitor: ContractPerson;
  location: string;
};

export type ContractPerson = {
  contactId: string;
  contactName: string;
  contactMail: string;
  contactAvatar: string;
  departement: string;
  phone: string;
};

// TODO: this is temp defined type, changed will be made on task integrate API
export type VisitorShortInfoResponse = {
  data: {
    users: {
      id: string;
      userId: string;
      name: string;
      location: string;
    }[];
  };
  code: string;
  message: string;
  total: string;
};

export type VisitorShortInfo = {
  id: string;
  visitorId: string;
  name: string;
};

export type FreeTextLabel = {
  freeForm1: string;
  freeForm2: string;
  comment: string;
};

export type FreeTextLabelResponse = {
  data: FreeTextLabel;
  code: string;
  message: string;
};

export type AddNewUserResponse = {
  code: string;
  data: { success: boolean; id: string };
  message: string;
};

export type AddNewVisitorResponse = {
  code: string;
  data: { success: boolean; id: string };
  message: string;
};
