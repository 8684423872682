import { Box, styled } from '@mui/material';
import { dimensions } from '../styles/dimensions';
import { theme } from '../styles/theme';

export type ContextMenuItem = {
  name: string;
  onClick?: () => void;
};

type Props = {
  show: boolean;
  menuItems: ContextMenuItem[];
  menuPosition: {
    x: number;
    y: number;
  };
};

type MenuItemProps = {
  isLastItem?: boolean;
};

type MenuContainerProps = {
  display: string;
  top: number;
  left: number;
};

const ContextMenu: React.FunctionComponent<Props> = ({ menuItems, show, menuPosition }) => {
  const isLastItem = (index: number): boolean => {
    return menuItems.length - 1 === index;
  };

  const display = show ? 'block' : 'none';

  return (
    <MenuContainer
      top={menuPosition.y}
      left={menuPosition.x}
      display={display}
      onContextMenu={e => {
        e.preventDefault();
      }}>
      {menuItems.map((item, index) => (
        <MenuItem key={index} onClick={item.onClick} isLastItem={isLastItem(index)}>
          <TextWrapper>{item.name}</TextWrapper>
        </MenuItem>
      ))}
    </MenuContainer>
  );
};

export default ContextMenu;

const {
  palette: { grey, common, primary },
  shadows
} = theme;

const { baseXS, baseMD } = dimensions;

const MenuContainer = styled(Box)<MenuContainerProps>(({ top, left, display }) => ({
  borderRadius: `${baseXS}`,
  width: 154,
  boxShadow: `${shadows[3]}`,
  position: 'absolute',
  top,
  left,
  backgroundColor: `${common.white}`,
  display
}));

const MenuItem = styled(Box)<MenuItemProps>(({ isLastItem }) => ({
  height: 38,
  width: 154,
  fontSize: 14,
  display: 'flex',
  alignItems: 'center',
  borderBottom: isLastItem ? 'unset' : `1px solid ${grey[400]}`
}));

const TextWrapper = styled(Box)`
  cursor: pointer;
  margin-left: ${baseMD};
  &:hover {
    color: ${primary.main};
  }
`;
