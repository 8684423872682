import { EmergencyInfo } from '../../models/emergency';
import _ from 'lodash';

export const combineMemberInMultipleLocation = (emergencyList: EmergencyInfo[]) => {
  const combineTeamMemberList: EmergencyInfo[] = [];
  const memberIds = _.uniq(emergencyList.map(item => item.id));

  memberIds.forEach(id => {
    const memberInfos = emergencyList.filter(item => item.id === id);

    if (memberInfos.length > 0) {
      const member = { ...memberInfos[0] };
      const locations = memberInfos.map(item => item.location).join(',');
      const locationIds = memberInfos.map(item => item.locationId).join(',');
      member.location = locations;
      member.locationId = locationIds;

      combineTeamMemberList.push(member);
    }
  });

  return combineTeamMemberList;
};
