import { FormControl, MenuItem, Select, styled, SelectProps } from '@mui/material';
import React from 'react';
import { dimensions } from '../../../../styles/dimensions';

export type SelectBoxType = {
  id: string | number;
  value: string | number;
};

type SelectBoxProps = {
  width?: string;
  /*listItem:  before passing your array, convert it to SelectBoxType[] type**/
  listItem?: SelectBoxType[];
  defaultValue?: string;
} & SelectProps;

export const SelectBox: React.FunctionComponent<SelectBoxProps> = (props: SelectBoxProps) => {
  const { width, listItem, label, name, defaultValue = '', ...others } = props;

  const [selected, setSelected] = React.useState<string>(defaultValue);

  return (
    <FormSelectBox width={width} size="small">
      <StyledSelect
        value={selected}
        label={label}
        size="small"
        onChange={event => {
          setSelected(event.target.value as string);
        }}
        name={name}
        {...others}>
        {listItem &&
          listItem.map(item => (
            <MenuItem key={item.id} value={item.value}>
              {item.value}
            </MenuItem>
          ))}
      </StyledSelect>
    </FormSelectBox>
  );
};

const { inputHeight, base } = dimensions;

const FormSelectBox = styled(FormControl)(({ width }: { width: string | undefined }) => ({
  minWidth: width ? width : 'initial'
}));

const StyledSelect = styled(Select)`
  & .MuiInputBase-input {
    padding: ${base};
  }
  height: ${inputHeight};
`;
