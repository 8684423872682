import { StorageKeys } from '../../types/storage';
import { $get, $post } from '../../utils/http';
import _ from 'lodash';
import { AuthResponse, LoginInfo, LoginResponse, TwoFaValueResponse } from '../../models';
import { StoreActionApi, createStore, createActionsHook, createHook } from 'react-sweet-state';
import { clearCookie, setCookie } from '../../utils/cookie';

type State = {
  authentication: LoginResponse | null;
  username: string | null;
  password: string | null;
};

const initialState: State = {
  authentication: null,
  username: null,
  password: null
};

export const actions = {
  login:
    (username: string, password: string) =>
    async ({ setState }: StoreActionApi<State>) => {
      setState({ username: username, password: password });

      const loginValue: LoginInfo = { username: username, password: password };
      const response: AuthResponse = await $post('login/', loginValue);

      const { data } = response;

      if (data) {
        const res = data as LoginResponse;
        const { authorization, refreshToken } = res;

        if (refreshToken) {
          setCookie(StorageKeys.REFRESH_TOKEN, refreshToken, 10);
        }

        authorization && localStorage.setItem(StorageKeys.TOKEN, authorization);
        setState({ authentication: res });
      }

      return data as LoginResponse;
    },

  logout:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      await $get('logout/');

      localStorage.clear();
      sessionStorage.clear();
      clearCookie(StorageKeys.REFRESH_TOKEN);

      setState(initialState);
    },

  check2FaEnableWithUsername: (username: string) => async () => {
    const twoFaValueCheck: AuthResponse = await $get('login/checkTwoFaEnable', { params: { username } });

    return twoFaValueCheck.data as TwoFaValueResponse;
  },

  check2FaValueWithCode: (username: string, code: string) => async () => {
    const twoFaValueCheck: AuthResponse = await $get('password/checkTwoFaValue', {
      params: { username, '2fa': code }
    });

    return twoFaValueCheck.data as TwoFaValueResponse;
  },

  changePassword: (username: string, newPassword: string) => async (): Promise<string> => {
    const res: AuthResponse = await $post('password/createNewPassword', {
      username: username,
      newPassword: newPassword
    });

    return res.code;
  },

  verifyTOTP:
    (OTP: string) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      const loginValue = { username: getState().username, password: getState().password, '2fa': OTP };

      const response: AuthResponse = await $post('login/', loginValue);

      const { data } = response;

      if (data) {
        const res = data as LoginResponse;
        const { authorization } = res;

        authorization && localStorage.setItem(StorageKeys.TOKEN, authorization);
        setState({ authentication: res });
      }
    }
};

const Store = createStore({
  initialState,
  actions
});

export const useAuthHook = createHook(Store);
export const useAuthAction = createActionsHook(Store);
