import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUsersStates } from '../../../store';
import GridTable from './GridTable';

export const DoorAuthorization: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { groupInformation } = useUsersStates();

  const titles = [
    t('group.door_authorization.column.id'),
    t('group.door_authorization.column.location'),
    t('group.door_authorization.column.door'),
    t('group.door_authorization.column.module')
  ];
  const doors = groupInformation.objectsEnabled ?? [];
  return (
    <GridTable
      header={t('group.door_authorization.content')}
      titles={titles}
      rows={doors.map(it => ({
        doorId: it.doorId,
        doorLocation: it.doorLocation,
        doorName: it.doorName,
        moduleName: it.moduleName
      }))}
    />
  );
};
