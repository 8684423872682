import { Box, styled } from '@mui/material';
import { theme } from '../../../styles/theme';

export const ContentWrapper = styled(Box)`
  height: 100%;
  overflow-y: auto;
  width: calc(100% - 300px);
  box-shadow: ${theme.shadows[3]};
  background-color: ${theme.palette.common.white};
  ${props => props.theme.breakpoints.down('lg')} {
    width: calc(100% - 200px);
  }
`;
