import { theme } from '../../styles/theme';

export const autocompleteSX = (width?: string, height?: string) => ({
  width,
  height,
  '& .MuiOutlinedInput-root': {
    width,
    height,
    borderRadius: '2px',
    paddingTop: '0',
    paddingBottom: '0',
    '& fieldset': {
      borderColor: `${grey[400]}`
    },
    '&:hover fieldset': {
      borderColor: `${grey[400]}`
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${main}`
    }
  }
});

const {
  palette: {
    grey,
    primary: { main }
  }
} = theme;
