import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from '../../../../components/Button';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';

type Props = {
  headerTitle: string;
  actionTitle: string;
  onClickCancel: () => void;
};

export const HeaderBar: React.FunctionComponent<Props> = ({ headerTitle, actionTitle, onClickCancel }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Typography variant="subtitle4" color="grey.100" textTransform="uppercase">
        {headerTitle}
      </Typography>

      <ButtonWrapper>
        <SecondaryButton content={t('user.cancel')} onClick={onClickCancel} />
        <PrimaryButton content={actionTitle} type="submit" />
      </ButtonWrapper>
    </Wrapper>
  );
};

const { base, baseMD, baseLG } = dimensions;
const { grey } = theme.palette;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${grey[400]};
  margin: ${baseMD} ${baseLG} 0;
  padding-bottom: ${baseMD};
`;

const ButtonWrapper = styled(Box)`
  height: initial;
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  column-gap: ${base};
`;
