import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxInput } from '../../../../components/Input';
import { SelectBoxWithLabel } from '../../components/SelectBox';
import { selectBoxData } from '../../data';
import { FilterOptionsWrapper } from '../../components';
import { ReportLogFormField } from '../../types';

const { SELECT_USER_IDS: SELECT_USER, SEARCH_WARNINGS, SEARCH_EMERGENCY_TEAM } = ReportLogFormField;

const CHECKBOX_INPUT_MIN_WIDTH = '300px';
const CHECKBOX_INPUT_LABEL_WIDTH = '160px';

const UserSelect: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <FilterOptionsWrapper width="30%" minWidth="350px">
      <SelectBoxWithLabel
        // TODO: Change in in https://sioux.atlassian.net/browse/ECA-357
        listItem={selectBoxData}
        name={SELECT_USER}
        minWidth="200px"
        label={t('report.log_history.filter_option.select_user')}
        selectBoxWidth="54%"
        labelWidth="170px"
      />

      <CheckboxInput
        variant="body2"
        name={SEARCH_WARNINGS}
        label={t('report.log_history.filter_option.search_warnings')}
        minWidth={CHECKBOX_INPUT_MIN_WIDTH}
        labelWidth={CHECKBOX_INPUT_LABEL_WIDTH}
      />

      <CheckboxInput
        variant="body2"
        name={SEARCH_EMERGENCY_TEAM}
        label={t('report.log_history.filter_option.search_emergency_team')}
        minWidth={CHECKBOX_INPUT_MIN_WIDTH}
        labelWidth={CHECKBOX_INPUT_LABEL_WIDTH}
      />
    </FilterOptionsWrapper>
  );
};

export default UserSelect;
