import { DoorAuthorization, StandardTime, UserInGroup, VisitorInGroup } from '../../../../models';
import { DayOfWeek } from '../../../../types';
import { BLANK_STRING, EMPTY_VALUE } from '../../types';

enum TemporaryAccess {
  YES = 'YES',
  NO = 'NO'
}

type Time = {
  day: string;
  time: string;
};

const getTime = (day: DayOfWeek, times: Time[]) => {
  const foundTime = times.find(item => item.day === day);

  return foundTime ? foundTime.time : BLANK_STRING;
};

export const exportGroupConverter = (data: { [key: string]: any }): { [key: string]: string } => {
  const { doorAuthorization, timeDetails, usersInGroup, visitorsInGroup, temporaryAccess, id: _id, ...rest } = data;

  const doors = doorAuthorization.map((item: DoorAuthorization) => item.doorName).join(';');
  const users = usersInGroup.map((item: UserInGroup) => item.userName).join(';');
  const visitors = visitorsInGroup.map((item: VisitorInGroup) => item.userName).join(';');
  const times: Time[] = timeDetails.map((item: StandardTime) => ({
    day: item.day,
    time: item.startTime + EMPTY_VALUE + item.endTime
  }));

  return {
    ...rest,
    doorAuthorization: doors,
    usersInGroup: users,
    visitorsInGroup: visitors,
    temporaryAccess: temporaryAccess ? TemporaryAccess.YES : TemporaryAccess.NO,
    monday: getTime(DayOfWeek.MONDAY, times),
    tuesday: getTime(DayOfWeek.TUESDAY, times),
    wednesday: getTime(DayOfWeek.WEDNESDAY, times),
    thursday: getTime(DayOfWeek.THURSDAY, times),
    friday: getTime(DayOfWeek.FRIDAY, times),
    saturday: getTime(DayOfWeek.SATURDAY, times),
    sunday: getTime(DayOfWeek.SUNDAY, times)
  };
};
