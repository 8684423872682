import { Box, styled } from '@mui/material';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';

const { baseMD, baseXS, baseSM, baseLG } = dimensions;

export const ListWrapper = styled(Box)`
  width: 300px;
  padding: ${baseSM} ${baseLG};
  border-radius: ${baseXS};
  height: calc(100% - ${baseMD});
  box-shadow: ${theme.shadows[2]};
  background-color: ${theme.palette.grey[500]};

  ${props => props.theme.breakpoints.down('lg')} {
    width: 200px;
  }
`;
