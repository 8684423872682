/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, InputLabel, styled, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react';
import { dimensions } from '../../styles/dimensions';
import { theme } from '../../styles/theme';
import { formatDateTime, TimeFormatType } from '../../utils/date';

import { ReactComponent as Calendar } from '../../assets/image/Calendar.svg';

type Props = {
  label?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  name?: string;
  required?: boolean;
  labelWidth?: string;
  inputWidth?: string;
  width?: string;
  minDate?: Date;
};

export const DateInput: React.FunctionComponent<Props> = (props: Props) => {
  const { label, defaultValue = '', labelWidth, minDate, inputWidth, width = '100%', name, onChange, required } = props;

  const [date, setDate] = useState<string>(defaultValue);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" width={width}>
      {label && (
        <Label style={{ minWidth: labelWidth }} required>
          {label}
        </Label>
      )}

      <StyledDatePicker
        className={name}
        minDate={minDate}
        width={inputWidth}
        inputFormat={TimeFormatType.MONTH_DATE_YEAR}
        value={formatDateTime(new Date(date), TimeFormatType.MONTH_DATE_YEAR)}
        onChange={(newValue: any) => {
          setDate(formatDateTime(new Date(newValue), TimeFormatType.MONTH_DATE_YEAR));
          onChange && onChange(formatDateTime(new Date(newValue), TimeFormatType.MONTH_DATE_YEAR));
        }}
        renderInput={params => (
          <TextField size="small" {...params} name={name} required={required} onKeyDown={e => e.preventDefault()} />
        )}
        InputProps={{
          style: {
            height: inputHeight
          }
        }}
        components={{
          OpenPickerIcon: Calendar
        }}
      />
    </Box>
  );
};

DateInput.defaultProps = {
  defaultValue: '',
  minDate: new Date(),
  labelWidth: '30%'
};

const { grey, common, error } = theme.palette;
const { inputHeight, base } = dimensions;

const StyledDatePicker = styled(DatePicker)(({ width }: { width?: string }) => ({
  width: width,
  caretColor: common.white,
  cursor: 'pointer',

  '& fieldset.MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px !important'
  },

  'input.MuiInputBase-input': {
    padding: `0 ${base}`
  }
}));

const Label = styled(InputLabel)`
  color: ${grey[200]};
  .MuiInputLabel-asterisk {
    color: ${error.main} !important;
  }
`;
