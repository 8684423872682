import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ChangePassword from './ChangePassword';

import Request from './Request';
import Verify from './Verify';

const ForgotPassword: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="" replace />} />
      <Route path="" element={<Request />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/create-new-password" element={<ChangePassword />} />
    </Routes>
  );
};

export default ForgotPassword;
