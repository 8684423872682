import React from 'react';

import { theme } from '../../../../styles/theme';
import { dimensions } from '../../../../styles/dimensions';
import {
  TableHead,
  styled,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Table,
  Box,
  Fade
} from '@mui/material';

import BodyTableCell from './BodyTableCell';

import { SecondaryButton } from '../../../../components/Button';
import { SideBarItemType } from '../../../../types/route';
import { useTranslation } from 'react-i18next';
import { HIDE_COLUMN_CLASS_NAME } from '../../../../types/table';
import { UserIcon } from '../../../../models';
import { useReportStates } from '../../store';
import { AuthLogColumn } from '../../types';

const getRouteDomainByUserIcon = (userIcon: UserIcon, userId: string | null) => {
  if (!userId) return;

  switch (userIcon) {
    case UserIcon.EMPLOYEE:
      return `/users/${SideBarItemType.User}/${userId}`;
    case UserIcon.VISITOR:
      return `/users/${SideBarItemType.Visitor}/${userId}`;

    default:
      return;
  }
};

const GridTable: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { authLogs } = useReportStates();

  return (
    <Transition in={true}>
      <Wrapper>
        <Header>
          <Typography variant="subtitle1" color="grey.100">
            {t('report.content')}
          </Typography>

          <SecondaryButton content={t('report.export')} height={buttonHeight} />
        </Header>
        <Container>
          <StyledTable stickyHeader>
            <TableHeader>
              <TableRow>
                {Object.values(AuthLogColumn).map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant="subtitle2" color="grey.100">
                      {item}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHeader>
            <Body>
              {authLogs.map(auth => (
                <Row key={auth.authId}>
                  <BodyTableCell
                    value={auth.name}
                    width="120px"
                    link={getRouteDomainByUserIcon(auth.userIcon, auth.userId)}
                  />

                  <BodyTableCell value={auth.userLocation} width="100px" />

                  <BodyTableCell value={auth.door} width="100px" />

                  <BodyTableCell value={auth.doorLocation} width="100px" />

                  <BodyTableCell value={auth.groupName} width="100px" />

                  <BodyTableCell value={auth.monday} />

                  <BodyTableCell value={auth.tuesday} />

                  <BodyTableCell value={auth.wednesday} />

                  <BodyTableCell value={auth.thursday} />

                  <BodyTableCell value={auth.friday} />

                  <BodyTableCell value={auth.saturday} />

                  <BodyTableCell value={auth.sunday} />
                </Row>
              ))}
            </Body>
          </StyledTable>
        </Container>
      </Wrapper>
    </Transition>
  );
};

export default GridTable;

const { common, grey, action } = theme.palette;
const { inputHeight, buttonHeight } = dimensions;

const Transition = styled(Fade)`
  transition-delay: 200ms;
`;

const Wrapper = styled(Box)`
  display: flex;
  height: calc(100% - 120px);
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
`;

const Header = styled(Box)`
  height: 56px;
  width: 98%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TableHeader = styled(TableHead)`
  height: ${inputHeight};
  background: ${common.white};
  border: 1px solid ${grey[400]};
  font-size: 16px;
  font-weight: 700;
`;

const Container = styled(TableContainer)`
  height: calc(100% - 47px);
  overflow: auto;
  border-top: 1px solid ${grey[400]};
`;

const Row = styled(TableRow)`
  position: relative;
  background-color: ${common.white};

  &:nth-of-type(odd) {
    background-color: ${action.hover};
  }

  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;

const Body = styled(TableBody)`
  position: relative;
  overflow: auto;
`;

const StyledTable = styled(Table)`
  tr > .${HIDE_COLUMN_CLASS_NAME} {
    display: none;
  }
`;
