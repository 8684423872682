export enum HttpStatusCode {
  OK = '0000',
  NoContent = '0001',
  InvalidEmail = 'A001',
  UserDuplicated = 'A002',
  UserNotFound = 'A003',
  WrongPassword = 'A004',
  PermissionDenied = 'A005',
  AlreadyLoggedIn = 'A006',
  LoginExpired = 'A007',
  TokenExpired = 'A009',
  TokenInvalid = 'A010',
  UserInactive = 'A015',
  UnsupportedParam = 'S006',
  UnsupportedBody = 'S007',
  TwoFaNotCorrect = 'S008'
}
