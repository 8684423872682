import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

import { theme } from '../styles/theme';
type Props = {
  size: number;
  width: string;
  height: string;
  position?: 'static' | 'absolute' | 'fixed' | 'relative' | 'sticky' | 'initial' | 'inherit';
};

export const LoadingIndicator: React.FunctionComponent<Props> = (props: Props) => {
  const { size, width, height, position = 'absolute' } = props;
  return (
    <Wrapper width={width} height={height} position={position}>
      <CircularProgress color="primary" size={size} />
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.grey[500]};
  opacity: 70%;
  z-index: 9999;
`;
