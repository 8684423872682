import { Navigate, Route, Routes } from 'react-router-dom';
import CreateAuthAccount from '../components/CreateAuthAccount';
import VerifyTimeBasedOTP from './VerifyTimeBasedOTP';

const SecondAuth: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/add-account/:code" element={<CreateAuthAccount isForgotPasswordMode={false} />} />
      <Route path="/verify/:code" element={<VerifyTimeBasedOTP />} />
      <Route path="/*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};

export default SecondAuth;
