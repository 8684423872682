import moment from 'moment';

export enum TimeFormatType {
  SHORTDATE = 'DD-MM-yyyy',
  LONGDATETIME = 'DD-MM-yyyy h:mm:ss',
  LONGDATETIME_V2 = 'yyyy-MM-DD HH:mm:ss',
  REVERSED_SHORTDATE = 'yyyy-MM-DD',
  HOUR_MINUTE_SECOND = 'HH:mm:ss',
  HOUR_MINUTE = 'HH:mm',
  FILENAME = 'DD-MM-yyyy_HH[h]-mm[m]-ss[s]',
  MONTH_DATE_YEAR = 'MM-DD-YYYY',
  FILE_DDMMYYY = 'DDMMYYYY',
  FILE_HHMM = 'HHmm'
}

export const formatDateTime = (date: Date, type: TimeFormatType) => {
  return moment(date).format(type);
};

export const addOneYear = (date: Date) => {
  date.setFullYear(date.getFullYear() + 1);
  return date;
};

export const isStartDateBeforeOrEqualEndDate = (startDate: Date, endDate: Date): boolean => {
  return moment(startDate).isSameOrBefore(endDate);
};

export const isOneDate = (startDate: Date, endDate: Date): boolean => {
  return moment(startDate).isSame(endDate);
};
