import { Box, styled } from '@mui/material';
import { dimensions } from '../../../../../styles/dimensions';
import { useUsersStates } from '../../../store';
import { AccessInformation } from './AccessInformation';
import { HistoryToday } from './HistoryToday';
import { VisitorInformation } from './VisitorInformation';

export const Body: React.FunctionComponent = () => {
  const { visitorInfo, userLogs } = useUsersStates();

  if (!visitorInfo) return <></>;

  const { email, location, company, plate, vehicle, startDate, endDate, tagActive, tagNumber, tagText, pin } =
    visitorInfo;

  const visitorInformation = {
    location,
    company,
    plate,
    vehicle
  };

  const accessInformation = {
    startDate,
    startTime: startDate,
    endDate,
    tagActive,
    tagNumber,
    tagText,
    pin,
    email
  };

  const historyLogs = userLogs
    ? userLogs.map(log => {
        const { time: dateTimeLog, ...rest } = log;
        return {
          time: dateTimeLog,
          ...rest
        };
      })
    : [];

  return (
    <Wrapper>
      <InformationWrapper>
        <Box display="flex" flexDirection="column" rowGap={baseMD}>
          <VisitorInformation visitorInfo={visitorInformation} />

          <AccessInformation accessInfo={accessInformation} />
        </Box>
      </InformationWrapper>

      <HistoryToday historyInfoList={historyLogs} />
    </Wrapper>
  );
};
const { base, baseMD, baseLG, baseSM } = dimensions;

const Wrapper = styled(Box)`
  height: calc(100% - 160px);
  padding: ${baseLG} ${baseLG} 0;
  display: flex;
  flex-direction: row;
  column-gap: ${base};
  justify-content: space-between;
  overflow-y: auto;
  ${props => props.theme.breakpoints.down('xl')} {
    flex-direction: column;
  }
`;

const InformationWrapper = styled(Box)`
  display: flex;
  flex: 1.2;
  flex-direction: column;
  height: 71.5vh;
  row-gap: ${baseSM};
  padding-top: ${baseMD};
  overflow-y: auto;
  ${props => props.theme.breakpoints.down('xl')} {
    overflow-y: visible;
    margin-bottom: 300px;
  }
`;
