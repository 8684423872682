import React from 'react';

import {
  DialogProps,
  Box,
  IconButton,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@mui/material';

import styled from '@emotion/styled';

import { PrimaryButton } from '../Button';

import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

type ErrorPopupProps = {
  title: string;
  content: string;
} & DialogProps;

export const ErrorPopup: React.FunctionComponent<ErrorPopupProps> = (props: ErrorPopupProps) => {
  const { content, title, onClose } = props;
  const { t } = useTranslation();

  const handleClosePopup = onClose as VoidFunction;

  return (
    <PopupContainer {...props}>
      <Box padding="20px">
        <Title variant="h4" color="error">
          {title}
          <IconButton onClick={handleClosePopup}>
            <CloseIcon />
          </IconButton>
        </Title>
        <Content>
          <DialogContentText variant="body1" color="common.black">
            {content}
          </DialogContentText>
        </Content>
        <DialogActions>
          <PrimaryButton width="100px" onClick={handleClosePopup} content={t('got_it')} />
        </DialogActions>
      </Box>
    </PopupContainer>
  );
};

const PopupContainer = styled(Dialog)`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Content = styled(DialogContent)`
  width: 460px;
  height: 100px;
`;
const Title = styled(DialogTitle)`
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
