import { Box, styled, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../appContext';
import { ErrorPopup } from '../../../components/Popup/ErrorPopup';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';
import { MainLayout, PinInputGrid } from '../components';
import { useAuthAction } from '../store';
import { EMPTY_STRING } from '../type';

const { baseSM, base5XL } = dimensions;
const { primary, common } = theme.palette;

const VerifyTimeBasedOTP: React.FunctionComponent = () => {
  const { verifyTOTP } = useAuthAction();
  const { showLoading, hideLoading } = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const QRImageEncode = location.pathname.split('/').at(-1);

  const initialTOTP = new Array(6).fill(EMPTY_STRING);

  const [error, setError] = useState<boolean>(false);
  const [timeBasedOTP, setTimeBasedOTP] = useState<string[]>(initialTOTP);
  const [onFocus, setOnFocus] = useState<boolean>(true);

  const onCodeChange = async (value: string[]) => {
    setTimeBasedOTP(value);
  };

  async function checkTimeBasedOTP(value: string) {
    try {
      setOnFocus(false);
      await verifyTOTP(value);
      navigate('/');
    } catch (e) {
      setOnFocus(true);
      setTimeBasedOTP(initialTOTP);
      setError(true);
    }
    hideLoading();
  }

  useEffect(() => {
    const isFinished = timeBasedOTP.includes('');

    if (!isFinished) {
      showLoading();
      checkTimeBasedOTP(timeBasedOTP.join(''));
    }
  }, [timeBasedOTP]);

  return (
    <MainLayout>
      <SideAuth>
        <Typography color="grey.100" variant="h1" align="center">
          {t('two_fa.verify')}
        </Typography>

        <Typography variant="body1" color="grey.200">
          {t('two_fa.enter_otp')}
        </Typography>

        <Box display="flex" flexDirection="row" columnGap={`${baseSM}`} margin={`${base5XL} 0`}>
          <PinInputGrid value={timeBasedOTP} onChange={onCodeChange} onFocus={onFocus} />
        </Box>

        <LoginLink to={`/auth/second-auth/add-account/${QRImageEncode}`}>
          <Typography color="primary.dark" borderBottom={`1px solid ${primary.dark}`}>
            {t('two_fa.add_account')}
          </Typography>
        </LoginLink>

        <ErrorPopup
          open={error}
          onClose={() => setError(false)}
          title={t('two_fa.error.title')}
          content={t('two_fa.error.content')}
        />
      </SideAuth>
    </MainLayout>
  );
};

export default VerifyTimeBasedOTP;

const LoginLink = styled(Link)`
  text-decoration: none;
`;

const SideAuth = styled(Box)`
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${common.white};
`;
