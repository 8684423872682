import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxInput, DateInput } from '../../../../components/Input';
import { formatDateTime, TimeFormatType } from '../../../../utils/date';
import { FilterOptionsWrapper } from '../../components';
import { ReportLogFormField } from '../../types';

const { START_DATE, END_DATE, PRESENCE_LIST, PRESENCE_TIME_REPORT } = ReportLogFormField;

const DATE_INPUT_WIDTH = '58%';
const DATE_INPUT_LABEL_WIDTH = '160px';
const CHECKBOX_INPUT_LABEL_WIDTH = '150px';

const TimeSelect: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const currentDate = formatDateTime(new Date(), TimeFormatType.MONTH_DATE_YEAR);

  return (
    <FilterOptionsWrapper width="30%" minWidth="350px">
      <DateInput
        inputWidth={DATE_INPUT_WIDTH}
        labelWidth={DATE_INPUT_LABEL_WIDTH}
        name={START_DATE}
        label={t('report.log_history.filter_option.start_date')}
        defaultValue={currentDate}
      />

      <DateInput
        inputWidth={DATE_INPUT_WIDTH}
        labelWidth={DATE_INPUT_LABEL_WIDTH}
        name={END_DATE}
        label={t('report.log_history.filter_option.end_date')}
        defaultValue={currentDate}
      />

      <CheckboxInput
        name={PRESENCE_LIST}
        variant="body2"
        label={t('report.log_history.filter_option.presence_list')}
        labelWidth={CHECKBOX_INPUT_LABEL_WIDTH}
      />

      <CheckboxInput
        variant="body2"
        name={PRESENCE_TIME_REPORT}
        label={t('report.log_history.filter_option.presence_time_report')}
        labelWidth={CHECKBOX_INPUT_LABEL_WIDTH}
      />
    </FilterOptionsWrapper>
  );
};

export default TimeSelect;
