import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@mui/material';

import SearchBar from '../../../components/SearchBar';
import { ResponsiveInfinityScroll } from '../../../components/ResponsiveInfinityScroll';
import { OptionButton, OptionButtonType } from '../../../components/Button/OptionButton';

import { ListWrapper } from '../components/ListWrapper';
import { BLANK_STRING } from '../types';
import { ButtonWrapper, Header, Item } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideBarItemType } from '../../../types/route';
import { GroupRouteEndpoints } from './types';
import { useUsersHook } from '../store';

type Props = {
  groupId: string;
  onExportGroups: () => Promise<void>;
};

const GroupList: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const { groupId, onExportGroups } = props;

  const [{ groupList, totalGroup }, { getAllGroups, setInitialGroups, getGroupsByName }] = useUsersHook();

  const [keyword, setKeyword] = React.useState<string>(BLANK_STRING);
  const [onSearchMode, setOnSearchMode] = React.useState<boolean>(false);
  const [hasMore, setHasMore] = React.useState<boolean>(true);

  const handleSearch = async (value: string) => {
    setKeyword(value);
    setHasMore(true);
    try {
      if (value.trim() === BLANK_STRING) {
        await getAllGroups();
      } else {
        await getGroupsByName(value);
        setOnSearchMode(true);
      }
    } catch (error) {
      setInitialGroups();
    }
  };

  const handleScrollDown = async () => {
    if (totalGroup > groupList.length && groupList[groupList.length - 1]) {
      const fromId = groupList[groupList.length - 1].groupID;
      if (onSearchMode) {
        await getGroupsByName(keyword, fromId);
      } else {
        await getAllGroups(fromId);
      }
    }
  };

  const handleChosenGroup = (value: string) => {
    navigate(`/users/group/${value}`);
  };

  async function getAllGroup() {
    setHasMore(true);

    await getAllGroups();

    setOnSearchMode(false);
  }

  React.useEffect(() => {
    getAllGroup();
  }, []);

  React.useEffect(() => {
    setHasMore(groupList && totalGroup > groupList.length);
  }, [groupList]);

  const isPopulateGroup =
    location.pathname.includes(GroupRouteEndpoints.ADD_NEW) || location.pathname.includes(GroupRouteEndpoints.EDIT);

  return (
    <ListWrapper>
      <Header>
        <Typography variant="subtitle4" textTransform="uppercase" color="grey.100">
          {t('group.list')} ({totalGroup})
        </Typography>
      </Header>

      {!isPopulateGroup && (
        <ButtonWrapper>
          <OptionButton
            type={OptionButtonType.EXPORT}
            content={t('export')}
            isscaledown={true}
            onClick={onExportGroups}
          />
          <OptionButton type={OptionButtonType.IMPORT} content={t('import')} isscaledown={true} />
          <OptionButton
            type={OptionButtonType.ADD_NEW}
            content={t('add_new')}
            isscaledown={true}
            onClick={() => navigate(`/users/${SideBarItemType.Group}/${GroupRouteEndpoints.ADD_NEW}`)}
          />
        </ButtonWrapper>
      )}

      <SearchBar
        onClear={getAllGroup}
        onSearch={handleSearch}
        placeHolder={t('group.search_placeholder')}
        keyword={keyword}
        onKeywordChanged={value => setKeyword(value)}
      />

      {_.isEmpty(groupList) ? (
        <Typography variant="subtitle1" color="grey.200">
          {t('search_no_result')}
        </Typography>
      ) : (
        <ResponsiveInfinityScroll
          height="40vh"
          dataLength={groupList.length}
          next={handleScrollDown}
          hasMore={hasMore}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" size={40} />
            </Box>
          }>
          {groupList.map((group, index) => (
            <Item
              key={index}
              className={groupId === group.groupID ? 'active' : BLANK_STRING}
              onClick={() => handleChosenGroup(group.groupID)}>
              {group.groupName}
            </Item>
          ))}
        </ResponsiveInfinityScroll>
      )}
    </ListWrapper>
  );
};

export default GroupList;
