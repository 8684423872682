import React from 'react';
import type { Blocker, Transition } from 'history';
import history from './history';

export function useBlocker(blocker: Blocker): void {
  React.useEffect(() => {
    const unblock = history.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        }
      };
      blocker(autoUnblockingTx);
    });

    return unblock;
  }, []);
}
