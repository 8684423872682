import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import _ from 'lodash';
import React from 'react';
import { LoadingIndicator } from './components/LoadingIndicator';
import { AdminProfile, Location } from './models';
import { StorageKeys } from './types/storage';
import { $get } from './utils/http';

type AppContextValue = {
  locations: Location[];
  adminProfile?: AdminProfile;
  loadAdminProfile: () => void;
  showLoading: () => void;
  hideLoading: () => void;
};
const AppContext = React.createContext<AppContextValue>({} as AppContextValue);

type AppContextProviderProps = {
  children: React.ReactNode;
};

export const AppContextProvider = (props: AppContextProviderProps) => {
  const { children } = props;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [adminProfile, setAdminProfile] = React.useState<AdminProfile>();
  const [locations, setLocations] = React.useState<Location[]>([]);

  const token = localStorage.getItem(StorageKeys.TOKEN);

  const loadAdminProfile = async () => {
    if (_.isUndefined(adminProfile)) {
      const response = await $get('admin/adminProfile');

      const profile: AdminProfile = response.data;

      setAdminProfile(profile);
    }
  };

  async function getLocations() {
    const response = await $get('location/all');

    const { data } = response;

    if (data) {
      const locationList: Location[] = data.locations;

      setLocations(locationList);
    }
  }

  React.useEffect(() => {
    if (token) {
      getLocations();
    }
  }, [token]);

  return (
    <AppContext.Provider
      value={{
        locations,
        adminProfile,
        loadAdminProfile,
        showLoading: () => setLoading(true),
        hideLoading: () => setLoading(false)
      }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {loading && <LoadingIndicator size={48} width="100%" height="100%" />}
        {children}
      </LocalizationProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return React.useContext(AppContext);
};
