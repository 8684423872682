import { AuthReport, ReportLog } from '../../models';
import { createStore, createHook, createActionsHook, StoreActionApi, createStateHook } from 'react-sweet-state';
import { authLogs, logs } from './data';

type State = {
  authLogs: AuthReport[];
  logs: ReportLog[];
  totalLogs: number;
};

const initialState: State = {
  authLogs: [],
  logs: [],
  totalLogs: 0
};

export const actions = {
  //TODO: implement in https://sioux.atlassian.net/browse/ECA-357
  getReportLogs:
    () =>
    ({ setState }: StoreActionApi<State>) => {
      setState({ logs: logs });
    },
  getReportAuthLogs:
    () =>
    //TODO : implement in https://sioux.atlassian.net/browse/ECA-364
    ({ setState }: StoreActionApi<State>) => {
      setState({ authLogs: authLogs });
    }
};

const Store = createStore({
  initialState,
  actions
});

export const useReportHook = createHook(Store);
export const useReportActions = createActionsHook(Store);
export const useReportStates = createStateHook(Store);
