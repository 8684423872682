import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserInGroup } from '../../../../../models';
import { useUsersStates } from '../../../store';
import { GridTable } from '../../components';

import { TableAction } from '../types';

type Props = {
  onBelongUserIdsChanged: (userIds: string[]) => void;
};

export const BelongUsers: React.FunctionComponent<Props> = (props: Props) => {
  const { onBelongUserIdsChanged } = props;

  const { t } = useTranslation();

  const { existedUsers } = useUsersStates();

  const [belongUsers, setBelongUsers] = React.useState<UserInGroup[]>([]);
  const [notBelongUsers, setNotBelongUsers] = React.useState<UserInGroup[]>([]);

  const titles = [
    t('group.users_in_group.column.id'),
    t('group.users_in_group.column.username'),
    t('group.users_in_group.column.location'),
    t('group.action')
  ];

  const columnWidths = ['10%', '50%', '30%', '10%'];

  const placeholder = t('group.users_in_group.placeholder');

  const sortUsersByUserId = (users: UserInGroup[]) =>
    users.sort((prev, next) => parseInt(prev.userId) - parseInt(next.userId));

  const handleAddUserIntoGroup = (user: UserInGroup) => {
    const newBelongUsers = [user, ...belongUsers];
    const newBelongUserIds = newBelongUsers.map(it => it.userId);
    const newNotBelongUsers = sortUsersByUserId(existedUsers.filter(item => !newBelongUserIds.includes(item.userId)));

    onBelongUserIdsChanged(newBelongUsers.map(item => item.userId));

    setBelongUsers(newBelongUsers);
    setNotBelongUsers(newNotBelongUsers);
  };

  const handleRemoveUserIntoGroup = (user: UserInGroup) => {
    const newNotBelongUsers = sortUsersByUserId([user, ...notBelongUsers]);
    const newNotBelongUserIds = newNotBelongUsers.map(it => it.userId);
    const newBelongUsers = sortUsersByUserId(existedUsers.filter(item => !newNotBelongUserIds.includes(item.userId)));

    onBelongUserIdsChanged(newBelongUsers.map(item => item.userId));

    setNotBelongUsers(newNotBelongUsers);
    setBelongUsers(newBelongUsers);
  };

  React.useEffect(() => {
    setNotBelongUsers(sortUsersByUserId(existedUsers));
  }, [existedUsers]);

  return (
    <>
      <GridTable
        header={t('group.users_in_group.belong_to_this_group')}
        titles={titles}
        rows={belongUsers.map(it => ({
          userId: it.userId,
          userName: it.userName,
          location: it.locationName
        }))}
        columnWidths={columnWidths}
        actionType={TableAction.REMOVE}
        action={handleRemoveUserIntoGroup}
        placeholder={placeholder}
      />

      <GridTable
        header={t('group.users_in_group.not_belong_to_this_group')}
        titles={titles}
        rows={notBelongUsers.map(it => ({
          userId: it.userId,
          userName: it.userName,
          location: it.locationName
        }))}
        columnWidths={columnWidths}
        actionType={TableAction.ADD}
        action={handleAddUserIntoGroup}
        placeholder={placeholder}
      />
    </>
  );
};
